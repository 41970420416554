<template>
  <div style="max-width: 400px" v-if="reviewData">
    <p class="fw-500 mb-3">Требования к скриншотам:</p>

    <p class="mb-3">1. Нельзя рисовать на скриншоте, обрезать, фотошопить его.</p>
    <p class="mb-3">2. Должна быть чётко видна отметка аккаунта заказчика и сама реклама.</p>
    <p class="mb-5">3. Если в отзыве указаны охваты, то нужно загрузить скриншоты с охватами.</p>

    <p class="mb-2 fs-16">Можно загрузить несколько файлов</p>

    <file-input @input="handleFile" />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import reviewsApi from '@main/api/reviews';
import apiService from '@main/api/index';
import FileInput from '../../forms/FileInput.vue';

export default {
  props: {
    reviewData: Object,
  },
  data: () => ({
    screenshots: [],
  }),
  components: { FileInput },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    handleFile(files) {
      const formData = new FormData();
      for (let k = 0; k < files.length; k += 1) formData.append('files[]', files[k]);
      apiService
        .post('/api/reviews/upload-screenshot/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // eslint-disable-next-line func-names
          onUploadProgress: function (event) {
            // eslint-disable-next-line radix
            this.fileLoading = parseInt(Math.round((event.loaded / event.total) * 100));
          }.bind(this),
        })
        .then((res) => {
          this.screenshots = this.screenshots.concat(res);
          reviewsApi.update({ id: this.reviewData.id, screenshots: this.screenshots });
          this.setWindow(null);
        })
        .catch((error) => {
          this.fileLoading = false;
        });
    },
  },
};
</script>
