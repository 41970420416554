import qs from 'querystring';
import apiService from './index';
/* eslint-disable camelcase */

export default {
  prAgencyRequest: {
    async create(data) {
      const response = await apiService.post(`/api/pr_agency/requests/`, data);
      return response;
    },
    async apiSchema(params) {
      const response = apiService.get(`/api/pr_agency/requests/api_schema/`, {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      });
      return response;
    },
  },
  prAgencyRequestService: {
    async list() {
      const response = await apiService.get(`/api/pr_agency/services/`);
      return response;
    },
  },
};
