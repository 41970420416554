<template><div class="d-none"></div></template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions('notifications', ['loadNotifications']),
  },
  mounted() {
    setInterval(this.loadNotifications, 5000);
  },
};
</script>
