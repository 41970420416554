import apiService from './index';
/* eslint-disable camelcase */

export default {
  energy: {
    async getPrice() {
      const response = await apiService.get('/api/payments/energy_price/');
      return response;
    },
    async createPayment(price_id) {
      const response = await apiService.post('/api/payments/energy/create/', { price_id });
      return response;
    },
  },
  sub: {
    async getPrice() {
      const response = await apiService.get('/api/payments/services-subscription/price/');
      return response;
    },
    async createPayment(data) {
      const response = await apiService.post('/api/payments/services-subscription/create/', data);
      return response;
    },
    async recurrentInit() {
      const response = await apiService.post('/api/payments/recurrent_init/create/');
      return response;
    },
    async charge() {
      const response = await apiService.post('/api/payments/subscribe/charge/');
      return response;
    },
    payments: {
      async list(params) {
        const response = await apiService.get('/api/payments/subscription_payments/', { params });
        return response;
      },
    },
  },
  promo: {
    async emailPromo(eMail) {
      const response = await apiService.post('/api/payments/email_promo/na(jdjaoiwo(!21/', {
        eMail,
      });
      return response;
    },
    saveSubscriptionPromo: {
      async check() {
        const response = await apiService.get('/api/payments/save_subscription_promo/');
        return response;
      },
      async apply() {
        const response = await apiService.post('/api/payments/save_subscription_promo/');
        return response;
      },
    },
  },
};
