var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup",class:( _obj = { act: _vm.showModal }, _obj[_vm.mainClass] = _vm.mainClass, _obj ),style:({
    'background-color': _vm.showAnim
      ? 'rgba(0, 0, 0, 0.5)'
      : _vm.showModal
      ? 'rgba(0, 0, 0, 0.0)'
      : 'rgba(0, 0, 0, 0.5)',
  }),on:{"click":function($event){_vm.clickOusideHide && _vm.$emit('hide')}}},[_c('div',{staticClass:"popup__wrap",class:_vm.wrapClass,style:({
      'margin-bottom': !_vm.hideAnim ? (_vm.showAnim ? '0px' : _vm.showModal ? '-2000px' : '0px') : '0px',
    }),on:{"click":function($event){$event.stopPropagation();}}},[(!_vm.topLess)?_c('div',{staticClass:"popup__top"},[_c('h2',{staticClass:"popup-search-off__title mr-2"},[_vm._v(_vm._s(_vm.title))]),(_vm.displayClose)?_c('button',{staticClass:"popup2__close",on:{"click":function($event){return _vm.$emit('hide')}}},[_c('svg',{staticClass:"icon-close-btn popup-theses__icon"},[_c('use',{attrs:{"xlink:href":require("@main/assets/sprite.svg") + "#close-btn"}})])]):_vm._e()]):_vm._e(),_c('div',{class:_vm.contentClass,staticStyle:{"position":"relative"}},[(_vm.topLess && _vm.displayClose)?_c('button',{staticClass:"popup2__close topless__close",on:{"click":function($event){return _vm.$emit('hide')}}},[_c('svg',{staticClass:"icon-close-btn popup-theses__icon"},[_c('use',{attrs:{"xlink:href":require("@main/assets/sprite.svg") + "#close-btn"}})])]):_vm._e(),_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }