import axios from 'axios';
import * as rax from 'retry-axios';
import { getAuthHeaders } from '@main/util';

const headers = getAuthHeaders();
const apiService = axios.create({
  timeout: 25000,
  headers,
});

rax.attach(apiService);

apiService.interceptors.response.use(
  (response) => {
    if (!response || typeof response === 'undefined') {
      return { success: false };
    }
    if ('data' in response) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      return;
    }
    if (error.response.status === 401) {
      if (error.response.data.code === 'token_not_valid') {
        console.log('TOKEN ERROR - RELOADING');
        window.$cookies.remove('jwt_access');
        localStorage.removeItem('token');
        window.location.reload();
      }
      throw error;
    }
    throw error;
  }
);

export default apiService;
