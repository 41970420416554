import Vue from 'vue';
import * as searchApi from '../../api/search';

export default {
  namespaced: true,
  state: {
    currentSearch: null,

    rejected: [],
    requested: [],
    accepted: [],

    mutalPrNew: [],
    mutalPrToday: [],
    mutalPrPast: [],
  },
  mutations: {
    setCurrentSearch(state, currentSearch) {
      state.currentSearch = currentSearch;
    },
    setMutalPrNew(state, data) {
      state.mutalPrNew = data;
    },
    setMutalPrToday(state, data) {
      state.mutalPrToday = data;
    },
    setMutalPrPast(state, data) {
      state.mutalPrPast = data;
    },
  },
  actions: {
    async loadCurrentSearch(context) {
      const currentSearch = await searchApi.getCurrentSearch();
      context.commit('setCurrentSearch', currentSearch);
    },
    async loadMutalPr(context) {
      const mutalpr = await searchApi.getMutalPr();
      context.commit('setMutalPr', mutalpr);
    },
    async loadPastMutalPr(context) {
      const mutalpr = await searchApi.getPastMutalPr();
      context.commit('setPastMutalPr', mutalpr);
    },
    async loadTodayMutalPr(context) {
      const mutalpr = await searchApi.getTodayMutalPr();
      context.commit('setTodayMutalPr', mutalpr);
    },
    async removeRequest(context, profileId) {
      const status = await searchApi.removeRequest(profileId);
      if (status.success) {
        context.dispatch('loadMutalPr');
      }
    },
    async updateStatus(context) {
      const status = await searchApi.getStatus();
      console.log(status);
      if (status.error) {
        return;
      }
      context.commit('setCurrentSearch', status.search_result);
      context.commit('setMutalPrNew', status.mutal_pr_new);
      context.commit('setMutalPrToday', status.mutal_pr_today);
      context.commit('setMutalPrPast', status.mutal_pr_past);
    },
  },
};
