<template>
  <header
    class="header"
    :style="backMode ? `z-index: 112233432221; ${backMode.style ? backMode.style : ''}` : ''"
    style="right: 0px"
  >
    <div class="container flex">
      <div class="header__part flex">
        <transition name="component-fade" mode="out-in">
          <template v-if="!backMode">
            <div class="header__logo flex">
              <a href="/" class="header__logo__img">
                <img src="@main/assets/favicon-228.png" alt="" />
              </a>
              <div v-if="!userCart || !userCart.orders_length" class="header__logo__right">
                <a href="/" class="header__logo__name"> Easyprbot v5.2 </a>
                <a class="header__logo__inst"> @easyprbot </a>
              </div>

              <b-button
                @click="$router.push('/promobot/orders/cart/')"
                class="ordercart position-relative ml-3"
                :orders="userCart ? userCart.orders_length : '0'"
                v-else
                variant="outline-default"
                ><img src="@main/assets/img/svg/cart.svg" alt="" class="d-inline-block" />
                Корзина</b-button
              >
            </div>
          </template>
          <b-button @click="handleBackClick" variant="outline-default" v-else
            ><img
              class="d-inline-block mb-1 mr-1"
              src="@main/assets/img/svg/back-arr-g.svg"
              alt=""
            />
            Назад</b-button
          >
        </transition>
        <div class="header__nav__container" :class="{ openedNav }">
          <div class="nav__mob__title flex">Меню</div>
          <ul @click="toggleNav(false)" class="header__nav__ul d-ttlg-flex">
            <li class="only-mobile">
              <a v-if="currentUser" @click.prevent="logout">Выйти</a>
              <a v-else @click.prevent="() => toggleSignIn(true)" class="openPopup">Войти</a>
            </li>
            <li class="only-mobile">
              <b-button variant="link" :to="{ name: 'statistic-settings' }" class="link"
                >Настройки</b-button
              >
            </li>
            <li class="head_nav_dropdown" @click="openedMenuPart = 'openedSel'">
              <div
                class="head_nav_select head_open_sekect"
                :class="{ openedSel: openedMenuPart === 'openedSel' }"
              >
                Отзывы
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.39959L4 4.51307L7 1.39959"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <div
                class="head__select__container py-ttlg-2"
                :class="{ 'd-block': openedMenuPart === 'openedSel' }"
                style="display: none"
              >
                <b-button style="text-align: left" variant="link" :to="{ name: 'reviews' }"
                  >Все отзывы</b-button
                >
                <b-button style="text-align: left" variant="link" :to="{ name: 'let-review' }"
                  >Оставить отзыв</b-button
                >
                <b-button style="text-align: left" variant="link" :to="{ name: 'myreviews' }"
                  >Отзывы на мой аккаунт</b-button
                >
                <b-button
                  style="text-align: left"
                  variant="link"
                  :to="{ name: 'reviews', query: { mode: 'me' } }"
                  >История моих отзывов</b-button
                >
                <b-button
                  class="d-ttlg-none"
                  variant="link"
                  style="text-align: left"
                  :to="{ name: 'payments-settings' }"
                  >Управление подпиской</b-button
                >
                <!--b-button style="text-align: left" variant="link" :to="{ name: 'referal' }"
                  >Реферальная программа</!--b-button
                -->
                <template v-if="isStaff">
                  <b-button
                    style="text-align: left"
                    variant="link"
                    :to="{ name: 'reviews-moderation' }"
                    >Модерация отзывов</b-button
                  >
                  <b-button style="text-align: left" variant="link" :to="{ name: 'app-statistic' }"
                    >Аналитика</b-button
                  >
                  <b-button
                    style="text-align: left"
                    variant="link"
                    :to="{ name: 'statistic-moderation' }"
                    >Модерация карточек</b-button
                  >
                  <b-button
                    style="text-align: left"
                    variant="link"
                    :to="{ name: 'producing-moderation' }"
                    >Модерация инфопродуктов</b-button
                  >
                  <b-button variant="link" style="text-align: left" :to="{ name: 'blacklist' }"
                    >Черный список</b-button
                  >
                  <b-button
                    variant="link"
                    style="text-align: left"
                    href="https://easyprbot.com/update-instagram/"
                    >Замена логина</b-button
                  >
                  <a
                    class="btn btn-link"
                    style="text-align: left"
                    @click="toggleChangeBotSettingsModal(true)"
                    >Настройки спама</a
                  >
                </template>
              </div>
            </li>
            <li>
              <b-button variant="link" class="text-left" :to="{ name: 'advertising-gathering' }"
                >Размещение карточки</b-button
              >
            </li>
            <li class="d-sm-none" v-if="currentUser && currentUser.blogger_profile">
              <b-button variant="link" style="text-align: left" :to="{ path: '/mystat' }"
                >Моя карточка</b-button
              >
            </li>
            <li>
              <b-button variant="link" href="/tracker/">Трекер</b-button>
            </li>
            <li>
              <a @click="() => $router.push({ name: 'blogger-checker' })" class="btn btn-link"
                >Проверка блогеров</a
              >
            </li>
            <li>
              <a
                variant="link"
                class="btn btn-link"
                target="_blank"
                href="https://korotylka.info/results"
                >Рейтинг менеджеров</a
              >
            </li>
            <li class="d-none d-xl-block">
              <b-button variant="link" :to="{ name: 'payments-settings' }"
                >Управление подпиской</b-button
              >
            </li>
          </ul>
          <div @click="() => toggleNav(false)" class="nav__overlay"></div>
        </div>
      </div>
      <div class="header__part flex header-menu-part">
        <div v-if="currentUser && currentUser.profile" class="values__status-bar mr-md-3 mr-lg-3">
          <b-button
            @click="toggleEnergyPriceModal(true)"
            variant="outline-default"
            class="energy-value"
          >
            <p>{{ currentUser.profile.energy }}</p>
            <img src="../assets/img/svg/energy.svg" alt="" />
          </b-button>
          <b-toast class="position-absolute" id="buy-energy-toast" static no-auto-hide>
            <template #toast-title>
              <p class="px-1">Доступно {{ currentUser.profile.energy }} проверок</p>
            </template>
            <div class="px-2">
              <b-button
                style="font-size: 15px"
                size="lg"
                @click="toggleEnergyPriceModal(true)"
                variant="yellow"
                >Купить проверки</b-button
              >
              <p class="text-grey mt-3" style="font-size: 12.5px">
                Проверки расходуются на открытие статистики блогера.
              </p>
            </div>
          </b-toast>
        </div>
        <div class="header__enter__container" :class="{ logined: currentUser }">
          <b-button
            style="line-height: 24px"
            variant="primary"
            @click="() => toggleSignIn(true)"
            class="enter__head_but openPopup"
            >Войти</b-button
          >
          <div
            v-if="currentUser"
            @click="openedMenuPart = 'openedAvatar'"
            :class="{
              logined_link_sub_error: subscriptionState.error,
              logined_link_sub_active: subscriptionState.active || subscriptionState.newSub,
              logined__link: true,
            }"
          >
            <div
              class="head_open_sekect"
              :class="{ openedSel: openedMenuPart === 'openedAvatar' }"
            ></div>
            <div
              class="head__select__container"
              :class="{ 'd-block': openedMenuPart === 'openedAvatar' }"
              style="display: none; min-width: 290px !important; padding: 0px !important"
            >
              <div class="p-2 px-4 d-flex" style="border-bottom: 1px solid #d5dfe4">
                <div class="mr-2">
                  <img
                    v-if="subscriptionState.active || subscriptionState.newSub"
                    src="@main/assets/img/svg/subscription_icons/avatar_active_subscription.svg"
                    alt=""
                  />
                  <img
                    v-else-if="subscriptionState.error"
                    src="@main/assets/img/svg/subscription_icons/avatar_error_subscription.svg"
                    alt=""
                  />
                  <img
                    v-else
                    src="@main/assets/img/svg/subscription_icons/avatar_non_subscription.svg"
                    alt=""
                  />
                </div>
                <div>
                  <p
                    v-if="currentUser.profile.phone"
                    v-phone-number="currentUser.profile.phone"
                    class="fw-500"
                  ></p>
                  <p v-else class="fw-500">
                    {{ currentUser.profile.email || currentUser.username }}
                  </p>
                  <div
                    class="fs-12"
                    style="white-space: nowrap !important"
                    v-html="
                      currentUser.profile.subscription_member && !subscriptionState.disabledByUser
                        ? subscriptionState.active
                          ? '<p style=\'color: #00D355\'>Подписка активна</p>'
                          : '<p style=\'color: #FF0000\'>Подписка неактивна</p>'
                        : currentUser.subscription_days_left
                        ? `<p style=\'color: #6A7B83\'>Осталось ${
                            currentUser.subscription_days_left
                          } ${literal.declension(
                            currentUser.subscription_days_left,
                            'дн'
                          )} подписки</p>`
                        : '<p style=\'color: #6A7B83\'>Подписка не оформлена</p>'
                    "
                  ></div>
                </div>
              </div>
              <a
                class="p-2 px-4 btn text-left"
                style="
                  background-color: #fffce3;
                  height: auto !important;
                  line-height: 16.59px !important;
                  width: auto !important;
                  white-space: break-spaces !important;
                "
                v-if="!currentUser.profile.subscription_member && !subscriptionState.newSub"
                href="https://sub.easyprbot.com/"
                target="_blank"
              >
                <img
                  class="d-inline-block mr-2"
                  src="@main/assets/img/svg/subscription_icons/yellow_plus_icon.svg"
                  alt=""
                />
                <p class="fw-500 d-inline-block">Больше в Подписке</p>
                <p class="fs-12 mt-1">Оформите подписку на сервисы Easyprbot за 2990₽ в месяц</p>
              </a>
              <div class="p-2">
                <b-button
                  variant="link"
                  style="text-align: left"
                  :to="{ name: 'payments-settings' }"
                  >Управление подпиской</b-button
                >
                <b-button
                  v-if="currentUser"
                  variant="link"
                  style="text-align: left"
                  :to="{ name: 'change-instaname' }"
                  >Изменить ник</b-button
                >
                <b-button
                  v-if="currentUser && currentUser.blogger_profile"
                  variant="link"
                  style="text-align: left"
                  :to="{ path: '/mystat' }"
                  >Моя карточка</b-button
                >
                <b-button
                  variant="link"
                  style="text-align: left"
                  :to="{ name: 'statistic-settings' }"
                  >Настройки</b-button
                >
                <b-button
                  v-if="userCart && userCart.orders_length"
                  variant="link"
                  :to="{ path: '/promobot/orders/cart/' }"
                  style="text-align: left"
                  >Корзина</b-button
                >
                <a class="btn btn-link" style="text-align: left" @click="logout">Выйти</a>
              </div>
            </div>
          </div>
        </div>
        <a
          v-if="!openedMenu"
          @click.prevent="() => toggleMenu(!openedMenu)"
          class="hamburger__button"
        >
          <span></span><span></span><span></span>
        </a>
        <a @click="toggleMenu(false)" v-else class="hamburger__button"
          ><img class="w-100 h-100 p-2" src="@main/assets/img/svg/no.svg" alt=""
        /></a>
      </div>
      <div
        v-if="openedMenu"
        style="
          top: 75px;
          background: white;
          width: iherit;
          right: 0;
          left: 0;
          z-index: -1;
          height: 100vh;
          overflow-y: scroll;
          overflow-x: hidden;
          padding-bottom: 140px;
        "
        class="position-fixed hovering--inset"
      >
        <div
          @click="menuClick"
          style="
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
            column-gap: 10px;
            row-gap: 15px;
            justify-content: space-evenly;
            grid-template-areas: 'A A A';
          "
          class="p-2 mt-2"
        >
          <div class="mb-2" v-if="!currentUser">
            <b-button
              style="border-radius: 23px !important"
              class="m-1 mx-2"
              variant="link"
              @click="toggleSignIn(true)"
            >
              <img style="width: 95px; height: 95px" src="@main/assets/img/svg/menu/login.svg" />
            </b-button>
            <p
              class="mt-1 mx-auto text-center"
              style="
                width: 95px;
                font-family: Rubik;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #192229;
              "
            >
              Войти
            </p>
          </div>
          <div class="mb-2" v-else>
            <b-button
              style="border-radius: 23px !important"
              @click="logout"
              class="m-1 mx-2"
              variant="link"
            >
              <img style="width: 95px; height: 95px" src="@main/assets/img/svg/menu/logout.svg" />
            </b-button>
            <p
              class="mt-1 mx-auto text-center"
              style="
                width: 95px;
                font-family: Rubik;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #192229;
              "
            >
              Выйти
            </p>
          </div>
          <tile-navigation-button
            class="mb-2"
            :key="button.id + 'awdaj@jdn'"
            v-for="button in navButtons.filter((val) => !val.for_staff)"
            :button="button"
          />
          <template v-if="currentUser && isStaff">
            <p
              style="grid-column: 1 / 4; margin-left: calc(33% - 95px) !important"
              class="gray-text text-left w-100 fs-12 mt-4 mb-2"
            >
              Модерация
            </p>
            <tile-navigation-button
              class="mb-2"
              :key="button.id + 'awdaj@jdn'"
              v-for="button in navButtons.filter((val) => val.for_staff)"
              :button="button"
            />
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import ui from '@main/api/ui';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import TileNavigationButton from './reusable/elements/buttons/TileNavigationButton.vue';

export default {
  components: { TileNavigationButton },
  computed: {
    ...mapState(['currentUser', 'backMode', 'userCart']),
    ...mapGetters(['isStaff']),
    ...mapGetters(['subscriptionState']),
  },
  data() {
    return {
      canModerate: true, // TODO: Сделать подгрузку прав
      extra_fields: [], // TODO: Сделать подгрузку
      showReviewsDropdown: false,
      openedAvatarSelect: false,
      openedMenu: false,
      openedNav: false,
      openedSel: false,
      openedSelAd: false,
      openedMenuPart: '',
      navButtons: null,
      isMobile,
    };
  },
  methods: {
    ...mapMutations(['toggleSignIn', 'setBackMode']),
    ...mapMutations('popups', ['toggleChangeBotSettingsModal']),
    ...mapActions(['logout']),
    ...mapActions('notifications', ['setWindow']),
    ...mapMutations('payments', ['toggleEnergyPriceModal']),
    hideMenu() {
      this.openedMenuPart = '';
    },
    openSignIn() {
      this.toggleSignIn(true);
    },
    toggleMenu(val) {
      this.openedMenu = val;
      if (val) document.body.classList.add('scroll-disabled');
      else document.body.classList.remove('scroll-disabled');
    },
    toggleNav(val) {
      this.openedNav = val;
      if (val) document.body.classList.add('scroll-disabled');
      else document.body.classList.remove('scroll-disabled');
    },
    menuClick() {
      setTimeout(() => this.toggleMenu(false), 300);
    },
    handleBackClick() {
      this.backMode.handler();
      this.setWindow(null);
      this.setBackMode(false);
    },
  },
  async mounted() {
    this.$bus.$on('body-clicked', (val) => {
      this.openedMenuPart = '';
    });
    const buttons = await ui.buttons.menuButtons.get();
    this.navButtons = buttons.results;
  },
  watch: {
    $route(to, from) {
      this.openedMenuPart = '';
    },
  },
};
</script>

<style scoped lang="scss">
#buy-energy-toast__toast_outer {
  top: 44px !important;
  max-width: 188px !important;
  min-width: 188px !important;
  ::v-deep .close {
    margin-right: 3px !important;
  }
  ::v-deep .toast-header {
    padding-left: 15px !important;
  }
}

.logout--mobile {
  display: none;
}
.navbar-brand {
  font-size: 16px;
}

@media (max-width: 768px) {
  .navbar-brand {
    font-size: 14px;
  }
  #nav-bar-brand,
  ::v-deep #header-list >>> .nav-item {
    font-size: 14px !important;
  }
  .logout--mobile {
    display: block;
  }
  .header__enter__container {
    display: none !important;
  }
  .energy-value {
    border: none !important;
  }
}

.ordercart::after {
  content: attr(orders);
  position: absolute;
  width: 17px;
  height: 17px;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  line-height: 17px;
  font-size: 12px;
  top: -17%;
  display: table-cell;
  right: -6%;
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 400px) {
  #nav-bar-brand {
    margin-right: 0px;
  }
  .navbar-light .navbar-toggler {
    margin-right: 0px !important;
  }
}

#header-list >>> .nav-item .dropdown-toggle::after {
  vertical-align: middle;
}
@media (min-width: 768px) {
  .item--active {
    border: 1px solid #3897f0;
    border-radius: 5px !important;
    color: #192229 !important;
  }
  .item--active >>> a {
    color: #192229 !important;
  }
}
.values__status-bar {
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
}

@media (max-width: 360px) {
  .header__logo__name {
    max-width: 120px !important;
  }
  .nav__overlay {
    left: 245px !important;
  }
  .header__nav__container {
    width: 245px !important;
  }
  #nav-bar-brand,
  #header-list >>> .nav-item {
    font-size: 16px;
  }
  .nav-item {
    font-size: 16px;
  }
}

.avatar-sect {
  display: flex;
  align-items: center;
}
.avatar-block {
  align-items: center;
  vertical-align: middle;
  display: flex;
}
.energy-value {
  display: flex;
  vertical-align: middle;
  width: fit-content;
  justify-content: space-between;
  color: #6a7b83;
  padding: 6px 8px;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
}
.nav-tgl {
  border: none;
}
.hovering {
  -webkit-box-shadow: 0 3px 10px rgba(0, 73, 129, 0.1);
  box-shadow: 0 3px 10px rgba(0, 73, 129, 0.1);
}
.hovering--inset {
  -webkit-box-shadow: inset 0 3px 10px rgba(0, 73, 129, 0.1), 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: inset 0 3px 10px rgba(0, 73, 129, 0.1), 0 0 0 rgba(0, 0, 0, 0);
}
@media (min-width: 400px) and (max-width: 989px) {
  .nav__flex--center-left--tablet {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.15s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
