<template>
  <Modal
    @hide="() => toggleStatisticCardModal({ open: false, context: { instaname: null } })"
    :show="true"
    :hideAnim="true"
    :clickOutsideHide="false"
    title="Статистика блогера"
    contentClass="stat--popup__content"
  >
    <div class="w-100 h-100 d-flex flex-column justify-content-center" v-if="loading">
      <Loader />
    </div>
    <StatisticCard
      v-bind="statisticCardModal.context.attrs"
      @hide="() => toggleStatisticCardModal({ open: false, context: { instaname: null } })"
      @reload="getBlogger"
      v-else-if="blogger"
      :user="{ foreign: true, blogger_profile: blogger }"
    />
  </Modal>
</template>

<script>
import StatisticCard from '@main/components/reusable/statistics/StatisticCard.vue';
import Loader from '@main/components/Loader.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import bloggers from '@main/api/bloggers';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';

export default {
  components: {
    Modal,
    Loader,
    StatisticCard,
  },
  computed: {
    ...mapState('popups', ['statisticCardModal']),
    ...mapState(['currentUser']),
  },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    ...mapActions('bloggers', ['getBlogger']),
  },
  data: () => ({
    blogger: null,
    loading: false,
  }),
  async mounted() {
    this.$bus.$emit('modal-open', true);
    this.loading = true;
    const blogger = await this.getBlogger({
      instaname: this.statisticCardModal.context.instaname,
      access: this.access,
      errBack: () => {
        this.toggleStatisticCardModal({ open: false, context: { instaname: null } });
      },
    });
    this.blogger = blogger;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .container {
  padding: 0px;
}
::v-deep .popup__wrap {
  max-width: 1100px !important;
}
@media (min-width: 768px) {
  ::v-deep .popup__wrap {
    max-height: 90vh;
  }
}
@media (min-width: 1100px) {
  ::v-deep .stat--popup__content {
    min-width: 1100px !important;
  }
}
::v-deep .card__section-stata {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
::v-deep .rectangle-block {
  box-shadow: none;
}
::v-deep .card__header {
  display: none !important;
}
</style>
