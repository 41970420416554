<template>
  <div class="table__mobile--item">
    <div class="table__mobile--header">
      <div style="min-width: 35px !important" class="table__icon mr-3">
        <img :src="getIcon" alt="clock" class="table__icon--pic" />
      </div>
      <div
        :style="blogger && blogger.pr_agency_member ? 'line-height: 30px !important' : ''"
        class="mr-2"
      >
        <div>{{ getCurrentPrType }}</div>
        <template v-if="!hidden">
          <span
            :style="
              blogger && blogger.pr_agency_member
                ? 'text-decoration: line-through; color: #6A7B83 !important'
                : ''
            "
            class="fw-500 mr-2"
          >
            {{ prType.uPrice }} ₽
          </span>
          <template v-if="blogger && blogger.pr_agency_member">
            <span class="mr-2">{{ Math.ceil(prType.uPrice - prType.uPrice * 0.1) }} ₽</span>
            <span class="p-2" style="background-color: #fadb67; border-radius: 5px">-10%</span>
          </template>
        </template>
        <div v-else class="fw-500">Скрыто</div>
      </div>
      <template v-if="!hideClickButton">
        <b-button
          @click="defaultCartClickHandler"
          v-if="choosen"
          class="ml-auto py-2 px-2 d-inline-block"
          style="min-width: 107.56px"
          variant="outline-default"
          ><img
            src="@main/assets/img/svg/green-check.svg"
            class="d-inline-block mr-2"
            style="margin-bottom: 2px"
          />В корзину</b-button
        >
        <!--b-button
          @click="prTypeClickHandler ? prTypeClickHandler(prType) : updateCart()"
          v-else
          variant="yellow"
          class="ml-auto py-2 px-4"
          >Выбрать</!--b-button
        -->
        <b-button
          class="d-none d-sxm-inline-block ml-2 ph-36"
          variant="outline-default"
          v-if="choosen"
          @click="removeHandler ? removeHandler(prType) : removeFromCart()"
          ><img src="@main/assets/img/svg/no.svg" alt="" /></b-button
      ></template>
    </div>
    <div
      v-if="!hidden"
      class="table__mobile--desc d-flex justify-content-between align-items-start"
    >
      <span
        >Набор на {{ getCurrentPrMonth }}. {{ getCurrentOrdererType }}. {{ prType.content }}.</span
      >
      <b-button
        style="min-width: 39px !important"
        class="d-inline-block d-sxm-none ml-4 ph-36"
        variant="outline-default"
        v-if="choosen"
        @click="removeHandler ? removeHandler(prType) : removeFromCart()"
        ><img src="@main/assets/img/svg/no.svg" alt=""
      /></b-button>
    </div>
    <div v-else class="table__mobile--desc fw-500">Скрыто</div>
  </div>
</template>

<script>
import PrTypeDisplayableMixin from '@main/mixins/statistic/PrTypeDisplayableMixin.vue';

export default {
  mixins: [PrTypeDisplayableMixin],
};
</script>
