<template>
  <div :class="{ 'for-test': activeMessage }">
    <popup v-if="activeMessage" :data="activeMessage" @close="readMessage" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import * as notifyApi from '@main/api/notify';
import Popup from './Popup.vue';

export default {
  components: {
    Popup,
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('notifications', ['messages']),
    ...mapGetters('notifications', ['activeMessage']),
  },
  methods: {
    ...mapActions('notifications', ['readMessage']),
  },
};
</script>

<style lang="scss" scoped></style>
