<template>
  <component
    v-bind="activeWindow.componentAttrs"
    :is="activeWindow.useComponent || 'Modal'"
    v-if="activeWindow"
    @hide="close"
    :show="!!activeWindow"
    :topLess="activeWindow.topLess === false ? activeWindow.topLess : true"
    :title="typeof activeWindow.title === 'object' ? activeWindow.title.text : activeWindow.title"
    :displayClose="!activeWindow.backMode || !isMobile"
    :contentClass="activeWindow.contentClass || 'window-contnt'"
    :wrapClass="['modal-sm', activeWindow.backMode ? 'modal-backed' : '']"
  >
    <h4
      :class="`text-left mb-3 w-100 ${
        activeWindow.titleAttrs ? activeWindow.titleAttrs.class : ''
      }`"
      v-bind="activeWindow.titleAttrs"
      v-if="activeWindow.title && activeWindow.topLess"
    >
      {{ activeWindow.title }}
    </h4>
    <div
      class="w-100"
      v-if="activeWindow.contentComponent"
      v-bind="
        activeWindow.contentComponent.wrapper ? activeWindow.contentComponent.wrapper.attrs : null
      "
    >
      <component
        ref="cc"
        v-bind="activeWindow.contentComponent.attrs"
        :is="activeWindow.contentComponent.component"
      ></component>
    </div>
    <div v-else class="d-flex flex-column align-items-center" v-bind="activeWindow.textBlockAttrs">
      <img
        v-if="activeWindow.iconPath"
        class="window-icon"
        :style="`height: ${activeWindow.iconHeight || 70}px !important; width: ${
          activeWindow.iconWidth || 70
        }px !important`"
        :src="activeWindow.iconPath"
      />
      <h2 v-if="!activeWindow.html" class="window-text">{{ activeWindow.text }}</h2>
      <p
        class="mt-md-4"
        style="padding-right: 2px; white-space: pre-line; max-height: 486px; overflow: auto"
        v-html="activeWindow.html"
        v-else
      ></p>
    </div>
    <div class="video-container py-3" v-if="activeWindow.video">
      <b-embed v-bind="activeWindow.video.attrs"></b-embed>
    </div>
    <div
      class="footer__buttons mt-1 pb-4"
      v-bind="activeWindow.footer ? activeWindow.footer.attrs : null"
      v-if="activeWindow.buttons"
    >
      <b-button
        v-bind="btn.attrs"
        :ref="`btn-${idx}`"
        class="footer__button-notif-window"
        :disabled="loading"
        :class="
          btn.replaceClass ||
          (btn.addClass ? btn.addClass + ' ' : '') +
            (activeWindow.buttonClass || 'w-100 w-ssm-auto')
        "
        @click="
          btn.handler ? getHandlerResult(btn) : btn.action ? btnActions[btn.action](btn) : null
        "
        :key="btn.text"
        v-for="(btn, idx) in activeWindow.buttons || []"
      >
        {{ btn.text }}
      </b-button>
    </div>
  </component>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import Modal from '@main/components/reusable/modals/Modal.vue';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    isMobile,
    loading: false,
  }),
  components: {
    Modal,
  },
  computed: {
    ...mapState('notifications', ['windows']),
    ...mapGetters('notifications', ['activeWindow']),
    btnActions() {
      return {
        close: this.close,
      };
    },
  },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    ...mapMutations(['setBackMode']),
    close() {
      this.setWindow(null);
    },
    async getHandlerResult(btn) {
      this.loading = true;
      const result = await btn.handler(this.$refs.cc);
      this.loading = false;
      return result;
    },
  },
  mounted() {
    if (this.activeWindow && this.activeWindow.backMode && this.isMobile) {
      this.setBackMode({
        // eslint-disable-next-line func-names, object-shorthand
        handler: this.close,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.window-text {
  text-align: center;
  margin-top: 25px;
}
.window-icon {
  width: 70px;
  height: 70px;
}
.footer__buttons {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}
.footer__button-notif-window {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
}
@media (max-width: 400px) {
  .footer__button-notif-window {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .footer__buttons {
    justify-content: space-between;
    width: 100%;
  }
}
.video-container {
  width: 100%;
}
@media (min-width: 768px) {
  .video-container {
    width: 500px;
  }
  .footer__buttons {
    justify-content: center;
  }
}
</style>
