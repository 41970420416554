/* eslint-disable no-lonely-if */

export default {
  declension(num, str, kmode) {
    const strnum = String(num);
    let strout;
    let exc = false;
    if (str.length === 2 && str.endsWith('н')) {
      exc = true;
    }
    if (strnum === '11') {
      if (exc) {
        strout = `${str}ей`;
      } else if (kmode) {
        strout = `${str}ок`;
      } else {
        strout = `${str}ов`;
      }
    } else if (strnum.endsWith('1')) {
      if (exc) {
        strout = `${str.slice(0, 1)}ень`;
      } else if (kmode) {
        strout = `${str}ка`;
      } else {
        strout = str;
      }
    } else if (strnum.endsWith('0')) {
      if (exc) {
        strout = `${str}ей`;
      } else if (kmode) {
        strout = `${str}ок`;
      } else {
        strout = `${str}ов`;
      }
    } else if ('234'.includes(strnum.split('').reverse()[0]) && (num > 14 || num < 5)) {
      if (exc) {
        strout = `${str}я`;
      } else if (kmode) {
        strout = `${str}ки`;
      } else {
        strout = `${str}а`;
      }
    } else {
      if (exc) {
        strout = `${str}ей`;
      } else if (kmode) {
        strout = `${str}ок`;
      } else {
        strout = `${str}ов`;
      }
    }
    return strout;
  },
  camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  },
  removeLinks(str) {
    return str
      .replaceAll(
        /(https\?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        '(ссылка была удалена сервисом)'
      )
      .replaceAll(/(?:https?|ftp):\/\/[\n\S]+/g, '(ссылка была удалена сервисом)')
      .replaceAll(/[^ .?!()_]{1,}\.[^ .?_()!]{1,}/g, '(ссылка была удалена сервисом)');
  },
  parseInstaname(value) {
    let output;
    const match = value.match(
      /(https?:\/\/)?(www\.)?(instagram\.com\/?)?@?(?<instaname>[a-zA-Z0-9._]+)\/?/
    );
    if (match) {
      output = match.groups.instaname;
    }
    output = output.replace(/[^0-9a-z_.]/gi, '');
    return output;
  },
};
