<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { RequiresAuth } from '@main/func/pipeline';
import bot from '@main/api/bot';
import videoIcon from '@main/assets/img/decor/video.png';
import playIcon from '@main/assets/img/decor/playicon.png';
import clockIcon from '@main/assets/img/decor/clock.png';
import instaIcon from '@main/assets/img/decor/insta.png';

export default {
  props: {
    prType: {
      type: Object,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    prTypeClickHandler: {
      type: Function,
      required: false,
    },
    hideClickButton: {
      type: Boolean,
      default: false,
    },
    blogger: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(['updateUserCart']),
    ...mapActions('notifications', ['setWindow']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    updateCart() {
      const handler = new RequiresAuth();
      handler.handle(() => {
        this.updateUserCart({
          ...this.userCart,
          formats: this.userCart.formats.concat(this.prType.id),
        }).then(() => {
          console.log('format add');
        });
      });
    },
    async removeFromCart() {
      await this.updateUserCart({
        ...this.userCart,
        formats: this.lodash.difference(this.userCart.formats, [this.prType.id]),
      });
    },
    defaultCartClickHandler() {
      this.toggleStatisticCardModal({ open: false, context: { instaname: null } });
      this.$router.push('/promobot/orders/cart/');
    },
  },
  computed: {
    ...mapState(['bloggerOptions', 'currentUser', 'userCart']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    getIcon() {
      switch (this.prType.kind) {
        case '1':
          return clockIcon;
        case '2':
          return instaIcon;
        case '3':
          return videoIcon;
        case '4':
          return playIcon;
        default:
          return '';
      }
    },
    choosen() {
      if (!this.userCart) {
        return;
      }
      return this.userCart.formats.includes(this.prType.id);
    },
    getCurrentPrType() {
      const { choices } = this.options.pr_types.child.children.kind;
      const { prType } = this;
      const values = choices.filter((val) => val.value === prType.kind);
      let namestring = values[0].display_name;
      if ((values[0].value === '1' || values[0].value === '4') && prType.duration) {
        namestring = `${namestring} ${prType.duration ? prType.duration : ''} сек.`;
      }
      return namestring;
    },
    getCurrentPrMonth() {
      const { choices } = this.options.pr_types.child.children.month;
      const { prType } = this;
      const values = choices.filter((val) => val.value === prType.month);
      if (!values.length) {
        return '';
      }
      return values[0].display_name;
    },
    getCurrentOrdererType() {
      const { choices } = this.options.pr_types.child.children.target;
      const { prType } = this;
      const values = choices.filter((val) => val.value === prType.target);
      if (values.length) {
        const target = values[0].display_name;
        if (target === 'Любой') {
          return 'Личные и коммерческие блоги';
        }
        return target;
      }
      return 'Личные и коммерческие блоги';
    },
  },
};
</script>
