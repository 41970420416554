<template>
  <div class="verificated verificated-active">
    <template
      v-if="
        !currentUser.blogger_profile ||
        !currentUser.blogger_profile[prAccount ? 'pr_account_verified' : 'instagram_verified'] ||
        change
      "
    >
      <h3
        class="statistics__format--title verificatepracc"
        style="margin-bottom: 20px; text-align: center"
      >
        Верифицируйте {{ prAccount ? 'PR-' : 'основной ' }}аккаунт
      </h3>
      <div class="verification__pr">
        <div class="verification">
          <template>
            <div class="verification__meaning">Введите логин</div>
            <div class="d-flex flex-row align-items-start justify-content-between mb-4">
              <b-input
                type="text"
                v-model="login"
                :disabled="
                  loading ||
                  (prAccount &&
                    formattedLogin.toLowerCase() ===
                      currentUser.blogger_profile.instaname.toLowerCase())
                "
                class="mr-2"
                :placeholder="`Например, korotylka${prAccount ? '-PR' : ''}`"
              />
              <b-button
                @click.self="
                  () => {
                    if (code) {
                      login = null;
                      code = null;
                    } else {
                      submitLogin();
                    }
                  }
                "
                :disabled="loading || !login"
                variant="outline-default"
              >
                {{ code ? 'Изменить' : 'Готово' }}
              </b-button>
            </div>
          </template>

          <div v-if="code && !loading">
            <div class="verification__meaning">Скопируйте код</div>
            <div class="copy__block mb-4">
              <b-input disabled type="text" v-model="code" class="ph-36" />
              <b-button v-clipboard="code" class="ml-2 ph-36" variant="outline-default"
                >Скопировать</b-button
              >
            </div>
            <div class="verification__meaning verification__meaning--pd">
              Вставьте в шапку в профиль вашего
              {{ prAccount ? 'PR-' : 'основного (не PR!) ' }}аккаунта код
            </div>
            <img
              src="../../../assets/img/content/verificationexample.jpg"
              alt="example"
              class="verificaton__img img-acc-exmpl"
            />
            <div class="verification__opac--sec">
              Пример кода в шапке профиля основного аккаунта
            </div>
            <p class="err-txt" v-if="codeError">
              {{ codeError }}
            </p>
            <div class="verification__meaning">Нажмите кнопку</div>
            <b-button @click="() => checkCode(false)" size="lg" variant="warning"
              >Проверить</b-button
            >
          </div>
          <Loader v-else-if="loading" />
          <p style="font-weight: 500" class="text-center gray-text" v-if="verifyCountdownInterval">
            {{ verifyCountdown }}
          </p>
        </div>
      </div>
    </template>
    <div v-else class="verification">
      <h1
        v-if="currentUser.blogger_profile.instagram_verified"
        class="mb-3 mobile d-md-none d-lg-none"
      >
        {{ prAccount ? 'PR-' : 'Основной ' }}аккаунт верифицирован
      </h1>
      <div class="verification__meaning">
        Ваш {{ prAccount ? 'PR-' : 'основной ' }}аккаунт
        <!-- <span class="payment__title--icon">
                                              <span class="payment__title--wrap">✔</span>
                                          </span> -->
        <!-- <span class="verification__ok--wrap"> -->
        <svg class="verification__ok">
          <use xlink:href="../../../assets/img/icons/sprite.svg#done"></use>
        </svg>
        <!-- </span> -->
      </div>
      <div class="verification__account">
        {{
          currentUser.blogger_profile &&
          currentUser.blogger_profile[prAccount ? 'pr_account' : 'instaname']
        }}
      </div>
      <div class="verification__opacity">
        Если вы хотите изменить аккаунт, сначала войдите в него, затем нажмите кнопку ниже.
      </div>
      <b-button @click.self="() => clear()" class="ph-36" variant="outline-default"
        >Изменить аккаунт</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import bloggers from '@main/api/bloggers';
import Loader from '@main/components/Loader.vue';

export default {
  components: {
    Loader,
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
    prAccount: Boolean,
  },
  data: () => ({
    loading: false,
    login: '',
    code: null,
    error: '',
    change: false,
    codeError: null,
    verifyCountdown: 30,
    verifyCountdownInterval: null,
  }),
  computed: {
    ...mapState(['currentUser', 'bloggerOptions']),
    formattedLogin() {
      const unspaced = this.login.toLowerCase().replaceAll(' ', '');
      const match = unspaced.match(
        /(https?:\/\/)?(www\.)?(instagram\.com\/?)?@?(?<instaname>[a-zA-Z0-9._]+)\/?/
      );
      return (match ? match.groups.instaname.replaceAll('@', '') : '').toLowerCase();
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    clear() {
      this.code = null;
      this.change = true;
      this.verifyCountdown = 30;
      this.login = '';
      this.error = '';
      this.codeError = '';
      this.loading = false;
      clearInterval(this.verifyCountdownInterval);
    },
    async submitLogin() {
      if (!this.login) {
        return;
      }
      await this.getCode();
    },
    async getCode() {
      try {
        this.loading = true;
        const response = await bloggers.getInstagramCode(this.formattedLogin);
        if (response.success) {
          this.$set(this, 'code', `${response.code}`);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async checkCode() {
      try {
        this.loading = true;
        this.verifyCountdown = 30;
        this.verifyCountdownInterval = setInterval(() => {
          if (this.verifyCountdown >= 1) {
            this.verifyCountdown -= 1;
          } else {
            this.verifyCountdown = 30;
            clearInterval(this.verifyCountdownInterval);
            this.verifyCountdownInterval = null;
          }
        }, 1000);
        const response = await bloggers.checkInstagramCode(this.prAccount);
        await this.loadUser();
        this.change = false;
      } catch (e) {
        if (e.response.data && e.response.data.error) {
          this.codeError = e.response.data.error;
        } else if (e.response.status === 404) {
          this.codeError = `Проверьте правильность ранее вставленного кода.\n
          Проверьте, на тот ли аккаунт вы пытаетесь поставить ссылку`;
        } else if (e.response.status === 400) {
          this.codeError = `Такого аккаунта не существует!`;
        } else if (e.response.status >= 500) {
          this.codeError = `Сервер перегружен, попробуйте позже!`;
        }
        this.loading = false;
      } finally {
        clearInterval(this.verifyCountdownInterval);
        this.verifyCountdownInterval = null;
        this.verifyCountdown = 30;
        this.loading = false;
      }
    },
  },
  watch: {
    login() {
      if (this.code) {
        this.code = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ph-36 {
  height: 36px;
}
.img-acc-exmpl {
  max-width: 350px !important;
  width: 100% !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.static-inp {
  transition: 0.3s ease-in-out;
}
.err-txt {
  margin-bottom: 15px;
  color: red;
  font-size: 0.9rem;
}
</style>
