import qs from 'querystring';
import apiService from './index';

export default {
  infoProducts: {
    async create(data) {
      const keys = Object.keys(data);
      if (keys) {
        keys.forEach((key) => {
          const value = data[key];
          if (value && typeof value === 'object') {
            if (Object.prototype.hasOwnProperty.call(data[key], 'id')) {
              data[key] = data[key].id;
            } else if (Object.prototype.hasOwnProperty.call(data[key], 'value')) {
              data[key] = data[key].value;
            }
          }
        });
      }
      const response = await apiService.post(`/api/producing/info_products/`, data);
      return response;
    },
    moderation: {
      async update(data) {
        const response = await apiService.put(
          `/api/producing/info_products_moderation/${data.id}/`,
          data
        );
        return response;
      },
      async list(params) {
        const response = await apiService.get(`/api/producing/info_products_moderation/`, {
          params,
        });
        return response;
      },
    },
    async list(params) {
      const response = await apiService.get(`/api/producing/info_products/`, { params });
      return response;
    },
    async apiSchema(params) {
      const response = apiService.get(`/api/producing/info_products/api_schema/`, {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      });
      return response;
    },
    async toggleHidden(id, remove) {
      const response = await apiService.post(`/api/producing/info_products/${id}/toggle_hidden/`, {
        remove,
      });
      return response;
    },
    async deleteAll() {
      const response = await apiService.post(`/api/producing/info_products/delete_all/`);
      return response;
    },
  },
  producerProfiles: {
    async create(data) {
      const response = await apiService.post(`/api/producing/producer_profiles/`, data);
      return response;
    },
    async apiSchema(params) {
      const response = apiService.get(`/api/producing/producer_profiles/api_schema/`, {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      });
      return response;
    },
    me: {
      async update(data) {
        const response = await apiService.put(`/api/producing/producer_profiles/me/`, data);
        return response;
      },
    },
  },
  producerResponse: {
    async create(data) {
      const response = await apiService.post(`/api/producing/producer_responses/`, data);
      return response;
    },
  },
};
