<template>
  <div class="mt-2">
    <span style="line-height: 17px" class="fs-12 gray-text" v-if="taskInfo"
      >Статус отправки: {{ sendStatus }}</span
    >
    <span class="fs-12 gray-text" v-else>Получение информации о доставке смс</span>
    <dot-loader
      :interval="320"
      v-if="loadingStatus"
      class="d-inline-block gray-text fs-12"
    ></dot-loader>
    <p v-if="error" class="my-3 fs-12" style="color: red">{{ error }}</p>
  </div>
</template>

<script>
import queues from '@main/api/queues';
import DotLoader from '@main/components/reusable/elements/loaders/DotLoader.vue';

export default {
  data: () => ({
    pollingStatuses: ['PENDING', 'RETRY'],
    sendStatusMapping: {
      PENDING: 'ожидает отправки оператору',
      SUCCESS: 'отправлено оператору',
      FAILURE: 'ошибка отправки оператору',
      RETRY: 'ошибка, повторяем попытку отправки оператору',
    },
    loop: null,
    taskInfo: null,
  }),
  components: {
    DotLoader,
  },
  props: {
    taskId: String,
  },
  computed: {
    loadingStatus() {
      return !this.taskInfo || this.taskInfo.status === 'RETRY';
    },
    sendStatus() {
      if (!this.taskInfo) {
        return;
      }
      return this.sendStatusMapping[this.taskInfo.status];
    },
    // eslint-disable-next-line vue/return-in-computed-property
    error() {
      if (this.taskInfo && this.taskInfo.result) {
        if (this.taskInfo.result.error_code) {
          const code = Number(this.taskInfo.result.error_code);
          if (code === 7) {
            return 'Номер не существует. Введите другой номер, чтобы продолжить.';
          }
          if (code === 8) {
            return 'Возможно, ваш номер заблокирован или не обслуживается оператором. Подождите 10-15 минут и повторите попытку.';
          }
          if ([1, 2, 3, 4, 5, 9].includes(code)) {
            return 'Ошибка сервера. Подождите 10-15 минут и повторите попытку.';
          }
        } else if (this.taskInfo.result.errcode) {
          const code = Number(this.taskInfo.result.errcode);
          if (code === 1) {
            return 'Превышен ежедневный лимит на отправку смс, повторите попытку завтра';
          }
          if (code === 2) {
            return 'Номер не поддерживается. Введите другой номер, чтобы продолжить.';
          }
          if (code >= 3) {
            return 'Ошибка сервера. Подождите 10-15 минут и повторите попытку.';
          }
        } else if (this.taskInfo.status === 'FAILURE') {
          return 'Ошибка сервера. Подождите 10-15 минут и повторите попытку.';
        }
      }
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    poll(task_id) {
      queues.getTaskInfo({ task_id }).then((res) => {
        if (!this.pollingStatuses.includes(res.status)) {
          clearInterval(this.loop);
        }
        this.taskInfo = res;
      });
    },
    startWatch() {
      if (this.loop) {
        return;
      }
      this.loop = setInterval(() => {
        this.poll(this.taskId);
      }, 2000);
    },
  },
  watch: {
    taskId(val) {
      if (val) {
        this.startWatch();
      }
    },
  },
  mounted() {
    this.startWatch();
  },
};
</script>

<style lang="scss" scoped></style>
