<template>
  <modal
    :show="emailActivationModal.open"
    @hide="() => toggleEmailActivationModal({ open: false, context: { email: null } })"
    title="Подтвердите почту"
    :displayClose="!emailActivationModal.context.disclose"
    wrapClass="popup-active-search__wrap step-1"
    contentClass="popup__content popup-confirm-email__content"
  >
    <div v-if="activationMode">
      <div v-if="!emailSent">
        <button @click="sendActivation" class="btn--border mgn15">
          Отправить email с подтверждением
        </button>
        <p class="popup-confirm-email__text">
          Нажмите кнопку выше если вы еще не получали письмо с подтверждением
        </p>
      </div>
      <p v-else class="popup-confirm-email__text">
        На указанный e-mail было отправлено письмо с ссылкой подтверждения. Обязательно перейдите по
        ссылке из письма. Если ссылка не работает - введите код из заголовка письма.
      </p>
      <div class="d-flex mb-2">
        <b-input class="mr-2" placeholder="Код из заголовка письма" v-model="code"></b-input>
        <b-button variant="outline-default" @click="confirmByCode">Подтвердить</b-button>
      </div>
      <div class="popup-confirm-email__footer">
        <button
          @click="checkActivation"
          class="btn--border popup-confirm-email__btn"
          v-bind:class="{ disabled: !checkActivationMode || checkButtonDisabled }"
        >
          Я подтвердил(-а) почту
        </button>
        <span class="popup-confirm-email__timer" v-if="!checkActivationMode">{{ timerCount }}</span>
        <button
          class="link-blue js-btnPopup"
          data-popup="popupConfirmEmailRetry"
          v-if="checkActivationMode"
          @click="sendAgain"
        >
          {{ emailActivationModal.context.noEmailText || 'Письмо не пришло' }}
        </button>
      </div>
      <span class="popup-confirm-email__error-msg" v-bind:class="{ show: errorActivation }"
        >E-mail не подтверждён. Попробуйте снова.</span
      >
    </div>
    <div v-else>
      <p class="popup-confirm-email__text" v-if="showEmailInfo">
        Проверьте правильность введенного e-mail, и при необходимости измените его. Затем нажмите на
        кнопку Подтвердить.
      </p>
      <p class="popup-confirm-email__text" v-if="showEmailInfo">
        Если письмо всё равно не пришло, проверьте папку спам.
      </p>
      <form @submit.prevent="sendActivation">
        <label for="email" class="label popup-confirm-email__label">Ваш e-mail</label>
        <input
          type="email"
          :class="{ error: $v.email.$anyError }"
          class="input popup-confirm-email__input"
          v-model="$v.email.$model"
          @click="
            () => {
              customError = '';
            }
          "
          required
          value="korotylka@gmail.com"
        />
        <div class="d-flex justify-content-between">
          <button
            class="btn--border popup-confirm-email__btn--small js-btnPopup js-btnPopup-timer"
            data-popup="popupConfirmEmailStep2"
          >
            Подтвердить
          </button>
          <button
            @click.prevent="
              () => {
                showEmailInfo = false;
                activationMode = true;
              }
            "
            class="
              ml-auto
              btn--border
              popup-confirm-email__btn--small
              js-btnPopup js-btnPopup-timer
            "
          >
            Назад
          </button>
        </div>
      </form>
      <span v-if="customError" class="popup-confirm-email__error-msg show">{{ customError }}</span>
      <span v-if="$v.email.$anyError" class="popup-confirm-email__error-msg show"
        >Введите корректный e-mail</span
      >
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import Modal from '@main/components/reusable/modals/Modal.vue';
import auth from '@main/api/users';

export default {
  props: {
    showEmailActivation: Boolean,
  },
  components: {
    Modal,
  },
  data() {
    return {
      activationMode: false,
      email: '',
      timerCount: null,
      checkActivationMode: false,
      timer: null,
      errorActivation: false,
      checkButtonDisabled: false,
      showEmailInfo: false,
      emailSent: false,
      customError: '',
      code: '',
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('popups', ['emailActivationModal']),
  },
  validations: {
    email: { required, email },
  },
  methods: {
    ...mapMutations('popups', ['toggleEmailActivationModal']),
    ...mapMutations('notifications', ['showMessage']),
    ...mapActions(['loadUser']),
    async initEmail() {
      if (
        (this.currentUser &&
          this.currentUser.profile.email &&
          !this.currentUser.profile.email_confirmed) ||
        this.emailActivationModal.context.register
      ) {
        this.activationMode = true;
        this.checkActivationMode = true;
        if (this.emailActivationModal.context.register) {
          this.emailSent = true;
        }
      }
    },
    async sendActivation() {
      try {
        await auth.sendEmail(
          this.emailActivationModal.context.email || this.email || this.currentUser.profile.email,
          '/settings/'
        );
      } catch (e) {
        if (e.response.status === 500) {
          this.customError = `Не удалось отправить e-mail с подтверждением на указаный адрес.
            Проверьте правильность введенного адреса и попробуйте еще раз.`;
        } else if (e.response.status === 400) {
          this.customError = 'Указанный e-mail уже зарегистрирован';
        }
        return;
      }
      this.errorActivation = false;
      this.checkActivationMode = true;
      this.activationMode = true;
      this.emailSent = true;
      this.timer = setInterval(() => {
        if (this.timerCount === 0) {
          this.timerCount = 5;
          this.checkActivationMode = true;
          clearInterval(this.timer);
          return;
        }
        this.timerCount -= 1;
      }, 1000);
    },
    async confirmByCode() {
      await auth.checkEmail(this.code);
      await this.checkActivation();
    },
    async checkActivation() {
      this.checkButtonDisabled = true;
      await this.loadUser();
      this.checkButtonDisabled = false;
      if (this.currentUser.profile.email_confirmed) {
        if (
          this.emailActivationModal.callback &&
          typeof this.emailActivationModal.callback === 'function'
        ) {
          try {
            this.emailActivationModal.callback();
          } catch (e) {
            console.log(e);
          }
        }
        this.$emit('hide');
      } else {
        this.errorActivation = true;
      }
    },
    async sendAgain() {
      this.showEmailInfo = true;
      this.activationMode = false;
    },
  },
  async mounted() {
    this.initEmail();
  },
};
</script>

<style scoped>
.show {
  display: block !important;
}
.mgn15 {
  margin-bottom: 15px;
}
</style>
