import Vue from 'vue';
import uiHelpers from '@main/api/uiHelpers';
import { readMessage, getMessages } from '../../api/notify';

function checkMenuShadow(state) {
  if (!window.$) return;
  if (state.messages.length) {
    window.$('.header').addClass('no-shadow');
  } else {
    window.$('.header').removeClass('no-shadow');
  }
}

export default {
  namespaced: true,
  state: {
    messages: [],
    sideNotifications: [],
    windows: [],
    currentHelperItemChain: [],
    currentHelperItemChainElement: 0,
    helperItemChains: {},
  },
  mutations: {
    setMessages(state, data) {
      data.forEach((message) => {
        if (!state.messages.map((v) => v.id).includes(message.id)) {
          state.messages.push(message);
        }
      });
    },
    showMessage(state, message) {
      state.messages.push(message);
      checkMenuShadow(state);
    },
    addMessage(state, message) {
      state.messages.push(message);
      checkMenuShadow(state);
    },
    popMessage(state) {
      state.messages.pop();
      checkMenuShadow(state);
    },
    clearMessages(state) {
      state.messages = [];
      checkMenuShadow(state);
    },
    setWindows(state, data) {
      data.forEach((window) => {
        if (
          !state.windows.map((v) => v.id).includes(window.id) &&
          !localStorage.getItem(`msgid_${window.id}`)
        ) {
          state.windows.push({ ...window, msgid: window.id, ...window.bind_attrs });
        }
      });
    },
    addWindow(state, data) {
      state.windows.push(data);
    },
    popWindow(state, data) {
      return state.windows.pop();
    },
    clearWindows(state) {
      state.windows = [];
    },
    setSideNotifications(state, data) {
      state.setSideNotifications = data;
    },
    addSideNotification(state, data) {
      state.sideNotifications.push(data);
    },
    popSideNotification(state, data) {
      state.sideNotifications.pop();
    },
    clearSideNotification(state, data) {
      state.sideNotifications = [];
    },
    setCurrentHelperItemChain(state, data) {
      state.currentHelperItemChain = data;
    },
  },
  getters: {
    activeWindow(state) {
      if (!state.windows.length) {
        return;
      }
      return state.windows.slice(-1)[0];
    },
    activeMessage(state) {
      if (!state.messages.length) {
        return;
      }
      return state.messages.slice(-1)[0];
    },
    activeSideNotifications(state) {
      if (!state.sideNotifications.length) {
        return;
      }
      return state.sideNotifications.slice(-3);
    },
  },
  actions: {
    readMessage({ commit, state }) {
      const message = state.messages.slice(-1)[0];
      commit('popMessage');
      if (message.id) {
        readMessage(message.id).then((res) => {
          console.log(res);
        });
      }
    },
    setWindow({ commit, state, rootState }, data) {
      if (!data) {
        const window = state.windows.slice(-1)[0];
        commit('popWindow');
        if (window) {
          if (window.closeCallback) {
            window.closeCallback();
          }
          if (window.id) {
            if (!rootState.currentUser) {
              localStorage.setItem(`msgid_${window.id}`, true);
            }
            readMessage(window.id).then((res) => {
              console.log(res);
            });
          }
        }
      } else {
        commit('addWindow', data);
      }
    },
    async loadNotifications({ commit, state }) {
      const notifications = await getMessages();
      const messages = notifications.results.filter((val) => !val.msgtype || val.msgtype === 0);
      const windows = notifications.results.filter((val) => val.msgtype === 1);
      const sides = notifications.results.filter((val) => val.msgtype === 2);
      commit('setMessages', messages);
      commit('setWindows', windows);
      commit('setSideNotifications', sides);
    },
    async loadHelperItemChain({ commit }, systemName) {
      const chain = uiHelpers.helperItemChains.get(systemName);
      commit('setCurrentHelperChain', chain.helper_items);
    },
  },
};
