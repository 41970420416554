import store from '../store/index';

export default {
  triggersLoading(func, attr, bypass) {
    if (!bypass) {
      store.commit('startLoading', attr);
    }
    let result;
    try {
      result = func();
      console.log(result);
    } catch (e) {
      console.log(e);
    }
    if (!bypass) {
      store.commit('stopLoading', attr);
    }
    return result;
  },
};
