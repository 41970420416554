import apiService from './index';

export default {
  buttons: {
    activeServices: {
      async get(params) {
        const res = await apiService.get('/api/user_interface/elements/service_buttons/', {
          params,
        });
        return res;
      },
    },
    menuButtons: {
      async get(params) {
        const res = await apiService.get('/api/user_interface/elements/menu_buttons/', {
          params,
        });
        return res;
      },
    },
  },
};
