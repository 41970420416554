import prAgency from '@main/api/prAgency';
import enums from '@main/enums';

export default {
  namespaced: true,
  state: {
    services: [],
    apiState: enums.INIT,
  },
  actions: {
    async loadServices(context) {
      const options = await prAgency.prAgencyRequestService.list();
      context.commit('setServices', options.results);
    },
  },
  mutations: {
    setServices(state, data) {
      state.services = data;
    },
    setApiState(state, data) {
      state.apiState = data;
    },
  },
};
