import Vue from 'vue';
import crypto from '@main/func/crypto';
import payments from '@main/api/payments';
/* eslint-disable func-names */

export default {
  namespaced: true,
  state: {
    showEnergyPriceModal: false,
    showSubscriptionPriceModal: false,
    energyPrice: [],
  },
  mutations: {
    toggleEnergyPriceModal(state, data) {
      Vue.set(state, 'showEnergyPriceModal', data);
    },
    toggleSubscriptionPriceModal(state, data) {
      Vue.set(state, 'showSubscriptionPriceModal', data);
    },
    setEnergyPrice(state, data) {
      Vue.set(state, 'energyPrice', data);
    },
  },
  actions: {
    loadEnergyPrice({ commit, state }) {
      const hash = 'Djidj&^TAdh8#D&^#Dadg*GDf#FDDOjd(37';
      // eslint-disable-next-line func-names
      const decorated = crypto.requestDebounce(
        async function () {
          if (!state.energyPrice || !state.energyPrice.length) {
            const response = await payments.energy.getPrice();
            commit('setEnergyPrice', response.results);
          }
        },
        hash,
        30
      );
      decorated();
    },
  },
};
