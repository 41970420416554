import lodash from 'lodash';

/* eslint-disable no-continue, no-restricted-syntax */

const typeMapping = {
  string: String(),
  boolean: Boolean(),
  choice: Number(),
  integer: Number(),
  'image upload': Object(),
};

export default {
  pydanticMetadataConverter(metadata) {
    const DRFMetadata = {
      actions: { POST: {} },
    };
    Object.entries(metadata.properties).forEach((prop) => {
      const propData = prop[1];
      const propKey = prop[0];
      DRFMetadata.actions.POST[propKey] = {
        label: propData.title,
        api_widget: null,
        type: propData.type,
        read_only: false,
        required: true,
        default: propData.default,
      };
      if (propData.type === 'integer') {
        DRFMetadata.actions.POST[propKey] = {
          ...DRFMetadata.actions.POST[propKey],
          min_value: -9999999,
          max_value: 9999999,
        };
      }
    });
    console.log(DRFMetadata);
    return DRFMetadata;
  },
  djangoRestFrameworkMetadataParser(metadata, { nullbool } = {}) {
    const model = {};
    const metaKeys = Object.keys(metadata);
    for (const mKey of metaKeys) {
      if (Object.prototype.hasOwnProperty.call(metadata[mKey], 'type')) {
        let mType = metadata[mKey].type;
        if (mType === 'field') {
          if (Object.prototype.hasOwnProperty.call(metadata[mKey], 'child')) {
            mType = [];
          }
        } else if (mType === 'nested object') {
          if (Object.prototype.hasOwnProperty.call(metadata[mKey], 'children')) {
            mType = this.djangoRestFrameworkMetadataParser(metadata[mKey].children);
          }
        }
        if (typeof mType === 'object') {
          model[mKey] = mType;
        } else if (mType === 'boolean' && nullbool) {
          model[mKey] = null;
        } else if (!metadata[mKey].required) {
          model[mKey] = null;
        } else if (metadata[mKey].default) {
          model[mKey] = metadata[mKey].default;
        } else {
          model[mKey] = typeMapping[mType];
        }
      }
    }
    return model;
  },
  idifyObjects(keys, object) {
    const copy = lodash.cloneDeep(object);
    Object.keys(copy).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(copy[key], 'id')) {
        copy[key] = copy[key].id;
      }
    });
    return copy;
  },
};
