import Vue from 'vue';
import store from '@main/store/index';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import apiService from './index';

export default {
  async get(id) {
    const response = await apiService.get(`/api/users/${id}/`);
    return response;
  },
  async getMe() {
    try {
      const response = await apiService.get('/api/users/me/');
      return response;
    } catch (e) {
      if (e.response.status === 500) {
        store.dispatch('notifications/setWindow', {
          iconPath: alertGreyIcon,
          text: 'Произошла ошибка при загрузке вашего профиля, проверьте подключение к интернету и/или повторите позже. При длительном присутствии ошибки - обратитесь к администрации для восстановления',
        });
      }
      return e;
    }
  },
  async updateMe(data) {
    const response = await apiService.put('/api/users/me/', data, {
      raxConfig: {
        retry: 3,
        retryDelay: 1000,
      },
    });
    return response;
  },
  async login(phone, password) {
    const response = await apiService.post('/api/token/', { phone, password });
    return response;
  },
  async register(email, password) {
    const resp = await apiService.post('/api/users/register/', {
      email,
      password,
      csrfmiddlewaretoken: Vue.$cookies.get('csrftoken'),
    });
    return resp;
  },
  async checkUserExists(data) {
    const response = await apiService.post('/api/users/check_user_exists/', data);
    return response;
  },
  async sendCode(data, params) {
    const response = await apiService.post(`/api/users/send_code/`, data);
    return response;
  },
  async checkCode(data) {
    const response = await apiService.post('/api/users/check_code/', data);
    return response;
  },
  async changePhone(data) {
    const response = await apiService.post(`/api/users/change_phone/`, data);
    return response;
  },
  async changePassword(data) {
    const response = await apiService.post(`/api/users/change_password/`, data);
    return response;
  },
  async checkPassword(password) {
    const response = await apiService.post('/api/users/check_password/', { password });
    return response;
  },
  async toggleSubscribe(value) {
    const response = await apiService.post('/api/users/toggle_subscribe/', { value });
    return response;
  },
  /**
   * Подтверждение email
   */
  // eslint-disable-next-line camelcase
  async sendEmail(email, destination, extra_params) {
    const response = await apiService.post('/api/users/send_mail/', {
      email,
      destination,
      extra_params,
    });
    return response;
  },

  /**
   * Подтверждение email
   */
  async checkEmail(token) {
    const response = await apiService.post('/api/users/check_mail/', { token });
    return response;
  },
};
