<template>
  <div :class="['base-popup', wrapperTypeClass, 'wrap-popup', open ? 'open' : '']">
    <div class="wrap-popup__inner">
      <component v-if="warningComponent" :is="warningComponent"></component>
      <a @click="() => $emit('close')" class="login__close close-btn"
        ><img src="@main/assets/img/svg/close-btn.svg" alt="img"
      /></a>
      <div class="login__tabs-wrap">
        <div class="login__head">
          <p v-if="upperHtml">{{ upperHtml }}</p>
          {{ title }}
          <div class="login__head-capt">Шаг {{ currentStep }} из {{ steps }}</div>
        </div>
        <slot></slot>
        <div class="login__content">
          <div
            v-for="(step, i) in steps"
            :class="['popup-tap', step == currentStep ? 'active' : '']"
            :key="i"
          >
            <slot :name="step"></slot>
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    open: {
      type: Boolean,
    },
    steps: {
      type: Number,
    },
    currentStep: {
      type: Number,
    },
    wrapperTypeClass: {
      type: String,
    },
    title: {
      type: String,
    },
    warningComponent: {
      required: false,
      type: Vue,
    },
    upperHtml: {
      required: false,
      type: Object,
    },
  },
  watch: {
    open: {
      handler(val) {
        this.$bus.$emit('modal-open', val);
      },
      immediate: true,
    },
  },
  destroyed() {
    this.$bus.$emit('modal-open', false);
  },
};
</script>

<style lang="css" scoped></style>
