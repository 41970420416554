import qs from 'qs';
import lodash, { update } from 'lodash';
import apiService from './index';
import instagram from './instagram';

const BLOGGER_CARD_FILTER_PARAMS = {
  coverage_stories_min: null,
  coverage_stories_max: null,
  coverage_posts_min: null,
  coverage_posts_max: null,
  females_percent_min: null,
  females_percent_max: null,
  city: null,
  city_percent: null,
  country: null,
  country_percent: null,
  audience: null,
  audience_percent: null,
  screens_last_updated: null,
};

export default {
  defaultFilterParams: {
    executor: '',
    page: 1,
    page_size: 50,
    ad_type: '',
    price_min: null,
    price_max: null,
    price_per_one__from: null,
    price_per_one__to: null,
    arrival__from: null,
    arrival__to: null,
    rate: 0,
    price_meta: [],
    tags: [],
    mode: 'all',
    item_q: '',
    customer_kind: 'blogger',
    deleted_reviews: false,
    valid_advertiser_blogger: null,
    ...BLOGGER_CARD_FILTER_PARAMS,
  },
  rateTags: {
    async list() {
      const response = await apiService.get('/api/reviews/rate_tags/');
      return response;
    },
    async bloggerDisplay(blogger) {
      const response = await apiService.post('/api/reviews/rate_tags/blogger_display/', {
        blogger,
      });
      return response;
    },
  },
  bloggerSuspiciousActivity: {
    async create(data) {
      const response = await apiService.post('/api/reviews/blogger_suspicious_activity/', data);
      return response;
    },
  },
  suspiciousActivityReasons: {
    async list() {
      const response = await apiService.get('/api/reviews/suspicious_activity_reasons/');
      return response;
    },
  },
  bans: {
    async list(params) {
      const response = await apiService.get('/api/reviews/bans/', { params });
      return response;
    },
    async update(data) {
      const response = await apiService.put(`/api/reviews/bans/${data.id}/`, data);
      return response;
    },
    async create(data) {
      const response = await apiService.post(`/api/reviews/bans/`, data);
      return response;
    },
  },
  reports: {
    async create(data) {
      const response = await apiService.post('/api/reviews/reports/', data);
      return response;
    },
    async options() {
      const response = await apiService.options('/api/reviews/reports/');
      return response;
    },
  },
  async fnc(data, params) {
    const response = await apiService.post('/api/reviews/fnc/', data, { params });
    return response;
  },
  async list(params, { page } = {}) {
    if (page) params.page = page;
    if (
      Object.keys(lodash.pickBy(params, lodash.identity)).some((param) =>
        Object.keys(BLOGGER_CARD_FILTER_PARAMS).includes(param)
      )
    ) {
      params.valid_advertiser_blogger = true;
    }
    const keys = Object.keys(params);
    if (keys) {
      keys.forEach((key) => {
        const value = params[key];
        if (value && typeof value === 'object') {
          if (Object.prototype.hasOwnProperty.call(params[key], 'id')) {
            params[key] = params[key].id;
          } else if (Object.prototype.hasOwnProperty.call(params[key], 'value')) {
            params[key] = params[key].value;
          }
        }
      });
    }
    const response = await apiService.get('/api/reviews/', {
      params,
      paramsSerializer: (ps) => {
        return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
      },
      raxConfig: {
        retry: 3,
        retryDelay: 5000,
      },
    });
    return response;
  },
  async get(id, params) {
    const response = await apiService.get(`/api/reviews/${id}/`, { params });
    return response;
  },
  async create(data) {
    const response = await apiService.post('/api/reviews/', data);
    return response;
  },
  async update(data) {
    const response = await apiService.patch(`/api/reviews/${data.id}/`, data);
    return response;
  },
  async delete(id) {
    const response = apiService.delete(`/api/reviews/${id}/`);
    return response;
  },
  async apiSchema(params) {
    const response = apiService.get(`/api/reviews/api_schema/`, {
      params,
      paramsSerializer: (ps) => {
        return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
      },
    });
    return response;
  },
  async deletionRequest(id) {
    const response = apiService.post(`/api/reviews/${id}/deletion_request/`);
    return response;
  },
  async deleteRefuse(id) {
    const response = apiService.post(`/api/reviews/${id}/delete_refuse/`);
    return response;
  },
  async checkMore(advertisers, customerKind) {
    const response = await apiService.post('/api/reviews/check-more/', {
      advertisers,
      customer_kind: customerKind,
    });
    return response;
  },
  async getBloggerSample() {
    const response = await apiService.get(`/api/reviews/get_blogger_sample/`);
    return response;
  },
  async count(instaname) {
    const response = await apiService.get(`/api/reviews/count/${instaname}`);
    return response;
  },
  async like(id, params) {
    const response = await apiService.post(
      `/api/reviews/${id}/like/`,
      {},
      {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      }
    );
    return response;
  },
  telegram: {
    async fnc(data, params) {
      const response = await apiService.post('/api/reviews/telegram/fnc/', data, { params });
      return response;
    },
    async list(params, { page } = {}) {
      if (page) params.page = page;
      if (
        Object.keys(lodash.pickBy(params, lodash.identity)).some((param) =>
          Object.keys(BLOGGER_CARD_FILTER_PARAMS).includes(param)
        )
      ) {
        params.valid_advertiser_blogger = true;
      }
      const keys = Object.keys(params);
      if (keys) {
        keys.forEach((key) => {
          const value = params[key];
          if (value && typeof value === 'object') {
            if (Object.prototype.hasOwnProperty.call(params[key], 'id')) {
              params[key] = params[key].id;
            } else if (Object.prototype.hasOwnProperty.call(params[key], 'value')) {
              params[key] = params[key].value;
            }
          }
        });
      }
      const response = await apiService.get('/api/reviews/telegram/', {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
        raxConfig: {
          retry: 3,
          retryDelay: 5000,
        },
      });
      return response;
    },
    async get(id, params) {
      const response = await apiService.get(`/api/reviews/telegram/${id}/`, { params });
      return response;
    },
    async create(data) {
      const response = await apiService.post('/api/reviews/telegram/', {
        ...data,
      });
      return response;
    },
    async update(data) {
      const response = await apiService.patch(`/api/reviews/telegram/${data.id}/`, data);
      return response;
    },
    async delete(id) {
      const response = apiService.delete(`/api/reviews/telegram/${id}/`);
      return response;
    },
    async apiSchema(params) {
      const response = apiService.get(`/api/reviews/telegram/api_schema/`, {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      });
      return response;
    },
    async deletionRequest(id) {
      const response = apiService.post(`/api/reviews/telegram/${id}/deletion_request/`);
      return response;
    },
    async deleteRefuse(id) {
      const response = apiService.post(`/api/reviews/telegram/${id}/delete_refuse/`);
      return response;
    },
    async checkMore(advertisers, customerKind) {
      const response = await apiService.post('/api/reviews/telegram/check-more/', {
        advertisers,
        customer_kind: customerKind,
      });
      return response;
    },
    async getBloggerSample() {
      const response = await apiService.get(`/api/reviews/telegram/get_blogger_sample/`);
      return response;
    },
    async count(instaname) {
      const response = await apiService.get(`/api/reviews/telegram/count/${instaname}`);
      return response;
    },
    async like(id, params) {
      const response = await apiService.post(
        `/api/reviews/telegram/${id}/like/`,
        {},
        {
          params,
          paramsSerializer: (ps) => {
            return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
          },
        }
      );
      return response;
    },
  },
};
