<template>
  <tr v-if="bloggerOptions" class="table__options">
    <td class="table__td align-middle">
      <div class="table__td-wrap">
        <div class="table__icon">
          <img :src="getIcon" alt="clock" class="table__icon--pic" />
        </div>
        <div class="table__td--text">{{ getCurrentPrType }}</div>
      </div>
    </td>
    <td
      :style="blogger && blogger.pr_agency_member ? 'line-height: 30px !important' : ''"
      v-if="!hidden"
      class="table__td table__price align-middle"
    >
      <span
        class="mr-2"
        :style="
          blogger && blogger.pr_agency_member
            ? 'text-decoration: line-through; color: #6A7B83 !important'
            : ''
        "
        >{{ prType.uPrice }} ₽</span
      >
      <template v-if="blogger && blogger.pr_agency_member">
        <span class="mr-2">{{ Math.ceil(prType.uPrice - prType.uPrice * 0.1) }} ₽</span>
        <span class="p-2 mr-2" style="background-color: #fadb67; border-radius: 5px">-10%</span>
      </template>
    </td>
    <td v-else class="table__td table__price font-weight-bold align-middle">Скрыто</td>
    <td v-if="!hidden" class="table__td table__cond align-middle">
      Набор на {{ getCurrentPrMonth }}. {{ getCurrentOrdererType }}.
      {{ prType.content ? prType.content + '.' : '' }}
    </td>
    <td v-else class="table__td table__cond font-weight-bold align-middle">Скрыто</td>
    <td class="table__td table__cond">
      <template v-if="!hideClickButton">
        <b-button
          @click="defaultCartClickHandler"
          v-if="choosen"
          class="ml-auto py-2 px-2 d-inline-block"
          style="min-width: 107.56px"
          variant="outline-default"
          ><img
            src="@main/assets/img/svg/green-check.svg"
            class="d-inline-block mr-2"
            style="margin-bottom: 2px"
          />В корзину</b-button
        >
        <!--b-button
          @click="prTypeClickHandler ? prTypeClickHandler(prType) : updateCart()"
          v-else
          variant="yellow"
          class="ml-auto py-2 px-4"
          >Выбрать</!--b-button
        -->
        <b-button
          class="ml-2 ph-36"
          variant="outline-default"
          v-if="choosen"
          @click="removeHandler ? removeHandler(prType) : removeFromCart()"
          ><img src="@main/assets/img/svg/no.svg" alt="" /></b-button
      ></template>
    </td>
  </tr>
</template>

<script>
import PrTypeDisplayableMixin from '@main/mixins/statistic/PrTypeDisplayableMixin.vue';

export default {
  mixins: [PrTypeDisplayableMixin],
};
</script>
