import Vue from 'vue';
import Router from 'vue-router';
import bloggers from '@main/api/bloggers';
import auth from '@main/api/users';
import {
  pipelineFactory,
  RequiresAuth,
  RequiresBloggerProfile,
  RequiresEnergy,
  RequiresStaff,
  RequiresSub,
  RequiresIdentification,
  RequiresPhoneNumber,
  RequiresBloggerProfileModerated,
  OnCondition,
  RequiresAuthenticatedInstagram,
  ReloadUser,
} from '@main/func/pipeline';
import ReviewsList from '@main/components/reviews_list/ReviewsList.vue';
import App from '@main/App.vue';
import payments from '@main/api/payments';
import referal from '@main/api/referal';
import VueYandexMetrika from 'vue-yandex-metrika';
import { setNextPage, getNextPage } from '@main/util';
import ReviewRegisterWarning from '@main/components/reusable/elements/warnings/ReviewRegisterWarning.vue';
import FillStatWarning from '@main/components/reusable/elements/warnings/FillStatWarning.vue';
import permissions from './permissions';
import store from './store/index';

Vue.use(Router);

const base = process.env.NODE_ENV === 'production' ? '/' : '/vue/';

const routes = [
  {
    path: '/mutualpr',
    component: () => import('@mp/App.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresBloggerProfile],
    },
    children: [
      {
        path: 'from_stories/:userId',
        props: true,
        name: 'mutualpr-from-stories',
        component: () => import('@mp/views/FromStories.vue'),
        meta: {
          mainClass: 'mp__background',
          middleware: [RequiresAuth, RequiresBloggerProfile],
          layout: 'container--mutualpr container--other',
        },
      },
      {
        path: '/',
        name: 'mutualpr',
        component: () => import('@mp/views/Home.vue'),
        meta: {
          mainClass: 'mp__background',
          layout: 'container--mutualpr container--other',
          middleware: [RequiresAuth, RequiresBloggerProfile],
          request: {
            next_page: 'routename:mutualpr',
          },
        },
      },
      {
        path: '/_',
        redirect: '/',
        meta: {
          layout: 'container--mutualpr container--other',
        },
      },
    ],
  },
  {
    path: '/infoproducts',
    component: () => import('@main/components/producing/Producing.vue'),
    children: [
      {
        path: 'create/',
        name: 'create-infoproduct',
        meta: {
          middleware: [RequiresAuth],
        },
        component: () => import('@main/components/producing/CreateInfoProduct.vue'),
      },
      {
        path: 'reply/:id',
        props: true,
        meta: {
          middleware: [RequiresAuth],
        },
        name: 'reply-to-infoproduct',
        component: () => import('@main/components/producing/ReplyToInfoProduct.vue'),
      },
      {
        path: 'list/',
        name: 'list-infoproducts',
        meta: {
          middleware: [RequiresAuth],
        },
        component: () => import('@main/components/producing/FindInfoProduct.vue'),
      },
      {
        path: 'moderation/',
        name: 'producing-moderation',
        meta: {
          middleware: [RequiresAuth, RequiresStaff],
        },
        component: () => import('@main/components/producing/ProducingModeration.vue'),
      },
    ],
  },
  {
    path: '/education',
    component: () => import('@main/components/education/Main.vue'),
    meta: {
      layout: 'container--white',
    },
    children: [
      {
        path: 'tests/meta/',
        name: 'tests-meta',
        component: () => import('@main/components/education/TestMetaData.vue'),
      },
      {
        path: 'tests/courses/',
        name: 'tests-courses',
        component: () => import('@main/components/education/Courses.vue'),
      },
      {
        path: 'tests/courses/:course',
        name: 'tests-courses-concrete',
        props: true,
        component: () => import('@main/components/education/Tests.vue'),
      },
      {
        path: 'tests/:course',
        props: true,
        name: 'education-test',
        component: () => import('@main/components/education/Test.vue'),
        meta: {
          layout: 'container--white container--other',
        },
      },
      {
        path: 'tests/',
        name: 'education-tests',
        component: () => import('@main/components/education/Tests.vue'),
        meta: {
          layout: 'container--white container--other',
        },
      },
      {
        path: 'questions/upload/',
        name: 'upload-questions',
        component: () => import('@main/components/education/UploadQuestions.vue'),
      },
      {
        path: 'api_key/generate/',
        name: 'generate-apikey',
        component: () => import('@main/components/education/GenerateApiKey.vue'),
      },
    ],
  },
  {
    path: '/pr_agency',
    name: 'pr-agency',
    meta: { middleware: [RequiresAuth] },
    component: () => import('@main/components/pr_agency/PrAgency.vue'),
    children: [
      {
        path: 'apply/',
        name: 'pr-agency-apply',
        component: () => import('@main/components/pr_agency/Apply.vue'),
      },
      {
        path: 'home/',
        name: 'pr-agency-home',
        component: () => import('@main/components/pr_agency/Main.vue'),
      },
    ],
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('@main/components/payments/Subscribe.vue'),
    meta: {
      middleware: [RequiresAuth],
      title: 'Easyprbot | Подписка',
    },
  },
  {
    path: '/report',
    name: 'review-report',
    component: () => import('@main/components/review_report/ReviewReport.vue'),
    meta: {
      middleware: [RequiresAuth],
      title: 'Easyprbot | Жалоба на отзыв',
    },
  },
  {
    path: '/home',
    name: 'home',
    cmponent: App,
    meta: { title: 'Easyprbot' },
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: ReviewsList,
    meta: { title: 'Easyprbot | Все отзывы' },
  },
  {
    path: '/reviews/telegram/',
    name: 'reviews-telegram',
    component: () => import('@main/components/reviews_list/TelegramReviewsList.vue'),
    meta: { title: 'Easyprbot | Все отзывы Telegram' },
  },
  {
    path: '/reviews/me',
    name: 'myreviews',
    component: () => import('@main/components/reviews_list/me/MyReviews.vue'),
    meta: {
      title: 'Easyprbot | Отзывы на меня',
    },
  },
  {
    path: '/reviews/new/',
    name: 'let-review',
    component: () => import('@main/components/forms/LetReview.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresIdentification],
      request: { warningComponent: ReviewRegisterWarning, next_page: '/reviews/new/' },
      title: 'Easyprbot | Оставить отзыв',
    },
  },
  {
    path: '/reviews/telegram/new/',
    name: 'let-review-telegram',
    component: () => import('@main/components/forms/LetReviewTelegram.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresIdentification],
      request: { warningComponent: ReviewRegisterWarning, next_page: '/reviews/telegram/new/' },
      title: 'Easyprbot | Оставить отзыв Telegram',
    },
  },
  {
    path: '/reviews/new/:advertiser',
    name: 'let-review-filled',
    component: () => import('@main/components/forms/LetReview.vue'),
    props: (route) => {
      const dateTimestamp = Number.parseInt(route.query.date, 10);
      const coverage = Number.parseInt(route.query.coverage, 10);
      return {
        customer: route.query.customer,
        mutual: route.query.mutual === '1',
        date: Number.isNaN(dateTimestamp) ? null : new Date(dateTimestamp),
        coverage: Number.isNaN(coverage) ? null : coverage,
        advertiser: route.params.advertiser,
      };
    },
    meta: {
      title: 'Easyprbot | Оставить отзыв',
    },
  },
  {
    path: '/reviews/edit/:id',
    name: 'edit-review',
    component: () => import('@main/components/forms/LetReview.vue'),
    props: true,
    meta: {
      middleware: [RequiresAuth],
      title: 'Easyprbot | Изменение отзыва',
    },
  },
  {
    path: '/reviews/moderation/',
    name: 'reviews-moderation',
    component: () => import('@main/components/reviews_moderation/ReviewsModeration.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresStaff],
    },
    props: (route) => {
      return {
        search: route.query.search,
        user: route.query.user,
      };
    },
  },
  {
    path: '/reviews/telegram/moderation/',
    name: 'reviews-telegram-moderation',
    component: () => import('@main/components/reviews_moderation/TelegramReviewsModeration.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresStaff],
    },
    props: (route) => {
      return {
        search: route.query.search,
        user: route.query.user,
      };
    },
  },
  {
    path: '/app/statistic',
    name: 'app-statistic',
    component: () => import('@main/components/app_statistic/AppStatistic.vue'),
    meta: { middleware: [RequiresAuth, RequiresStaff] },
  },
  {
    path: '/reviews/:advertiser',
    name: 'reviews-blogger',
    component: ReviewsList,
    props: true,
    meta: {
      title: 'Easyprbot | Отзывы на блогера',
    },
  },
  {
    path: '/blacklist',
    name: 'blacklist',
    component: () => import('@main/components/blacklist//Blacklist.vue'),
    meta: {
      title: 'Easyprbot | Черный список',
    },
  },
  {
    path: '/pricetable/',
    name: 'reviews-pricetable',
    component: () => import('@main/components/reviewprice_table/ReviewPriceTable.vue'),
    meta: {
      title: 'Easyprbot | Таблица со средней ценой за подписчика',
    },
  },
  {
    path: '/promobot/orders',
    name: 'promobot-orders',
    component: () =>
      import('@main/components/advertising_applications/AdvertisingApplications.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('promobot/loadRefuseReasons');
      next();
    },
    meta: { title: 'Easyprbot | Заявки на рекламу' },
  },
  {
    path: '/fillstat',
    name: 'fill_statistics',
    component: () => import('@main/components/statistics_form/StatisticsForm.vue'),
    meta: {
      middleware: [RequiresAuth, OnCondition],
      request: {
        condition(to, from, next) {
          if (!store.state.currentUser || !store.state.currentUser.blogger_profile) {
            return true;
          }
          const result = !store.state.currentUser.blogger_profile.filled;
          if (!result) {
            next({ path: '/settings/' });
          }
          return result;
        },
        warningComponent: FillStatWarning,
      },
      layout: 'container--stat',
      title: 'Easyprbot | Заполнить статистику',
    },
  },
  {
    path: '/fillstat_foreign/:instaname',
    props: true,
    name: 'fill_foreign_statistic',
    component: () => import('@main/components/statistics_form/StatisticFormSimple.vue'),
  },
  {
    path: '/change_instaname',
    name: 'change-instaname',
    component: () => import('@main/components/change_instaname/ChangeInstaname.vue'),
    meta: {
      middleware: [RequiresAuth],
    },
  },
  {
    path: '/change_instaname/moderation',
    name: 'change-instaname-moderation',
    component: () => import('@main/components/change_instaname/Moderation.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresStaff],
    },
  },
  {
    path: '/mystat',
    name: 'my_statistic',
    component: () => import('@main/components/my_statistic/MyStatistic.vue'),
    meta: {
      layout: 'container--stat mycard',
      middleware: [RequiresAuth],
      title: 'Easyprbot | Моя карточка',
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('loadUser');
      if (!store.state.currentUser.blogger_profile) {
        next('/fillstat/');
        store.commit('notifications/showMessage', {
          title: 'Перед этим действием нужно создать карточку блогера!',
          icon: 'alert-yellow',
        });
      } else if (!store.state.currentUser.blogger_profile.filled) {
        next('/fillstat/');
        store.commit('notifications/showMessage', {
          title: 'Заполните до конца вашу карточку блогера!',
          icon: 'alert-yellow',
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/bloggers/:instaname',
    name: 'blogger_statistic',
    component: () => import('@main/components/blogger_statistic/BloggerStatistic.vue'),
    props: (route) => {
      return {
        instaname: route.params.instaname,
        access: route.query.access,
      };
    },
    meta: {
      layout: 'container--stat',
      title: 'Easyprbot | Блогер',
    },
  },
  {
    path: '/statistic/moderation/',
    name: 'statistic-moderation',
    component: () => import('@main/components/moderation/Moderation.vue'),
    meta: {
      middleware: [RequiresAuth, RequiresStaff],
    },
  },
  {
    path: '/settings',
    name: 'statistic-settings',
    component: () => import('@main/components/settings/Settings.vue'),
    meta: {
      layout: 'container--settings',
      middleware: [RequiresAuth],
      title: 'Easyprbot | Настройки',
    },
    beforeEnter: async (to, from, next) => {
      const { token } = to.query;
      if (token) {
        const response = await bloggers.connectBlogger(token);
        await store.dispatch('loadUser');
        if (response.success) {
          store.commit('notifications/showMessage', {
            title: 'Карточка успешно привязана!',
            message:
              'Внимание! Если настройки карточки блогера не появились - перезагрузите страницу.',
            icon: 1,
          });
        }
      } else {
        await store.dispatch('loadUser');
      }
      next();
    },
  },
  {
    path: '/settings/payments/',
    name: 'payments-settings',
    meta: {
      middleware: [ReloadUser, RequiresAuth],
      title: 'Easyprbot | Платежи',
    },
    component: () => import('@main/components/settings/subscription/ManageSubscription.vue'),
  },
  {
    path: '/confirm_email/:token',
    name: 'confirm-email',
    beforeEnter: (to, from, next) => {
      auth
        .checkEmail(to.params.token)
        .then((confirmation) => {
          if (confirmation.success) {
            store.commit('notifications/showMessage', {
              title: 'Почта подтверждена',
              icon: 1,
            });
          } else {
            store.commit('notifications/showMessage', {
              title: 'Ссылка недействительна!',
              icon: 2,
            });
          }
          store.dispatch('loadUser').catch((e) => {
            console.log(e);
          });
        })
        .catch((e) => {
          if (e.response.status === 400) {
            store.commit('notifications/showMessage', {
              title: 'Почта уже подтверждена! Войдите в ваш аккаунт',
              icon: 2,
            });
            return;
          }
          store.commit('notifications/showMessage', {
            title: 'Ссылка недействительна!',
            icon: 2,
          });
        })
        .finally(() => {
          store.commit('popups/toggleEmailActivationModal', { open: false });
          next({ path: to.query.next || '/reviews' });
          if (
            !store.state.currentUser ||
            (!store.state.currentUser.profile.email && !store.state.currentUser.profile.phone)
          ) {
            store.commit('toggleSignIn', true);
          }
        });
    },
  },
  {
    path: '/subscribtion/demo',
    name: 'subscription-demo',
    component: () => import('@main/components/ad_pages/SubscribeAdvantagesAdPage.vue'),
  },
  {
    path: '/subscription/demo',
    name: 'subscribtion-demo',
    component: () => import('@main/components/ad_pages/SubscribeAdvantagesAdPage.vue'),
  },
  {
    path: '/gathering/main',
    name: 'advertising-gathering',
    component: () => import('@main/components/advertising_gathering/AdvertisingGathering.vue'),
    meta: {
      title: 'Easyprbot | Размещения в боте',
    },
  },
  {
    path: '/gatherings/main',
    name: 'advertising-gatherings',
    component: () => import('@main/components/advertising_gathering/AdvertisingGathering.vue'),
    meta: {
      title: 'Easyprbot | Размещения в боте',
    },
  },
  {
    path: '/blogger_checker',
    name: 'blogger-checker',
    component: () => import('@main/components/blogger_checker/BloggerChecker.vue'),
    meta: {
      title: 'Easyprbot | Проверка блогеров',
    },
  },
  {
    path: '/arrival_predict',
    name: 'arrival-predict',
    component: () => import('@main/components/arrival_predict/ArrivalPredict.vue'),
  },
  {
    path: '/payments/subscribe',
    name: 'payments-subscribe',
    beforeEnter(to, from, next) {
      const response = payments.sub.createPayment(to.query.rate);
    },
  },
  /*
  {
    path: '/referal',
    name: 'referal',
    component: () => import('@main/components/referal/Referal.vue'),
    meta: {
      title: 'Easyprbot | Реферальная програма',
    },
  },
  */
  {
    path: '/subscription_success',
    name: 'subscription-success',
    component: () => import('@main/components/subscription_success/SubscriptionSuccess.vue'),
    meta: {
      title: 'Easyprbot | Вы подписаны!',
    },
  },
  {
    path: '/promo/Ndi7hnDhwi1jDwja8',
    name: 'subscription-by-mail-promo',
    component: () => import('@main/components/promo/SubscriptionByMailPromo.vue'),
    meta: {
      middleware: [RequiresAuth],
    },
  },
  {
    path: '/easyprbot_*',
    beforeEnter(to, from, next) {
      window.location.href =
        'https://sub.easyprbot.com?utm_source=confirmation_code&utm_medium=instagram&utm_campaign=codes';
    },
  },
  /*
  {
    path: '/referal_*',
    async beforeEnter(to, from, next) {
      if (store.state.currentUser) {
        try {
          await referal.code.setRefererCode(to.params.pathMatch);
        } catch (e) {
          store.commit('notifications/showMessage', {
            title: 'Реферальная ссылка недействительна!',
            icon: 2,
          });
          return;
        }
      }
      window.location.href = `https://sub.easyprbot.com?utm_source=referal_code&utm_medium=easyprbot&utm_campaign=codes&referer_code=${to.params.pathMatch}`;
    },
  },
  */
  {
    path: '/research',
    component: () => import('@main/components/research/ResearchView.vue'),
    children: [
      {
        path: 'instagram_funnel',
        component: () => import('@main/components/research/InstagramFunnelResearch.vue'),
        meta: {
          middleware: [RequiresStaff],
        },
      },
    ],
  },
  {
    path: '/promobot/orders/cart',
    component: () => import('@main/components/order_cart/OrderCart.vue'),
    meta: { middleware: [RequiresAuth], title: 'Easyprbot | Корзина' },
  },
  {
    path: '/promobot/orders/answers/',
    name: 'promobot-answers',
    component: () => import('@main/components/order_answers/OrderAnswers.vue'),
    meta: {
      middleware: [RequiresAuth],
      title: 'Easyprbot | Ответы по рекламе',
    },
  },
  {
    path: '/delete_blogger_profile',
    name: 'delete-blogger-profile',
    beforeEnter: async (to, from, next) => {
      store.dispatch('notifications/setWindow', {
        topLess: false,
        title: 'Удаление карточки блогера',
        html: 'Вы уверены, что хотите удалить свою карточку блогера?',
        buttons: [
          {
            text: 'Нет',
            variant: 'outline-default',
            handler: () => {
              next('/reviews/');
            },
            attrs: {
              style: 'min-width: 150px !important',
            },
          },
          {
            text: 'Да',
            variant: 'danger',
            handler: async () => {
              try {
                await bloggers.delete(store.state.currentUser.blogger_profile.instaname);
                store.commit('notifications/showMessage', {
                  title: 'Карточка успешно удалена',
                  icon: 1,
                });
                await store.dispatch('loadUser');
              } catch (e) {
                store.commit('notifications/showMessage', {
                  title: 'Ошибка при удалении каточки!',
                  icon: 2,
                });
              }
              store.dispatch('notifications/setWindow', null);
              next('/reviews/');
            },
          },
        ],
      });
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@main/components/errors/PageNotFound.vue'),
    meta: {
      title: 'Easyprbot | 404',
    },
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@main/components/errors/ServerError.vue'),
    meta: {
      title: 'Easyprbot | 500',
    },
  },
  {
    path: '/_',
    redirect: '/',
  },
  {
    path: '/',
    beforeEnter(to, from, next) {
      if (window.location.pathname.startsWith('/render')) {
        return;
      }
      next();
    },
    redirect: { name: 'reviews' },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

Router.prototype.$setNextPage = setNextPage;
Router.prototype.$getNextPage = getNextPage;

export const router = new Router({
  mode: 'hash',
  base,
  routes,
});

Vue.use(VueYandexMetrika, {
  id: 55319803,
  router,
  env: process.env.NODE_ENV,
});

function passMiddleware(middlewares, to, from, next) {
  return new Promise((resolve, reject) => {
    try {
      const handler = pipelineFactory(middlewares);
      if (to.meta.request) {
        if (!to.meta.request.callback) {
          to.meta.request.callback = next;
          to.meta.request.to = to;
          to.meta.request.from = from;
          to.meta.request.next = next;
        }
      }
      handler.handle(next, to.meta.request || null);
    } catch (e) {
      reject(e);
    }
    resolve();
  });
}

router.beforeEach(async (to, from, next) => {
  await store.dispatch('apiLoaded');
  const { title } = to.meta;
  if (title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Easyprbot';
  }
  const middlewares = to.meta.middleware || [];
  if (middlewares.length > 0) {
    await passMiddleware(middlewares, to, from, next);
  } else {
    await next();
  }
});

export default router;
