import lodash from 'lodash';

export default {
  reorderObject(obj, order) {
    const arrangedObject = {};
    order.forEach((element) => {
      arrangedObject[element] = null;
    });
    console.log(Object.assign(arrangedObject, obj));
    return { ...obj };
  },
  reorderArrayOfObjects(obj, property, order) {
    return lodash.sortBy(obj, (element) => {
      if (!element) {
        return;
      }
      const idx = order.findIndex((item) => element[property] === item);
      if (idx === -1) {
        return obj.length;
      }
      return idx;
    });
  },
};
