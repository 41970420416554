export default {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  LOADED: 3,
  bloggers: {
    moderation_statuses: {
      M_NOT_MODERATED: 1,
      M_ACCEPTED: 2,
      M_FOR_REVISION: 3,
      M_REJECTED: 4,
      M_BANNED: 5,
      M_CHANGED: 6,
      groups: {
        safe: [2, 6],
        unsafe: [1, 3, 4, 5],
        onModeration: [1, 6],
      },
    },
  },
  reviews: {
    moderation_status: {
      NEW: 1,
      ACCEPTED: 2,
      ACCEPTED_AFTER_USER_RESEND: 3,
      ACCEPTED_AFTER_BLOGGER_RESEND: 4,
      REFUSED: 5,
      RESENT_BY_BLOGGER: 6,
      RESENT_BY_USER: 7,
      BANNED: 8,
      REFUSED_AFTER_BLOGGER_RESEND: 9,
      REFUSED_AFTER_USER_RESEND: 10,
    },
  },
};
