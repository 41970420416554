import qs from 'qs';
import apiService from './index';
import utils from './utils';

export default {
  async list(params) {
    const response = await apiService.get('/api/reviews/moder/', {
      params,
      paramsSerializer: (ps) => {
        return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
      },
    });
    return response;
  },
  async update(data) {
    const response = await apiService.patch(`/api/reviews/moder/${data.id}/`, data);
    return response;
  },
  async export(mode, cb) {
    const response = await apiService.post('/api/reviews/export/', { mode }).then(cb);
    return response;
  },
  async checkExport(idx, cb) {
    const response = await apiService.get(`/api/reviews/export/${idx}/`).then(cb);
    return response;
  },
  async getRefuseReasons() {
    const response = await apiService.get(`/api/reviews/refuse-reasons/`);
    return response;
  },
  async ban(instaname, user, penalty, ban, forever, reason) {
    const params = {};
    params.reason = reason;
    let instagramId = null;
    utils
      .getAccountGraphql(instaname)
      .then((res) => {
        instagramId = res.graphql.user.id;
      })
      .finally(() => {
        if (instaname) {
          params.instaname = instaname;
        } else if (user) {
          params.user = user;
        }
        apiService.get('/api/reviews/bans/', { params }).then((res) => {
          const { results } = res;
          let data = params;
          if (results.length > 0) {
            [data] = results;
            data.instagram_id = instagramId;
            if (penalty) data.penalty_points -= 1;
            data.is_banned = ban;
            data.forever = forever;
            apiService.put(`/api/reviews/bans/${data.id}/`, data);
          } else {
            data.penalty_points = penalty ? 2 : 3;
            data.is_banned = ban;
            data.forever = forever;
            data.instagram_id = instagramId;
            apiService.post('/api/reviews/bans/', data);
          }
        });
      });
  },
  async mark(instaname) {
    apiService.post('/api/reviews/mark-doubt/', { instaname });
  },
  telegram: {
    async list(params) {
      const response = await apiService.get('/api/reviews/telegram/moder/', {
        params,
        paramsSerializer: (ps) => {
          return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
        },
      });
      return response;
    },
    async update(data) {
      const response = await apiService.patch(`/api/reviews/telegram/moder/${data.id}/`, data);
      return response;
    },
    async export(mode, cb) {
      const response = await apiService.post('/api/reviews/export/', { mode }).then(cb);
      return response;
    },
    async checkExport(idx, cb) {
      const response = await apiService.get(`/api/reviews/export/${idx}/`).then(cb);
      return response;
    },
    async getRefuseReasons() {
      const response = await apiService.get(`/api/reviews/refuse-reasons/`);
      return response;
    },
    async ban(instaname, user, penalty, ban, forever, reason) {
      const params = {};
      params.reason = reason;
      let instagramId = null;
      utils
        .getAccountGraphql(instaname)
        .then((res) => {
          instagramId = res.graphql.user.id;
        })
        .finally(() => {
          if (instaname) {
            params.instaname = instaname;
          } else if (user) {
            params.user = user;
          }
          apiService.get('/api/reviews/bans/', { params }).then((res) => {
            const { results } = res;
            let data = params;
            if (results.length > 0) {
              [data] = results;
              data.instagram_id = instagramId;
              if (penalty) data.penalty_points -= 1;
              data.is_banned = ban;
              data.forever = forever;
              apiService.put(`/api/reviews/bans/${data.id}/`, data);
            } else {
              data.penalty_points = penalty ? 2 : 3;
              data.is_banned = ban;
              data.forever = forever;
              data.instagram_id = instagramId;
              apiService.post('/api/reviews/bans/', data);
            }
          });
        });
    },
    async mark(instaname) {
      apiService.post('/api/reviews/mark-doubt/', { instaname });
    },
  },
};
