import education from '@main/api/education';
import enums from '@main/enums';

export default {
  namespaced: true,
  state: {
    headerQuestionsOptions: null,
    apiState: enums.INIT,
  },
  actions: {
    async loadHeaderQuestionsOptions(context) {
      const options = await education.questions.header.apiSchema();
      context.commit('setHeaderQuestionsOptions', options);
    },
  },
  mutations: {
    setHeaderQuestionsOptions(state, data) {
      state.headerQuestionsOptions = data;
    },
    setApiState(state, data) {
      state.apiState = data;
    },
  },
};
