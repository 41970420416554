<template>
  <div>
    <div v-if="data">
      <div>
        <shuffle-list-transition
          v-if="data"
          :elHeight="'62px'"
          :style="`display: grid; grid-template-columns: repeat(auto-fill, minmax(${
            barConfig.width || 250
          }px, 1fr)); grid-gap: 30px !important`"
        >
          <horizontal-html-bar
            v-for="item in displayableData"
            :key="item.title"
            :data="item"
            :config="barConfig"
          ></horizontal-html-bar>
        </shuffle-list-transition>
      </div>
      <div v-if="data.length > 1" class="d-sm-none">
        <slot name="fold" v-bind="{ toggleFold, unfolded }"></slot>
      </div>
    </div>
    <div v-else class="p-3">
      <loader class="mx-auto"></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '../../Loader.vue';
import ShuffleListTransition from '../transitions/ShuffleListTransition.vue';
/* eslint-disable no-underscore-dangle */
import HorizontalHtmlBar from './HorizontalHtmlBar.vue';

export default {
  props: {
    barConfig: { type: Object },
    data: { type: [Object, Array] },
    itemsCountFolded: { type: Number, default: 1 },
    fold: { type: [Boolean, String] },
  },
  methods: {
    toggleFold() {
      console.log(this.iUnfolded);
      this.iUnfolded = !this.iUnfolded;
    },
  },
  computed: {
    ...mapState(['windowWidth']),
    unfolded: {
      get() {
        return this.iUnfolded || this.windowWidth > 550;
      },
      set(val) {
        this.iUnfolded = val;
      },
    },
    displayableData() {
      if (this.unfolded) {
        return this.data;
      }
      return this.data.slice(0, this.itemsCountFolded);
    },
  },
  data: () => ({
    iUnfolded: false,
  }),
  components: { HorizontalHtmlBar, ShuffleListTransition, Loader },
};
</script>
