<template>
  <div class="file-wrapper">
    <input
      ref="input"
      type="file"
      multiple="multiple"
      :accept="$props.accept || $attrs.accept || 'image/*'"
      hidden="hidden"
      @change="handleFile"
    />
    <slot name="uploadButton" v-bind="{ handler: openInput }"></slot>
    <b-button
      variant="primary"
      :disabled="!!loading"
      v-if="!$scopedSlots.uploadButton"
      type="button"
      class="files-load__button button"
      @click="openInput"
      :style="getStyle"
    >
      {{ value ? 'Файлы загружены' : loadText || 'Загрузить скриншоты' }}
    </b-button>
    <div v-if="!$scopedSlots.uploadButton && !!!loading && !disableFileMark" class="files-status">
      <span class="files-status__title" v-if="files.length"
        ><span class="files-status__files fl-set--r"
          >{{ getFilename.slice(0, 11) }}...
          <span class="d-sm-none">({{ files.length }})</span></span
        ></span
      >
      <template v-if="!disableFileMark">
        <span class="files-status__remove no-file-chosen" v-if="!files.length && !loaded"
          >Файл не выбран</span
        >
        <span v-else class="files-status__remove no-file-chosen d-none d-sm-block">
          Выбрано файлов: {{ files.length }}
        </span>
      </template>
    </div>
    <div v-else-if="loading" class="files-status">
      <b-spinner class="ml-2" style="width: 16px; height: 16px; color: lightgray"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import literal from '@main/func/literal';

export default {
  props: ['value', 'loading', 'loaded', 'disableFileMark', 'loadText'],
  data: () => ({
    files: [],
  }),
  computed: {
    getFilename() {
      return this.files[this.files.length - 1].name;
    },
    getStyle() {
      if (!this.loading) return;
      return `background: linear-gradient(to right,
                                       rgb(56, 151, 240) ${this.loading}%,
                                       grey ${this.loading}% ${100 - this.loading}%) !important`;
    },
  },
  methods: {
    openInput() {
      this.$refs.input.click();
    },
    handleFile(e) {
      if (!e.target.files) {
        this.showMessage({ title: 'Файлы не загружены! Загрузите файлы' });
      }
      this.files.push(...e.target.files);
      this.$emit('input', e.target.files);
    },
  },
};
</script>

<style scoped>
.file-wrapper {
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.files-load__button {
  width: 168px !important;
  min-width: 168px !important;
  line-height: 16px;
  height: 36px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #3897f0;
  border: none;
  padding: 10px;
  outline: none;
  cursor: pointer;
  white-space: nowrap !important;
  color: #fff;
}

.files-status {
  position: absolute;
  right: 10px;
}

.files-status__title {
  display: block;
  height: 18px !important;
}

.files-status__remove {
  color: #b4bdc1;
  text-align: left;
}
</style>
