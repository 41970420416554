<template>
  <div :class="[wrapper, popupMode ? 'popup-mode' : '']">
    <div v-if="apiState === 3" :class="containerClass">
      <SignIn v-if="showSignIn" />
      <SettingsChangeModal v-if="changeBotSettingsModal" />
      <ChangePasswordModal v-if="changePasswordModal.open" />
      <EnergyPaymentModal v-if="showEnergyPriceModal" />
      <StatisticCardModal v-if="statisticCardModal.open" />
      <ActivateEmailNotification v-if="emailActivationModal.open" />
      <ReviewsModal v-if="reviewsModal.open" />
      <ChangePhoneModal v-if="changePhoneModal.open" />
      <InstagramVerificationModal v-if="instagramVerificationModal.open" />
      <MainSubscriptionPaymentModal />
      <Header v-if="!popupMode" />
      <PopupContainer />
      <SideNotificationContainer />
      <NotificationWindow v-if="windows.length" />
      <MessagePolling />
      <div @click="$bus.$emit('body-clicked')" classid="app" :class="`container--height ${layout}`">
        <router-view v-if="apiState === 3" />
      </div>
    </div>
    <div v-else style="height: 100vh"><Loader /></div>
    <Footer v-if="!popupMode" />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */

import { setNextPage, getAuthHeaders, setAxiosHeaders } from '@main/util';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VueAdBlockDetect from 'vue-adblock-detect';
import ENUM from '@main/enums.js';
import Header from '@main/components/Header.vue';
import Footer from '@main/components/Footer.vue';
import SignIn from '@main/components/SignIn.vue';
import StatisticCardModal from '@main/components/reusable/modals/StatisticCardModal.vue';
import ChangePasswordModal from '@main/components/reusable/modals/ChangePasswordModal.vue';
import PopupContainer from '@main/components/notifications/PopupContainer.vue';
import NotificationWindow from '@main/components/notifications/NotificationWindow.vue';
import SideNotificationContainer from '@main/components/notifications/SideNotificationContainer.vue';
import SettingsChangeModal from '@main/components/reusable/modals/SettingsChangeModal.vue';
import MainSubscriptionPaymentModal from '@main/components/reusable/modals/MainSubscriptionPaymentModal.vue';
import InstagramVerificationModal from '@main/components/reusable/modals/InstagramVerificationModal.vue';
import Loader from '@main/components/Loader.vue';
import apiService from '@main/api/index';
import auth from '@main/api/users';
import literal from '@main/func/literal';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import EnergyPaymentModal from './components/reusable/modals/EnergyPaymentModal.vue';
import ActivateEmailNotification from './components/reusable/modals/ActivateEmailNotification.vue';
import ChangePhoneModal from './components/reusable/modals/ChangePhoneModal.vue';
import ReviewsModal from './components/reusable/modals/ReviewsModal.vue';
import MessagePolling from './components/notifications/MessagePolling.vue';

export default {
  name: 'App',
  components: {
    Header,
    SignIn,
    Footer,
    PopupContainer,
    Loader,
    NotificationWindow,
    SideNotificationContainer,
    ChangePasswordModal,
    EnergyPaymentModal,
    SettingsChangeModal,
    StatisticCardModal,
    MainSubscriptionPaymentModal,
    InstagramVerificationModal,
    ActivateEmailNotification,
    ChangePhoneModal,
    ReviewsModal,
    MessagePolling,
  },
  mixins: [VueAdBlockDetect],
  computed: {
    ...mapState('payments', ['showEnergyPriceModal']),
    ...mapState(['currentUser', 'apiState', 'authAction', 'showSignIn']),
    ...mapState('notifications', ['windows']),
    ...mapGetters(['hasTokens']),
    ...mapState('popups', [
      'changePasswordModal',
      'changeBotSettingsModal',
      'statisticCardModal',
      'instagramVerificationModal',
      'emailActivationModal',
      'changePhoneModal',
      'reviewsModal',
    ]),
    layout() {
      if (this.$route.meta.layout !== undefined) {
        return this.$route.meta.layout;
      }
      return 'container--other';
    },
    wrapper() {
      if (this.$route.meta.wrapper !== undefined) {
        return this.$route.meta.wrapper;
      }
      return '';
    },
    containerClass() {
      if (this.$route.meta.containerClass !== undefined) {
        return this.$route.meta.containerClass;
      }
      return '';
    },
    popupMode() {
      return this.$route.query.popup === '1';
    },
  },
  methods: {
    ...mapActions([
      'loadUser',
      'loadFooterLinks',
      'loadTags',
      'loadCountries',
      'loadBloggerOptions',
      'loadReviewsOptions',
      'loadTelegramReviewsOptions',
      'loadUserCart',
    ]),
    ...mapActions('reviewsModeration', ['loadRefuseReasons']),
    ...mapActions('notifications', ['setWindow', 'loadNotifications']),
    ...mapMutations(['setApiState', 'setBackMode', 'setWindowWidth', 'toggleSignIn']),
    ...mapMutations('notifications', ['showMessage', 'addSideNotification', 'popSideNotification']),
    ...mapMutations('popups', ['toggleChangePasswordModal', 'toggleEmailActivationModal']),
    async initApp() {
      this.setApiState(ENUM.LOADING);
      try {
        const loadData = [
          this.loadBloggerOptions(),
          this.loadTelegramReviewsOptions(),
          this.loadReviewsOptions(),
          this.loadFooterLinks(),
          this.loadTags(),
          this.loadCountries(),
          this.loadRefuseReasons(),
          this.loadUserCart(),
          this.loadUser(),
        ];
        if (this.hasTokens) {
          loadData.push(this.loadUser());
        }
        await Promise.all(loadData);
      } catch (e) {
        if (e.response && e.response.status >= 500) {
          this.setApiState(ENUM.ERROR);
          this.$router.push({ name: '500' });
        }
      }
      this.setApiState(ENUM.LOADED);
    },
    initVuexQuery() {
      const { vq } = this.$route.query;
      if (vq) {
        const data = JSON.parse(vq);
        this.$store[data.type](
          // eslint-disable-next-line prefer-template
          `${data.location ? data.location + '/' : ''}${data.name}`,
          data.value
        );
        if (data.next) {
          this.$router.push({ query: { next: data.next, vq: '' } });
        }
      }
    },
    initAccountConfirmation() {
      if (
        this.currentUser &&
        !this.currentUser.profile.email &&
        !this.currentUser.profile.phone &&
        this.$route.path !== '/confirm_email'
      ) {
        console.log(this.$route.path);
        this.toggleEmailActivationModal({
          open: true,
          context: { register: true, disclose: true, noEmailText: 'Изменить почту' },
        });
      }
    },
    initWarnings() {
      if (localStorage.getItem('cookiePolicy') === 'disagreed') {
        // eslint-disable-next-line no-restricted-properties
        if (!document.__defineGetter__) {
          Object.defineProperty(document, 'cookie', {
            get() {
              return '';
            },
            set() {
              return true;
            },
          });
        } else {
          // eslint-disable-next-line no-restricted-properties
          document.__defineGetter__('cookie', function () {
            return '';
          });
          // eslint-disable-next-line no-restricted-properties
          document.__defineSetter__('cookie', function () {});
        }
        setInterval(() => {
          // eslint-disable-next-line func-names
          console.log(document.cookie);
          document.cookie.split(';').forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, '')
              // eslint-disable-next-line prefer-template
              .replace(
                /=.*/,
                // eslint-disable-next-line prefer-template
                '=;expires=' + new Date().toUTCString() + ';path=/'
              );
          });
        }, 100);
      }
      if (
        this.currentUser &&
        this.currentUser.blogger_profile &&
        !this.currentUser.blogger_profile.filled
      ) {
        this.showMessage({
          title: `<a class="" href="/#/fillstat/">Заполните до конца вашу карточку блогера на сайте</a>`,
          icon: 2,
        });
      }
      if (!localStorage.getItem('cookiePolicy')) {
        if (
          !this.currentUser ||
          !(this.currentUser && this.currentUser.profile.cookie_policy_agree)
        ) {
          this.addSideNotification({
            message: `Мы используем куки, чтобы вы могли полноценно пользоваться сайтом. <a style='color: white; white-space: break-spaces; text-decoration: underline' href='/cookie_policy' target='_blank'>Политика в отношении файлов cookie</a>`,
            buttons: [
              {
                text: 'Принимаю',
                attrs: { variant: 'yellow' },
                handler(button, idx) {
                  localStorage.setItem('cookiePolicy', 'agreed');
                },
              },
              {
                text: 'Нет',
                attrs: { variant: 'outline-default' },

                handler: async (button, idx) => {
                  this.setWindow({
                    text: 'Вы точно хотите отключить cookie? При отключении cookie сайт может работать некорректно.',
                    iconPath: alertGreyIcon,
                    buttons: [
                      {
                        text: 'Я передумал(-а)',
                        attrs: { variant: 'warning' },
                        handler: async () => {
                          this.setWindow(null);
                        },
                      },
                      {
                        text: 'Не нужно отключать',
                        attrs: { variant: 'warning' },
                        handler: async () => {
                          this.setWindow(null);
                        },
                      },
                      {
                        text: 'Да, точно отключить.',
                        attrs: { variant: 'danger' },
                        handler: async () => {
                          setInterval(() => {
                            // eslint-disable-next-line func-names
                            document.cookie.split(';').forEach(function (c) {
                              document.cookie = c
                                .replace(/^ +/, '')
                                // eslint-disable-next-line prefer-template
                                .replace(
                                  /=.*/,
                                  // eslint-disable-next-line prefer-template
                                  '=;expires=' + new Date().toUTCString() + ';path=/'
                                );
                            });
                          }, 100);

                          localStorage.setItem('cookiePolicy', 'disagreed');
                          this.popSideNotification();
                          this.setWindow(null);
                        },
                      },
                    ],
                  });
                },
              },
            ],
          });
        }
      }
      this.detectAdBlock().then((response) => {
        if (response) {
          this.addSideNotification({
            message:
              'Обнаружен блокировщик рекламы! Отключите блокировку для корректной работы сайта',
            buttons: [
              {
                text: 'Хорошо',
                attrs: { variant: 'yellow' },
                handler: (button, idx) => {
                  this.popSideNotification();
                },
              },
            ],
          });
        }
      });
    },
    initQueryParamsCredentials() {
      const { password } = this.$route.query || null;
      const { login } = this.$route.query || null;
      if (password || login) {
        this.toggleSignIn({ data: { loginString: login, password } });
        this.$router.replace({ query: { login: null, password: null } });
      }
    },
    async loginInit() {
      const headers = getAuthHeaders();
      setAxiosHeaders(headers, apiService);
      await this.initApp();
    },
    initListeners() {
      this.$bus.$on('modal-open', (val) => {
        if (val) {
          document.body.classList.add('blocked');
          return;
        }
        this.setBackMode(false);
        document.body.classList.remove('blocked');
      });
    },
  },
  created() {
    this.setApiState(ENUM.INIT);
    window.addEventListener('resize', () => {
      this.setWindowWidth(window.screen.width);
    });
  },
  mounted() {
    if (window.location.pathname.startsWith('/render')) {
      console.log('render path detected');
      return;
    }

    this.initListeners();
    this.initApp().then(() => {
      this.initWarnings();
      this.initAccountConfirmation();
      setTimeout(() => {
        this.initVuexQuery();
        this.initQueryParamsCredentials();
      }, 2000);
    });
  },
  provide() {
    return { loginInit: this.loginInit };
  },
};
</script>

<style lang="scss">
.main_-_window_-_ {
  min-height: 100vh;
}
</style>
