import apiService from './index';

export default {
  orders: {
    refuseReasons: {
      async list(params) {
        const response = await apiService.get('/api/promobot/data/orders-refuse-reasons/', {
          params,
        });
        return response;
      },
    },
    async list(params) {
      const response = await apiService.get('/api/promobot/orders/', { params });
      return response;
    },
    async fnc(data, params) {
      const response = await apiService.post('/api/promobot/orders/fnc/', data, { params });
      return response;
    },
    async partial_update(data, params) {
      const response = await apiService.put(`/api/promobot/orders/${data.id}/`, data, { params });
      return response;
    },
    async create(data) {
      const response = await apiService.post(`/api/promobot/orders`, data);
      return response;
    },
    async generateCode(pk) {
      const response = await apiService.post(
        `/api/promobot/orders/${pk}/generate_code/`,
        {},
        { params: { for_user: true } }
      );
      return response;
    },
    async sendBell(pk) {
      const response = await apiService.post(
        `/api/promobot/orders/${pk}/send_bell/`,
        {},
        { params: { for_user: true } }
      );
      return response;
    },
    // eslint-disable-next-line camelcase
    async setFormat(pk, format_id) {
      const response = await apiService.post(
        `/api/promobot/orders/${pk}/set_format/`,
        { format_id },
        { params: { for_user: true } }
      );
      return response;
    },
    async hiddenCount() {
      const response = await apiService.get('/api/promobot/orders/hidden_count/');
      return response;
    },
  },
};
