import axios from 'axios';
import * as rax from 'retry-axios';
import { getAuthHeaders } from '@main/util';

const headers = getAuthHeaders();
const apiService = axios.create({
  timeout: 25000,
  headers,
});

apiService.interceptors.response.use(
  (response) => {
    if (!response || typeof response === 'undefined') {
      return { success: false };
    }
    if ('data' in response) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      return;
    }
    if (error.response.status === 401) {
      if (error.response.data.code === 'token_not_valid') {
        console.log('TOKEN ERROR - RELOADING');
        window.$cookies.remove('jwt_access');
        localStorage.removeItem('token');
        window.location.reload();
      }
      throw error;
    }
    throw error;
  }
);

rax.attach(apiService);
export default {
  apiService,
  tests: {
    async list(params) {
      const response = await apiService.get(`/api/education/tests/`, { params });
      return response;
    },
    async get(id) {
      const response = await apiService.get(`/api/education/tests/${id}/`);
      return response;
    },
    async check(id, answers) {
      const response = await apiService.post(`/api/education/tests/${id}/check/`, { answers });
      return response;
    },
  },
  questions: {
    header: {
      async apiSchema() {
        const response = await apiService.get(`/api/education/header_questions/api_schema/`);
        return response;
      },
    },
  },
  v2: {
    tests: {
      async list(params) {
        const response = await apiService.get(`/api/education/v2/tests/`, { params });
        return response;
      },
      async get(name) {
        const response = await apiService.get(`/api/education/v2/tests/${name}/`);
        return response;
      },
      async check(data) {
        const response = await apiService.post(`/api/education/v2/tests/answer/`, data);
        return response;
      },
      async generate(params) {
        const response = await apiService.get(`/api/education/v2/tests/generate/`, { params });
        return response;
      },
      answers: {
        async get(id) {
          const response = await apiService.get(`/api/education/v2/answers/${id}/`);
          return response;
        },
      },
      meta: {
        async list(params) {
          const response = await apiService.get(`/api/education/v2/tests/meta/`, { params });
          return response;
        },
        async userList(params) {
          const response = await apiService.get(`/api/education/v2/tests/usermeta/`, { params });
          return response;
        },
        async create(data) {
          const response = await apiService.post(`/api/education/v2/tests/meta/create/`, data);
          return response;
        },
        async update(data) {
          const response = await apiService.post(`/api/education/v2/tests/meta/update/`, data);
          return response;
        },
        async delete(data) {
          const response = await apiService.post(`/api/education/v2/tests/meta/delete/`, data);
          return response;
        },
      },
      courses: {
        async list(params) {
          const response = await apiService.get(`/api/education/v2/courses/`, { params });
          return response;
        },
        async create(data) {
          const response = await apiService.post(`/api/education/v2/courses/`, data);
          return response;
        },
        async update(data) {
          const response = await apiService.patch(`/api/education/v2/courses/`, data);
          return response;
        },
        async delete(data) {
          console.log('course delete', data);
          const response = await apiService.delete(`/api/education/v2/courses/${data.name}/`);
          return response;
        },
      },
    },
    questions: {
      async list(params) {
        const response = await apiService.get(`/api/education/v2/questions/`, { params });
        return response;
      },
      upload: {
        async csv(data) {
          const formData = new FormData();
          Object.keys(data).forEach((key) => formData.append(key, data[key]));
          const response = await apiService.post(
            `/api/education/v2/questions/upload/csv/`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          return response;
        },
      },
    },
    models: {
      schema: {
        async get(model) {
          const response = await apiService.get(`/api/education/v2/model/schema/${model}/`);
          return response;
        },
      },
    },
  },
  auth: {
    apiKey: {
      async get(name) {
        const response = await apiService.get(`/api/education/v2/api_keys/generate/`, {
          params: { name },
        });
        return response;
      },
      async list() {
        const response = await apiService.get(`/api/education/v2/api_keys/`);
        return response;
      },
      async revoke(key) {
        const response = await apiService.post(`/api/education/v2/api_keys/revoke/`, { key });
        return response;
      },
    },
  },
};
