// eslint-disable-next-line camelcase
export async function replaceNextPage(router, route, next_page) {
  await router.push({ query: { next_page }, path: route.query.path });
}

// eslint-disable-next-line camelcase
export async function setNextPage(router, route, next_page, { prepend } = {}) {
  const query = route.query.next_page;
  if (query) {
    const queryArray = [query];
    if (!prepend) {
      queryArray.push(next_page);
    } else {
      queryArray.splice(0, 0, next_page);
    }
    await replaceNextPage(router, route, queryArray.join(','));
  } else {
    await replaceNextPage(router, route, next_page);
  }
}

export async function getNextPage(router, route) {
  let query = route.query.next_page;
  if (query) {
    let queryArray;
    if (query.indexOf(',') !== -1) {
      queryArray = query.split(',');
      // eslint-disable-next-line prefer-destructuring
      query = queryArray[0];
    } else {
      delete route.query.next_page;
    }
    const nextQuery = queryArray ? queryArray.slice(1).join(',') : null;
    const nextQueryObject = { query: nextQuery ? { next_page: nextQuery } : {} };
    if (query.startsWith('redirect:')) {
      window.location.href = query.replace('redirect:', '');
    } else if (query.startsWith('routename:')) {
      await router.push({
        name: query.replace('routename:', ''),
        ...nextQueryObject,
      });
    } else {
      await router.push({
        path: query,
        ...nextQueryObject,
      });
    }
    return true;
  }
}

export function getAuthHeaders() {
  const cookieToken = window.$cookies.get('jwt_access');
  const storageToken = localStorage.getItem('token');
  const csrfToken = window.$cookies.get('csrftoken');

  const headers = {
    contentType: 'application/json',
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',
    withCredentials: true,
  };

  if (cookieToken) {
    headers.Authorization = `Bearer ${cookieToken}`;
  } else if (storageToken) {
    headers.Authorization = `Bearer ${storageToken}`;
  }
  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }
  return headers;
}

export async function setAxiosHeaders(headers, instance) {
  const keys = Object.keys(headers);
  keys.forEach((key) => {
    // instance.defaults.headers.common[key] = headers[key];
    instance.defaults.headers[key] = headers[key];
  });
}
