<template>
  <MultiStepModal
    :steps="steps"
    :currentStep="currentStep"
    wrapperTypeClass="change-phone-popup"
    :title="(changePhoneModal.context && changePhoneModal.context.title) || 'Изменение телефона'"
    :open="changePhoneModal.open"
    :upperHtml="changePhoneModal.context ? changePhoneModal.context.upperHtml : null"
    :warningComponent="changePhoneModal.context ? changePhoneModal.context.warningComponent : null"
    @close="
      () => {
        toggleChangePhoneModal({ open: false, context: {} });
      }
    "
  >
    <template v-slot:1>
      <form @submit.prevent="forward" class="needs-validation" novalidate>
        <div class="sms-top">Введите пароль</div>
        <div class="form-group">
          <input
            class="form-control"
            id="num3"
            type="password"
            v-model="password"
            invalid
            placeholder=""
            required
          />
          <div :style="{ display: passwordError ? 'block' : 'none' }" class="error-form">
            Пароль неверный. Попробуйте снова
          </div>
        </div>
      </form>
    </template>
    <template v-slot:2>
      <form @submit.prevent="forward" class="needs-validation" novalidate>
        <div class="sms-top">
          Введите ваш номер телефона. <br />
          Мы отправим на него код
        </div>
        <div class="form-group">
          <b-form-input
            :class="{ error: $v.phone.$anyError }"
            class="form-control phone"
            v-model="$v.phone.$model"
          />
          <div v-if="$v.phone.$anyError && !$v.phone.validPhone" class="code-link__caption open">
            <div>Номер телефона не может содержать букв</div>
          </div>
          <div
            v-if="$v.phone.$anyError && !$v.phone.required"
            style="font-size: 12px; margin-top: 5px"
            class="red"
          >
            Введите номер телефона
          </div>
          <div v-if="codeError" style="font-size: 12px; margin-top: 5px" class="red">
            {{ codeError }}
          </div>
        </div>
      </form>
    </template>
    <template v-slot:3>
      <form @submit.prevent="forward" class="needs-validation" novalidate>
        <div class="sms-top">Код из SMS</div>
        <div class="form-group">
          <div class="pass-label">
            <label for="code"
              >Мы отправили вам код подтверждения на номер <span v-phone-number="phone"></span>
            </label>
          </div>
          <input
            type="text"
            class="form-control"
            id="code1"
            v-model="code"
            placeholder="Код"
            required
          />
          <div v-if="codeError" style="font-size: 12px; margin-top: 5px" class="red">
            {{ codeError }}
          </div>
          <a @click="resendCode" :class="['code-link', tryTime > 0 ? 'code-link-open' : '']">
            Отправить код повторно
          </a>
          <div v-if="!!tries" class="code-link__caption">
            <div>
              Попытка №{{ tries }} <br />
              Запросить код повторно можно через {{ tryTime }} секунд
            </div>
            <a>
              <span class="info left bottom">
                Не приходит SMS
                <div class="info__txt">
                  Возможные причины: <br /><br />
                  1. Вы не изменили номер мобильного телефона на сайте на новый и SMS было
                  отправлено на старый номер.<br /><br />
                  2. Проблемы могут быть со стороны сотового оператора. Обратитесь в их службу
                  поддержки.
                </div>
              </span>
            </a>
          </div>
          <sms-code-status-block :taskId="codeId" v-if="codeId"></sms-code-status-block>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="login__bottom">
        <b-button
          @click="
            currentStep !== 1 ? goLeft() : toggleChangePhoneModal({ open: false, context: {} })
          "
          variant="outline-default"
          >Назад</b-button
        >
        <b-button @click="forward" variant="primary" class="next-tap">{{
          currentStep === 3 ? 'Сохранить' : 'Далее'
        }}</b-button>
      </div>
    </template>
  </MultiStepModal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import auth from '@main/api/users';
import MultiStepModal from '@main/components/reusable/modals/MultiStepModal.vue';
import MultiStepMixin from '@main/mixins/MultiStepMixin.vue';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import { required } from 'vuelidate/lib/validators';
import SmsCodeStatusBlock from '../elements/complex/SmsCodeStatusBlock.vue';

export default {
  components: {
    MultiStepModal,
    SmsCodeStatusBlock,
  },
  mixins: [MultiStepMixin],
  props: {
    open: {
      type: Boolean,
    },
  },
  data: () => ({
    password: '',
    passwordError: false,
    phoneValid: true,
    phone: '',
    code: '',
    codeId: null,
    codeError: '',
    tries: 0,
    tryTime: 0,
    steps: 3,
    tryInterval: null,
  }),
  validations: {
    phone: {
      required,
      validPhone: (val) => {
        return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g.test(val);
      },
    },
  },
  watch: {
    phone(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapState('popups', ['changePhoneModal']),
    requestData() {
      return {
        type: 'phone',
        value: this.phone,
        code: this.code,
      };
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('popups', ['toggleChangePhoneModal']),
    ...mapMutations('notifications', ['showMessage', 'setWindow']),
    async passwordCheck() {
      const response = await auth.checkPassword(this.password);
      if (response.success) {
        this.goRight();
        return;
      }
      this.passwordError = true;
    },
    async sendCode(disableMovement) {
      this.codeError = '';
      try {
        const resp = await auth.sendCode(this.requestData);
        this.codeId = resp.task_id;
        if (!resp.success) {
          this.codeError = resp.error;
          return;
        }
        if (!disableMovement) this.goRight();
      } catch (e) {
        this.codeError = e.response.error;
      }
    },
    async resendCode() {
      if (!this.tryTime) {
        this.tries += 1;
        await this.sendCode(true);
        this.tryTime = 60;
        this.setTryInterval();
      }
    },
    async checkCode() {
      try {
        const resp = await auth.checkCode(this.requestData);
        if (resp.success) {
          try {
            const phoneres = await auth.changePhone(this.requestData);
            if (phoneres.success) {
              this.loadUser();
              this.showMessage({
                id: 500,
                type: 'basic',
                title: 'Номер телефона успешно изменен',
                icon: 1,
              });
              if (this.changePhoneModal.callback) {
                try {
                  this.changePhoneModal.callback();
                } catch (e) {
                  console.log(e);
                }
              }
              this.toggleChangePhoneModal({ open: false });
              return;
            }
            this.setWindow({
              text: 'Ошибка при изменении телефона, попробуйте позднее и/или обратитесь к администратору!',
              iconPath: alertGreyIcon,
            });
          } catch (e) {
            if (e.response.status === 403) {
              this.codeError =
                'Аккаунт с указанным номером уже есть в системе. Введите номер телефона, который не зарегистрирован на сервисе или воспользуйтесь восстановлением пароля для текущего номера.';
            }
            this.setWindow({
              text: 'Ошибка при изменении телефона, попробуйте позднее и/или обратитесь к администратору!',
              iconPath: alertGreyIcon,
            });
          }
          this.$emit('close');
          return;
        }
        this.codeError = 'Неверный код, попробуйте еще раз!';
      } catch (e) {
        this.codeError = 'Неверный код, попробуйте еще раз!';
      }
    },
    setTryInterval() {
      this.tryTime = 60;
      this.tryInterval = setInterval(() => {
        if (this.tryTime <= 0) {
          this.tryTime = 0;
          clearInterval(this.tryInterval);
        } else {
          this.tryTime -= 1;
        }
      }, 1000);
    },
    async forward() {
      switch (this.currentStep) {
        case 1:
          this.passwordCheck();
          break;
        case 2:
          if (this.$v.phone.$anyError) {
            break;
          }
          await this.sendCode();
          break;
        case 3:
          await this.checkCode();
          break;
        default:
      }
    },
  },
  mounted() {
    if (this.changePhoneModal.context.step) {
      this.currentStep = this.changePhoneModal.context.step;
    }
  },
};
</script>

<style lang="css" scoped></style>
