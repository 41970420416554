/* eslint-disable camelcase */
import apiService from './index';

export default {
  helperItemChains: {
    async get(system_name) {
      const result = await apiService.get('/api/ui_helpers/helper_item_chains/', { system_name });
      return result;
    },
  },
};
