<template>
  <div class="d-flex flex-column side-notification-container">
    <SideNotification
      :key="`${notification.message}${notification.id}`"
      v-for="notification in sideNotifications"
      v-bind="notification"
      @read="popSideNotification"
    ></SideNotification>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SideNotification from './SideNotification.vue';

export default {
  computed: {
    ...mapState('notifications', ['sideNotifications']),
  },
  methods: {
    ...mapMutations('notifications', ['popSideNotification']),
  },
  components: {
    SideNotification,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 550px) {
  .side-notification-container {
    bottom: 10px !important;
    z-index: 4 !important;
    width: calc(100% - 20px) !important;
    display: block !important;
    right: 0px !important;
    margin: 0px 10px;
    max-width: none !important;
  }
}
.side-notification-container {
  right: 20px;
  bottom: 20px;
  position: fixed !important;
  z-index: 4;
  max-width: 600px;
}
</style>
