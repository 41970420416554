import enums from '@main/enums';
import permissions from '@main/permissions';
import store from '../store/index';
import { router } from '../router';

/* eslint-disable func-names, camelcase, max-classes-per-file */

class BasePipelineItem {
  constructor(next) {
    this.next = next;
  }

  setNext(next) {
    this.next = next;
    return next;
  }

  handle(func, request) {
    console.log(this.next);
    if (this.next) {
      return this.next.handle(func, request);
    }
    if (request) {
      return func(request.context);
    }
    return func();
  }
}

export class RequiresIdentification extends BasePipelineItem {
  handle(func, request) {
    if (store.state.currentUser.profile.phone || store.state.currentUser.profile.email) {
      return super.handle(func, request);
    }
    if (request && !request.callback) {
      request.callback = func;
    }
    if (!store.state.currentUser.profile.phone) {
      store.commit('popups/toggleChangePhoneModal', {
        open: true,
        context: { step: 2 },
        callback: request ? request.callback : func,
      });
    } else if (!store.state.currentUser.profile.email) {
      store.commit('popups/toggleEmailActivationModal', {
        open: true,
        callback: request ? request.callback : func,
      });
    }
  }
}

export class RequiresPhoneNumber extends BasePipelineItem {
  // eslint-disable-next-line class-methods-use-this
  handle(func, request) {
    if (!store.state.currentUser.profile.phone) {
      if (request && !request.callback) {
        request.callback = func;
      }
      store.commit('popups/toggleChangePhoneModal', {
        open: true,
        context: { step: 2, ...(request.context || {}) },
        callback: request ? request.callback : func,
      });
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresAuth extends BasePipelineItem {
  handle(func, request) {
    if (request && !request.callback) {
      request.callback = func;
    }
    if (!permissions.isAuthorised()) {
      store.commit('toggleSignIn', request || { callback: func });
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresStaff extends BasePipelineItem {
  handle(func, request) {
    if (!permissions.isStaff()) {
      store.commit('notifications/showMessage', {
        title: 'У вас недостаточно прав для перехода на эту страницу',
        icon: 'alert-yellow',
      });
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresSub extends BasePipelineItem {
  handle(func, request) {
    if (!store.state.currentUser.services_subscriber) {
      window.location.href = 'https://sub.easyprbot.com';
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresEnergy extends BasePipelineItem {
  handle(func, request) {
    if (store.state.currentUser.profile.energy < request.amount) {
      store.commit('payments/toggleEnergyPriceModal');
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresBloggerProfile extends BasePipelineItem {
  handle(func, request) {
    if (
      !store.state.currentUser.blogger_profile ||
      !store.state.currentUser.blogger_profile.filled
    ) {
      router.push({
        name: 'fill_statistics',
        query: { next_page: request ? request.next_page : '/mystat' },
      });
      store.commit('notifications/showMessage', {
        title: store.state.currentUser.blogger_profile
          ? 'Необходимо заполнить карточку блогера до конца!'
          : 'Необходимо заполнить карточку блогера!',
        icon: 2,
      });
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresAuthenticatedInstagram extends BasePipelineItem {
  handle(func, request) {
    if (!store.state.currentUser.blogger_profile) {
      store.commit('popups/toggleInstagramVerificationModal', { open: true });
      return;
    }
    return super.handle(func, request);
  }
}

export class RequiresBloggerProfileModerated extends BasePipelineItem {
  handle(func, request) {
    const status = Number(store.state.currentUser.blogger_profile.moderation_status);
    if (!enums.bloggers.moderation_statuses.groups.safe.includes(status)) {
      store.commit('notifications/showMessage', {
        title: 'Ваша карточка еще на модерации - попробуйте позже!',
        icon: 2,
      });
      return;
    }
    if (enums.bloggers.moderation_statuses.groups.unsafe.includes(status)) {
      router.push({ name: 'fill_statistics' });
      store.commit('notifications/showMessage', {
        title: 'Ваша карточка отклонена - исправьте ошибки и отправьте заново!',
        icon: 2,
      });
      return;
    }
    return super.handle(func, request);
  }
}

export class OnCondition extends BasePipelineItem {
  handle(func, request) {
    if (request && !request.condition(request.to, request.from, request.next)) {
      return;
    }
    return super.handle(func, request);
  }
}

export class ReloadUser extends BasePipelineItem {
  handle(func, request) {
    store.dispatch('loadUser');
    return super.handle(func, request);
  }
}

export function pipelineFactory(classes, idx) {
  const instances = classes.map((Cls) => {
    // eslint-disable-next-line no-new
    return new Cls();
  });
  instances.reduce((prev, next) => {
    return prev.setNext(next);
  });
  return instances[0];
}
