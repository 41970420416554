<template>
  <div
    class="review col col-sm-12 col-md-6 col-mlg-6 col-lg-4"
    :class="{
      'review-yellow': !!review.referencedBy,
      'mobile-review-danger': review._rowVariant === 'danger' && !disableVariants,
    }"
  >
    <DefaultMobileTable class="review-table p-0 pt-0" :item="review" :fields="shownFields">
      <template #cell(date)-custom="data">
        <div class="review-top-info mb-2">
          {{ data.item.date ? `${data.item.date},` : '' }} № {{ data.item.id }}
        </div>
      </template>
      <template #cell(like)-custom="data">
        <template v-if="!loading[`review-like-${data.item.id}`]">
          <div
            v-if="!data.item.referencedBy && filterParams.mode === 'all'"
            @click="() => $emit('like', review)"
            style="top: 35px; right: 15px; z-index: 2"
            class="position-absolute d-flex align-items-center"
          >
            <p class="gray-text mr-1 fs-12">{{ data.item.liked }}</p>
            <img
              style="width: 18px; height: 18px"
              v-if="!data.item.liked_by_viewer"
              class="ml-1"
              src="@main/assets/img/svg/like-gray-outline.svg"
              alt=""
            />
            <img
              style="width: 18px; height: 18px"
              class="ml-1"
              v-else
              src="@main/assets/img/svg/like.svg"
              alt=""
            />
          </div>
        </template>
        <b-spinner
          class="mx-2 position-absolute"
          style="width: 18px; height: 18px; top: 35px; right: 10px; z-index: 2"
          variant="secondary"
          v-else-if="loading[`review-like-${data.item.id}`]"
        ></b-spinner>
      </template>
      <template #cell(rate)="data">
        {{ data.value }} из 10 <b-icon-star-fill style="color: #fbc400" />
      </template>
      <template #cell(advertiser)="data">
        <a class="link" v-clipboard:copy="data.value" target="_blank">{{ data.value }}</a>
        <div
          @click="$router.push({ name: 'pr-agency-apply' })"
          v-if="data.item.advertiser_blogger && data.item.advertiser_blogger.pr_agency_member"
          class="fs-12 mb-3 p-1"
          style="
            background-color: #fadb67;
            border-radius: 5px;
            line-height: 15px;
            width: fit-content;
          "
        >
          В агентстве easyprbot
        </div>
      </template>
      <template #cell(customer)="data">
        <a class="link" v-clipboard:copy="data.value" target="_blank">{{ data.value }}</a>
      </template>
      <template #cell(arrival)="data">
        <div
          style="white-space: nowrap !important; text-overflow: ellipsis"
          class="d-flex flex-row align-items-center"
        >
          {{ data.value }}
          <span
            style="font-size: 12px; height: 16px; text-overflow: ellipsis; overflow: hidden"
            class="ml-1 gray-text ws-nowrap"
          >
            {{ declSubscribers(Number(data.value)) }}</span
          >
        </div>
      </template>
      <template #cell(refuse_reasons)-custom="data">
        <th class="d-inline-block">Причина:</th>
        {{ data.value }}
      </template>
      <template #cell(edit_button)-custom>
        <b-button
          class="w-100 mb-3 mt-2 manip__btn-rev"
          @click="editReview"
          variant="outline-default"
          >{{ 'Редактировать и отправить заново' }}</b-button
        >
      </template>
      <template #cell(text_display)-custom="data">
        <div class="mt-2 mb-1">
          <th class="mb-1">{{ data.field.label }}</th>
          <p>{{ data.value }}</p>
        </div>
      </template>
      <template #cell(delete_request)="data">
        <b-button
          class="ws-nowrap rev-more__mobile h-100"
          v-if="!data.item.deletion_status"
          @click="$emit('delete-request', data.item)"
          variant="outline-default"
          >Запросить
        </b-button>
        <p v-else>{{ data.item.deletion_status }}</p>
      </template>

      <template #cell(moderation_status)="data">
        <div>
          <div v-if="typeof data.value === 'object'">
            <span class="mr-2" v-html="data.value.html"></span>
            <b-button
              v-if="data.value.button"
              @click="() => data.value.button.handler()"
              variant="link"
              >{{ data.value.button.text }}</b-button
            >
          </div>
          <div v-else v-html="data.value"></div>
        </div>
      </template>

      <template #cell(open_review_standalone)-custom="data">
        <b-button
          v-if="
            !['me', 'blogger'].includes(filterParams.mode) &&
            data.item.reviews_count_advertiser > 1 &&
            !disclosed &&
            !data.item.referencedBy
          "
          variant="link"
          class="link d-flex justify-content-between w-75 py-1 mt-2"
          :class="{ rotate: isDetail }"
          @click="isDetail = !isDetail"
        >
          {{ isDetail ? 'Свернуть отзыв' : 'Раскрыть отзыв полностью' }}
        </b-button>
      </template>
      <template #cell(footer_block)-custom="data">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <b-button
            :disabled="moreLoading && moreLoading.includes(data.item.id)"
            v-if="
              !data.item.referencedBy &&
              data.item.reviews_count_advertiser > 1 &&
              !['me', 'blogger'].includes(filterParams.mode)
            "
            class="py-2 rev-more__mobile mt-3 mr-auto"
            variant="outline-default"
            style="max-width: 110px !important"
          >
            <div
              class="rev-more__mobile__text ws-nowrap overflow-hidden"
              style="font-size: 14px; line-height: 16.5px; text-overflow: ellipsis"
              @click="$emit('show-more', data.item.id)"
              v-if="
                !data.item.referencedBy &&
                !data.item.showMore &&
                data.item.reviews_count_advertiser > 1
              "
            >
              {{ moreText }}<br />
              <span style="font-size: 13px" class="gray-text">на блогера</span>
            </div>
            <div
              class="rev-more__mobile__text ws-nowrap overflow-hidden text-overflow-ellipsis"
              style="font-size: 14px; line-height: 16.5px; text-overflow: ellipsis"
              @click="$emit('hide-more', data.item.id)"
              v-if="data.item.showMore"
            >
              Свернуть отзывы<br />
              <span class="gray-text">на блогера</span>
            </div>
          </b-button>
          <b-button
            v-else-if="!['me', 'blogger'].includes(filterParams.mode) && !disclosed"
            variant="link"
            style="max-width: 110px"
            class="link d-flex justify-content-between py-1 mt-3 text-left rev-more__mobile__text"
            :class="{ rotate: isDetail }"
            @click="isDetail = !isDetail"
          >
            {{ isDetail ? 'Свернуть отзыв' : 'Раскрыть отзыв полностью' }}
          </b-button>
          <template
            v-if="
              !data.item.referencedBy &&
              !(excludedFields && excludedFields.includes('order_button')) &&
              !['me', 'blogger'].includes(filterParams.mode)
            "
          >
            <b-button
              style="box-shadow: 0 0 0 1px #00d463 inset"
              v-if="ordered"
              @click="
                {
                  $router.push('/promobot/orders/cart/');
                  setWindow(null);
                }
              "
              variant="outline-default"
              class="py-2 mt-3 px-3 ml-auto"
              ><div
                class="rev-more__mobile__text px-1"
                style="font-size: 14px; line-height: 16.3px"
              >
                <img src="@main/assets/img/svg/green-check.svg" class="d-inline-block mr-1" />
                В корзине<br />
                <span style="font-size: 12px" class="gray-text">Отправить заявку</span>
              </div></b-button
            >
            <!--b-button
              v-else
              :style="data.item.advertiser_blogger ? 'border: 1px solid #f9d451' : ''"
              @click="handleGatheringRequest(data.item)"
              :variant="'outline-default'"
              class="py-2 mt-3 ml-auto"
            >
              <div class="rev-more__mobile__text" style="font-size: 14px; line-height: 16.5px">
                {{ data.item.advertiser_blogger ? 'Открыть статистику' : 'Отправить запрос' }}<br />
                <span style="font-size: 13px" class="gray-text ws-nowrap">{{
                  data.item.advertiser_blogger ? 'и запросить блогера' : 'на рекламу на сайте'
                }}</span>
              </div></!--b-button
            --></template
          >
        </div>
      </template>
      <!--template #cell(customers_count)-custom="data">
        <p
          v-if="
            !data.item.advertiser_blogger &&
            data.item.orders_count &&
            !data.item.referencedBy &&
            filterParams.mode === 'all'
          "
          class="mt-3"
          style="
            background-color: #ecf5fe;
            font-size: 11px;
            border-radius: 3px;
            line-height: 15px;
            padding: 4px 6px;
          "
        >
          Блогер ещё не зарегистрировал свою карточку на Easyprbot.
          <strong
            >{{ data.item.orders_count }}
            {{ literal.declension(data.item.orders_count, 'заказчик') }}</strong
          >
          оставил{{ String(data.item.orders_count).endsWith('1') ? '' : 'и' }} запрос на рекламу и
          ожида{{ String(data.item.orders_count).endsWith('1') ? 'ет' : 'ют' }}
          его регистрации
        </p>
      </!template-->
      <template #cell(delete_review)-custom="data">
        <b-button
          @click="() => $emit('del', data.item.id)"
          variant="outline-default"
          class="manip__btn-rev mt-2"
          v-if="['me'].includes(filterParams.mode) && isDetail"
        >
          Удалить отзыв
        </b-button>
      </template>
    </DefaultMobileTable>
  </div>
</template>

<script>
import literal from '@main/func/literal';
import bot from '@main/api/bot';
import wrappers from '@main/func/wrappers';
import pipeline from '@main/func/pipeline';
import AdvertisingApplicationForm from '@main/components/forms/AdvertisingApplicationForm.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import DefaultMobileTable from '../reusable/tables/DefaultMobileTable.vue';
import TableFieldManageMixinVue from '../../mixins/tables/TableFieldManageMixin.vue';
import ReviewTableMixin from './mixins/ReviewTableMixin.vue';

export default {
  components: { DefaultMobileTable },
  name: 'mobile-review',
  props: [
    'review',
    'moreLoading',
    'filterParams',
    'excludedFields',
    'disclosed',
    'hideLike',
    'disableVariants',
    'fieldOrder',
  ],
  data() {
    return {
      isMobile,
      openText: false,
      isDetail: false,
      mode: 'all',
    };
  },
  computed: {
    ...mapState(['tags', 'userCart', 'currentUser', 'loading']),
    ...mapState('reviewsModeration', ['refuseReasons']),
    shownFields() {
      const fields = this.fields.filter((field) =>
        field ? !field.ifDetail || (field.ifDetail && this.isDetail) : null
      );
      return fields;
    },
    tableMode() {
      return this.filterParams.mode;
    },
    fields() {
      const fieldArray = [
        {
          key: 'date',
          type: 'other',
          formatter: this.$options.filters.formatDate,
        },
        {
          key: 'like',
          type: 'other',
        },
        {
          key: 'paid_off',
          label: 'Реклама окупилась',
          formatter: this.formatPaidOff,
          condition({ filterParams }) {
            return filterParams.customer_kind === 'shop' || filterParams.mode === 'blogger';
          },
        },
        {
          key: 'price_per_one',
          formatter: this.formatPPO,
          label: 'Цена за подписчика',
        },
        { key: 'arrival', formatter: this.formatArrival, label: 'Приход' },
        { key: 'price', formatter: this.formatPrice, label: 'Цена за рекламу' },
        this.review.largest_reach
          ? {
              key: 'largest_reach',
              formatter: this.formatLargestReach,
              label: 'Охваты из рекламы',
            }
          : null,
        { key: 'advertiser', label: 'Аккаунт исполнителя' },
        { key: 'rate', label: 'Оценка' },
        {
          key: 'empty2',
          empty: true,
          ifDetail: true,
          condition: ({ filterParams }) => {
            if (filterParams.mode === 'blogger') {
              return this.reviewsOptions.groups.ACCEPTED_STATUSES.includes(
                this.review.moderation_status
              );
            }
            return true;
          },
        },
        {
          key: 'ad_type',
          formatter: this.formatAdType,
          label: 'Формат рекламы',
          ifDetail: true,
        },
        { key: 'customer', label: 'Аккаунт заказчика', ifDetail: true },
        this.review.customer_tags && this.review.customer_tags.length
          ? {
              key: 'customer_tags',
              formatter: this.formatCustomerTags,
              label: 'Темы аккаунта',
              ifDetail: true,
              condition({ filterParams }) {
                return filterParams.customer_kind === 'blogger' || filterParams.mode === 'blogger';
              },
            }
          : null,
        this.review.item
          ? {
              key: 'item',
              label: 'Товар или услуга',
              condition({ filterParams }) {
                return filterParams.customer_kind === 'shop' || filterParams.mode === 'blogger';
              },
            }
          : null,
        { modes: ['me', 'blogger'], key: 'emptyme', empty: true, ifDetail: true },
        {
          modes: ['me', 'blogger'],
          label: `Статус`,
          formatter:
            this.filterParams.mode === 'blogger' ? this.formatStatusBlogger : this.formatStatus,
          key: 'moderation_status',
        },
        {
          key: 'emptystatus',
          empty: true,
          modes: ['blogger'],
          condition: () => {
            return this.reviewsOptions.groups.ACCEPTED_STATUSES.includes(
              this.review.moderation_status
            );
          },
        },
        {
          modes: ['me', 'blogger'],
          key: 'refuse_reasons',
          label: `<span class="gray-text">Причина </span>`,
          type: 'other',
          formatter: this.formatReasons,
          condition: () => {
            return (
              this.review.refuse_reasons &&
              this.review.refuse_reasons.length &&
              this.reviewsOptions.groups.REFUSED_STATUSES.includes(this.review.moderation_status)
            );
          },
        },
        this.reviewsOptions.groups.REFUSED_STATUSES.includes(this.review.moderation_status)
          ? {
              modes: ['me'],
              type: 'other',
              key: 'edit_button',
            }
          : {
              key: 'empty3',
              empty: true,
              ifDetail: true,
              modes: ['blogger'],
              condition: ({ filterParams }) => {
                return filterParams.mode !== 'me';
              },
            },

        this.review.text_display
          ? {
              label: `Текст отзыва:`,
              ifDetail: true,
              key: 'text_display',
              type: 'other',
              text: `${this.review.text_display}`,
            }
          : null,
        {
          key: 'empty4',
          empty: true,
          ifDetail: true,
          condition: ({ filterParams }) => {
            return !filterParams.mode === 'blogger';
          },
        },
        {
          key: 'open_review_standalone',
          type: 'other',
        },
        {
          key: 'delete_review',
          type: 'other',
        },
        {
          key: 'footer_block',
          type: 'other',
        },
        {
          key: 'customers_count',
          type: 'other',
        },
      ];
      return this.processFields(fieldArray);
    },
    moreText() {
      return `Еще ${this.review.reviews_count_advertiser} ${literal.declension(
        this.review.reviews_count_advertiser,
        'отзыв'
      )}`;
    },
    reviewRefuseReason() {
      if (!this.review.refuse_reasons) {
        return;
      }
      const reasons = this.refuseReasons.filter((reason) => {
        return this.review.refuse_reasons.includes(reason.id);
      });
      let reasonText = reasons.map((reason) => reason.text).join('\n\n');
      if (this.review.refuse_reason) {
        reasonText += `\nКомментарий модератора: ${this.review.refuse_reason}`;
      }
      if (!reasonText) {
        return 'не указана.';
      }
      return reasonText;
    },
    ordered() {
      if (!this.userCart) {
        return false;
      }
      return this.userCart.reviews.includes(this.review.id);
    },
  },
  conditionArguments: ['filterParams'],
  mixins: [TableFieldManageMixinVue, ReviewTableMixin],
  inject: ['reviewsFields'],
  methods: {
    ...mapActions(['updateUserCart']),
    ...mapActions('notifications', ['setWindow']),
    declSubscribers(val) {
      return literal.declension(val, 'подписчик');
    },
    editReview() {
      this.$emit('edit', this.review.id);
    },
    getInstagramUrl(i) {
      return `https://www.instagram.com/${i}/`;
    },
    toggleText() {
      this.openText = !this.openText;
    },
  },
  mounted() {
    if (
      this.disclosed ||
      this.review.referencedBy ||
      ['me', 'blogger'].includes(this.filterParams.mode)
    ) {
      this.isDetail = true;
    }
  },
};
</script>

<style scope>
.review-table {
  width: 100%;
}

.review {
  padding: 0px 15px 15px;
  border-bottom: 1px solid #d5dfe4;
  position: relative;
  flex-basis: 1 !important;
  min-width: 270px;
}

.review:nth-child(2n) {
  background-color: #f8f8f8;
}

.review-yellow {
  background-color: #fffcec !important;
}

.review-yellow:nth-child(2n + 1) {
  background-color: #fffce3 !important;
}

.review-top-info {
  text-align: right !important;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  color: #6a7b83;
  margin-top: 11px;
}

.review-table tr {
  min-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.review-table th {
  color: #59676e;
  max-width: 148px;
}

.review-table td {
  width: 142px;
}

.link {
  width: 100% !important  ;
}

a {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-details {
  width: 130px;
  position: relative;
}

.show-details::before {
  content: '';
  position: absolute;
  right: 7px;
  -webkit-transform-origin: 50% 25%;
  transform-origin: 50% 25%;
  top: 11px;
  border: 5px solid transparent;
  border-top: 5px solid #6a7b83;
}

.show-details.rotate::before {
  transform: rotate(180deg);
}

.show-details::after {
  content: '';
  width: 24px;
  height: 24px;
  border: 1px solid #d5dfe4;
  display: block;
  background-color: #fff;
  border-radius: 5px;
}

.review-top-info {
  font-size: 12.5px;
}
.rev-more__mobile {
  min-width: 150px;
}

@media (max-width: 380px) {
  .rev-more__mobile__text {
    font-size: 13.5px !important;
  }
  .rev-more__mobile__text > span {
    font-size: 12.4px !important;
  }
}

@media (max-width: 363px) {
  .review-top-info {
    font-size: 11px;
  }
  .rev-more__mobile {
    font-size: 11px !important;
    max-width: 135px !important;
    min-width: 110px !important;
  }
  .rev-more__mobile__text {
    font-size: 12.5px !important;
  }
  .rev-more__mobile__text > span {
    font-size: 11.4px !important;
  }
  .review-table td {
    padding-left: 18px;
    width: 130px;
  }
}
@media (max-width: 330px) {
  .review-table {
    font-size: 13px !important;
  }
  .rev-more__mobile {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .review-table td {
    padding-left: 18px;
    width: 120px;
  }
}
.manip__btn-rev {
  padding: 8px 10px !important;
  font-size: 13px !important;
}
.mobile-review-danger {
  background: #f5c6cb;
}
</style>
