<template>
  <MultiStepModal
    :steps="steps"
    :currentStep="currentStep"
    wrapperTypeClass="change-phone-popup"
    title="Изменение пароля"
    v-if="changePasswordModal.open"
    :open="changePasswordModal.open"
    @close="() => toggleChangePasswordModal({ open: false, context: { phone: null } })"
  >
    <template v-slot:1>
      <form @submit.prevent="forward" class="needs-validation" novalidate>
        <div class="sms-top mb-3">Введите ваш номер телефона или e-mail.</div>
        <label for="uidlogin">Мы отправим на него код</label>
        <div class="form-group mb-4">
          <b-form-input
            id="uidlogin"
            v-model="$v.enteredValue.$model"
            :class="{ error: $v.enteredValue.$anyError }"
            placeholder=""
            class="w-100 popup__input"
          ></b-form-input>
          <div v-if="codeError" style="font-size: 12px; margin-top: 5px" class="red">
            {{ codeError }}
          </div>
          <div
            v-if="enteredValueType && !$v.enteredValue.typeValidation"
            style="font-size: 12px; margin-top: 5px"
            class="red"
          >
            Введите корректные данные
          </div>
          <div
            v-if="!$v.enteredValue.minLength"
            style="font-size: 12px; margin-top: 5px"
            class="red"
          >
            Минимальная длина - 6 символов
          </div>
        </div>
      </form>
    </template>
    <template v-slot:2>
      <form @submit.prevent="forward" class="needs-validation" novalidate>
        <div class="sms-top">Код из {{ enteredValueType === 'phone' ? 'SMS' : 'E-MAIL' }}</div>
        <div class="form-group">
          <div class="pass-label">
            <label for="code">
              Мы отправили вам код подтверждения на
              <template v-if="enteredValueType === 'phone'">
                номер <span v-phone-number="String(enteredValue)"></span>
              </template>
              <template v-else-if="enteredValueType === 'email'"
                >e-mail {{ enteredValue }}</template
              >
            </label>
          </div>
          <input
            type="text"
            class="form-control"
            id="code1"
            v-model="$v.code.$model"
            :class="{ error: $v.code.$anyError }"
            placeholder="Код"
            required
            @change="
              () => {
                codeError = '';
              }
            "
          />
          <p></p>
          <a @click="resendCode" :class="['code-link', tryTime > 0 ? 'code-link-open' : '']">
            Отправить код повторно
          </a>
          <p v-if="codeError" style="margin-top: 5px; font-size: 12px" class="red">
            {{ codeError }}
          </p>
          <sms-code-status-block
            :taskId="codeId"
            v-if="enteredValueType === 'phone' && codeId"
          ></sms-code-status-block>
          <div v-if="!!tries" class="code-link__caption">
            <div>
              Попытка №{{ tries }} <br />
              Запросить код повторно можно через {{ tryTime }} секунд
            </div>
            <a>
              <span class="info left bottom">
                Не приходит SMS
                <div class="info__txt">
                  Возможные причины: <br /><br />
                  1. Вы не изменили номер мобильного телефона на сайте на новый и SMS было
                  отправлено на старый номер.<br /><br />
                  2. Проблемы могут быть со стороны сотового оператора. Обратитесь в их службу
                  поддержки.
                </div>
              </span>
            </a>
          </div>
        </div>
        <div v-if="$v.code.$anyError" class="error-form active text-left">Введите код!</div>
      </form>
    </template>
    <template v-slot:3>
      <form @submit.prevent="forward" class="needs-validation" novalidate>
        <div class="form-group">
          <div class="pass-label">
            <label for="pass2">Введите новый пароль</label>
          </div>
          <input
            :class="{ error: $v.password.$anyError }"
            type="password"
            v-model="$v.password.$model"
            class="form-control"
            id="pass4"
            minlength="6"
            required
          />
          <div
            v-if="
              !$v.password.minLength ||
              !$v.password.requiresLowerCase ||
              !$v.password.requiresUpperCase
            "
            class="login__content-capt"
          >
            От 6 символов. Минимум по 1 букве верхнего и нижнего регистра и мимимум одна цифра
          </div>
        </div>
        <div class="form-group">
          <div class="pass-label">
            <label for="pass3">Подтвердите пароль</label>
          </div>
          <input
            :class="{ error: $v.repeatPassword.$anyError }"
            v-model="$v.repeatPassword.$model"
            type="password"
            class="form-control"
            id="pass5"
            minlength="6"
            required
          />
        </div>

        <div
          v-if="$v.$error && (!$v.repeatPassword.$required || !$v.password.required)"
          class="error-form active text-left"
        >
          Заполните поля отмеченные красным
        </div>
        <div v-if="!$v.repeatPassword.sameAsPassword" class="error-form pass active text-left">
          Пароли не совпадают
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="login__bottom">
        <b-button
          @click="
            () => {
              if (currentStep > 1) {
                currentStep -= 1;
              } else {
                toggleChangePasswordModal({ open: false, context: { phone: null } });
              }
            }
          "
          variant="outline-default"
          class="to-login"
          >Назад</b-button
        >
        <b-button @click="forward" variant="primary" class="next-tap">{{
          currentStep === 3 ? 'Сохранить' : 'Далее'
        }}</b-button>
      </div>
    </template>
  </MultiStepModal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import auth from '@main/api/users';
import MultiStepModal from '@main/components/reusable/modals/MultiStepModal.vue';
import { required, sameAs, minLength, maxlength, email } from 'vuelidate/lib/validators';
import SmsCodeStatusBlock from '../elements/complex/SmsCodeStatusBlock.vue';

export default {
  components: {
    MultiStepModal,
    SmsCodeStatusBlock,
  },
  data: () => ({
    disablePhoneBack: false,
    enteredValue: '',
    steps: 3,
    password: '',
    repeatPassword: '',
    code: '',
    codeId: null,
    codeError: '',
    tries: 0,
    tryTime: 0,
    tryInterval: null,
    currentStep: 1,
  }),
  validations: {
    password: {
      required,
      minLength: minLength(6),
      requiresUpperCase: (val) => {
        return /[A-Z]/.test(val);
      },
      requiresLowerCase: (val) => {
        return /[a-z]/.test(val);
      },
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    code: {
      required,
    },
    enteredValue: {
      required,
      minLength: minLength(6),
      typeValidation(val) {
        if (this.enteredValueType === 'email') {
          return email(val);
        }
        return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g.test(val);
      },
    },
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('popups', ['changePasswordModal']),
    enteredValueType() {
      const emailRe = /^[^\s@]+@[^\s@]+$/;
      const phoneRe = /\d/g;
      let type = 'phone';
      if (emailRe.test(this.enteredValue)) {
        type = 'email';
        // eslint-disable-next-line no-else-return
      } else if (phoneRe.test(this.enteredValue)) {
        type = 'phone';
      }
      return type;
    },
    requestData() {
      return {
        type: this.enteredValueType,
        value: String(this.enteredValue).toLowerCase().replace(/[ ]/g, ''),
        code: this.code,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('notifications', ['showMessage']),
    ...mapMutations('popups', ['toggleChangePasswordModal']),
    async resendCode() {
      if (!this.tryTime) {
        this.tries += 1;
        await auth.sendCode(this.requestData);
        this.tryTime = 60;
        this.setTryInterval();
      }
    },
    async checkCode() {
      const response = await auth.checkCode(this.requestData);
      if (response.success) {
        this.goRight();
      } else {
        this.codeError = 'Неверный код';
      }
    },
    async setNewPassword() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const response = await auth.changePassword(this.requestData);
      if (response.success) {
        this.showMessage({ title: 'Пароль успешно изменен!', icon: 1 });
        if (this.changePasswordModal.context.callback) {
          this.changePasswordModal.context.callback();
        }
        this.toggleChangePasswordModal({ open: false, context: { phone: null } });
      } else {
        this.showMessage({ title: 'Ошибка! Попробуйте позднее', icon: 2 });
      }
    },
    setTryInterval() {
      this.tryTime = 60;
      this.tryInterval = setInterval(() => {
        if (this.tryTime <= 0) {
          this.tryInterval = 0;
          clearInterval(this.tryInterval);
        } else {
          this.tryTime -= 1;
        }
      }, 1000);
    },
    async sendCode() {
      if (this.enteredValue) {
        const existing = await auth.checkUserExists(this.requestData);
        if (!existing.success) {
          this.codeError = 'Пользователя с таким номером / e-mail не существует!';
          return;
        }
        const res = await auth.sendCode(this.requestData);
        if (res && res.success) {
          this.codeId = res.task_id;
          this.goRight();
        }
      }
    },
    async forward() {
      this.codeError = '';
      switch (this.currentStep) {
        case 1:
          await this.sendCode();
          break;
        case 2:
          if (!this.code || this.$v.code.$anyError) {
            break;
          }
          await this.checkCode();
          break;
        case 3:
          await this.setNewPassword();
          break;
        default:
      }
    },
    goRight() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
  },
  watch: {
    enteredValue(val) {
      console.log(val);
    },
  },
  mounted() {
    if (this.changePasswordModal.context.email || this.changePasswordModal.context.phone) {
      this.enteredValue =
        this.changePasswordModal.context.email || this.changePasswordModal.context.phone;
      this.forward();
    }
  },
};
</script>

<style lang="css" scoped></style>
