<script>
import objects from '@main/func/objects';
import lodash from 'lodash';

export default {
  filterPipeline: [
    'orderFields',
    'excludeFields',
    'checkModes',
    'checkConditions',
    'replaceTableFields',
  ],
  conditionArguments: [],
  methods: {
    orderFields(fieldArray) {
      if (this.fieldOrder) {
        const ordered = objects.reorderArrayOfObjects(fieldArray, 'key', this.fieldOrder);
        return ordered;
      }
      return fieldArray;
    },
    checkModes(fieldArray) {
      return this.lodash
        .compact(fieldArray)
        .filter((val) => !val.modes || val.modes.includes(this.tableMode));
    },
    checkConditions(fieldArray) {
      const mapping = {};
      this.$options.conditionArguments.forEach((argName) => {
        mapping[argName] = this[argName];
      });
      return fieldArray.filter((val) => {
        return !val.condition || val.condition(mapping);
      });
    },
    replaceTableFields(fieldArray) {
      if (this.replaceFields) {
        return fieldArray.map((val) => this.replaceFields.find((f) => f.key === val.key) || val);
      }
      return fieldArray;
    },
    excludeFields(fieldArray) {
      if (this.excludedFields) {
        return fieldArray.filter((field) => {
          if (field && field.key) {
            return !this.excludedFields.includes(field.key);
          }
          return true;
        });
      }
      return fieldArray;
    },
    processFields(fieldArray) {
      this.$options.filterPipeline.forEach((funcName) => {
        fieldArray = this[funcName](fieldArray);
      });
      return fieldArray;
    },
  },
};
</script>
