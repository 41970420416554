<template>
  <footer class="footer py-2">
    <div
      style="max-width: 1332px !important"
      class="
        py-4
        d-flex
        flex-wrap flex-column flex-md-row
        justify-content-between
        align-items-center align-items-md-start
        w-100
      "
    >
      <div
        class="
          d-flex
          px-3
          flex-column
          justify-content-center justify-content-md-start
          align-items-center align-items-md-left
          text-center text-md-left
          mb-4
        "
      >
        <img
          src="../assets/footer_logo.png"
          style="filter: invert(1); max-width: 200px; margin-bottom: 0.5rem"
        />
        <b-button variant="link" class="footer-link ml-md-1 text-center text-md-left"
          >Изобретатель сервиса: <span style="color: white">@anzty</span></b-button
        >
      </div>
      <div
        class="
          d-flex
          px-3
          flex-column
          justify-content-center justify-content-md-start
          text-center text-md-left
          align-items-center align-items-md-left
        "
        :key="idx"
        v-for="(chunk, idx) in chunkedLinks"
      >
        <b-button
          :variant="'link'"
          class="footer-link text-center text-md-left mb-3 ml-md-1"
          target="_blank"
          :href="obj.link"
          v-for="obj in chunk"
          :key="obj.link"
          >{{ obj.name }}</b-button
        >
      </div>
      <div class="px-3">
        <b-button
          class="sup-btn"
          variant="outline-default"
          href="https://u012655.stepform.io/Kt5D6gQ"
          target="_blank"
          >Написать в техподдержку</b-button
        >
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['footerLinks']),
    chunkedLinks() {
      const flArray = [];
      Object.keys(this.footerLinks).forEach((el) => {
        const arrItem = { name: el, link: this.footerLinks[el] };
        flArray.push(arrItem);
      });
      return this.tools.chunkArray(flArray, 2);
    },
  },
};
</script>

<style scoped>
.footer {
  margin-top: 20px;
  background-color: #424858;
  width: 100%;
  padding: 0 10px;
  position: absolute !important;
  bottom: 0;
}
.footer-link {
  color: #b4bdc1;
}
.footer-link:hover {
  color: white;
}
.sup-btn {
  background-color: transparent;
  color: white;
}

.sup-btn:hover {
  background-color: white;
  color: black;
}
</style>
