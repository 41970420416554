<template>
  <div>
    <h5 class="bar-title">{{ data.title }}</h5>
    <div
      class="bar-container d-flex justify-content-between flex-wrap my-2"
      :maxWidth="config.maxWidth"
      :style="`height: ${config.height || 6}px; min-width: ${config.width || 250}px`"
    >
      <div
        v-if="data.positiveValue"
        class="h-100 bar-item"
        :style="`width: calc(${positiveValuePercent}% - ${
          config.barSpacing || 2.5
        }px) !important; background-color: green`"
      ></div>
      <div
        v-if="data.negativeValue"
        class="h-100 bar-item"
        :style="`width: calc(${negativeValuePercent}% - ${
          config.barSpacing || 2.5
        }px) !important; background-color: red`"
      ></div>
    </div>
    <div>
      <span v-if="config.unitName"
        >{{ wholeValue + ' ' + literal.declension(wholeValue, config.unitName) }} - </span
      ><span style="color: green">{{ Math.round(positiveValuePercent) }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    config: {
      type: Object,
    },
  },
  computed: {
    wholeValue() {
      return this.data.positiveValue + this.data.negativeValue;
    },
    percentSize() {
      return this.wholeValue / 100;
    },
    positiveValuePercent() {
      return this.data.positiveValue / this.percentSize;
    },
    negativeValuePercent() {
      return this.data.negativeValue / this.percentSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-item:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.bar-item:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
@media (min-width: 768px) {
  .bar-container {
    max-width: attr(maxWidth);
  }
}
@media (max-width: 500px) {
  .bar-title {
    font-size: 14px !important;
  }
}
</style>
