<template>
  <div class="reviews-table">
    <MobileReview
      class="w-100"
      :disableVariants="true"
      :disclosed="true"
      :filterParams="{ ...review }"
      :review="review"
      :excludedFields="[
        'order_button',
        'reviews_count_advertiser',
        'footer_block',
        'customers_count',
      ]"
      :hideLike="true"
    ></MobileReview>
  </div>
</template>

<script>
import MobileReview from '@main/components/reviews_list/MobileReview.vue';

export default {
  props: {
    review: Object,
  },
  components: {
    MobileReview,
  },
};
</script>

<style lang="scss" scoped>
.stat-col-blogger {
  color: #6a7b83;
  max-width: 80px;
}

::v-deep .reviews-table >>> td {
  min-height: 63px !important;
}

::v-deep .review-table >>> .align-middle {
  font-weight: 500px !important;
}

::v-deep td {
  margin-left: 20px;
}

::v-deep .review {
  border-bottom: none !important;
}

.show-more {
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #d5dfe4;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #6a7b83;
}

.reviews-table >>> .b-table-details {
  background-color: #fffce3;
}

.reviews-table {
  margin-bottom: 0px !important;
}

::v-deep .table {
  border-right: none;
  border-left: none;
}

.review-tags {
  max-height: 2em;
  line-height: 1em;
  word-break: break-word;
}
.rev-mb {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.coverage__th-rev {
  min-width: 160px !important;
}
.icon-hover-close:hover {
  fill: red;
}

@media (max-width: 350px) {
  .short-btn {
    padding: 0.375rem 0.35rem;
  }
}
.leave_rev-block {
  // min-height: 300px;
  height: 300px !important;
  max-height: 300px !important;
  flex-direction: column;
}
.fillstat-btn {
  widows: 100%;
  margin: 1px;
  margin-top: 40px;
}
.rev-title {
  margin-right: 14px;
}

@media (min-width: 768px) {
  .btn-rev {
    width: 97px;
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .btn-rev {
    border-radius: 0;
  }
  .btn-rev:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .btn-rev:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
.button-fill-stat__mobile {
  display: block;
  width: 100%;
  border-bottom: 1px solid #d5dfe4;
  background-color: #fffce3;
}
@media (min-width: 400px) {
  .row-btns__rev {
    max-width: 300px !important;
  }
}
</style>
