import producing from '@main/api/producing';
import enums from '@main/enums';

export default {
  namespaced: true,
  state: {
    infoProductsOptions: null,
    producerProfileOptions: null,
    apiState: enums.INIT,
  },
  actions: {
    async loadInfoProductsOptions(context) {
      const options = await producing.infoProducts.apiSchema({
        chunks: ['actions'],
      });
      context.commit('setInfoProductsOptions', options);
    },
    async loadProducerProfileOptions(context) {
      const options = await producing.producerProfiles.apiSchema({
        chunks: ['actions'],
      });
      context.commit('setProducerProfileOptions', options);
    },
  },
  mutations: {
    setProducerProfileOptions(state, data) {
      state.producerProfileOptions = data;
    },
    setInfoProductsOptions(state, data) {
      state.infoProductsOptions = data;
    },
    setApiState(state, data) {
      state.apiState = data;
    },
  },
};
