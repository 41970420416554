<template>
  <div>
    <b-button
      style="border-radius: 23px !important"
      v-bind="{ ...$props, ...$attrs }"
      class="m-1 mx-2"
      variant="link"
      :to="!button.link && { name: button.to, query: button.query }"
      :href="button.link ? button.link : null"
    >
      <img
        v-if="!$scopedSlots.image"
        style="width: 95px; height: 95px"
        :src="button.icon_desktop"
        :alt="button.text"
        :disabled="button.disabled"
        :title="button.text"
      />
      <slot name="image"></slot>
    </b-button>
    <p
      class="mt-1 mx-auto text-center"
      style="
        width: 100px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #192229;
      "
    >
      {{ button.text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    button: Object,
  },
};
</script>
