<template>
  <div
    @click="clickOusideHide && $emit('hide')"
    class="popup"
    :style="{
      'background-color': showAnim
        ? 'rgba(0, 0, 0, 0.5)'
        : showModal
        ? 'rgba(0, 0, 0, 0.0)'
        : 'rgba(0, 0, 0, 0.5)',
    }"
    v-bind:class="{ act: showModal, [mainClass]: mainClass }"
  >
    <div
      class="popup__wrap"
      :style="{
        'margin-bottom': !hideAnim ? (showAnim ? '0px' : showModal ? '-2000px' : '0px') : '0px',
      }"
      :class="wrapClass"
      @click.stop
    >
      <!-- <div class="popup-nav">
              <button type="button" class="popup__close btn--border popup-nav__btn-back" @click="$emit('hide')">Назад</button>
              <div class="hamburger">
                  <button class="hamburger__btn"></button>
              </div>
          </div> -->
      <div v-if="!topLess" class="popup__top">
        <h2 class="popup-search-off__title mr-2">{{ title }}</h2>
        <button v-if="displayClose" class="popup2__close" @click="$emit('hide')">
          <svg class="icon-close-btn popup-theses__icon">
            <use xlink:href="@main/assets/sprite.svg#close-btn"></use>
          </svg>
        </button>
      </div>
      <div style="position: relative" :class="contentClass">
        <button
          v-if="topLess && displayClose"
          class="popup2__close topless__close"
          @click="$emit('hide')"
        >
          <svg class="icon-close-btn popup-theses__icon">
            <use xlink:href="@main/assets/sprite.svg#close-btn"></use>
          </svg>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    title: String,
    wrapClass: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
    mainClass: {
      type: String,
      default: 'popup-instruction',
    },
    topLess: Boolean,
    show: Boolean,
    clickOutsideHide: {
      default: true,
      type: Boolean,
    },
    hideAnim: {
      default: true,
      type: Boolean,
    },
    displayClose: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    showAnim: false,
    internalShow: false,
  }),
  computed: {
    showModal() {
      return this.show || this.internalShow;
    },
  },
  methods: {
    open() {
      this.internalShow = true;
    },
    close() {
      this.internalShow = false;
    },
  },
  watch: {
    showModal: {
      handler(val) {
        setTimeout(() => {
          this.showAnim = val;
        }, 50);
        this.$bus.$emit('modal-open', val);
      },
      immediate: true,
    },
  },
  destroyed() {
    this.$bus.$emit('modal-open', false);
  },
};
</script>

<style lang="scss" scoped>
.topless__close {
  position: absolute;
  right: 4%;
  top: 4%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}
.popup__wrap {
  margin-bottom: attr(mgb);
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
.popup {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
</style>
