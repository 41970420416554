import lodash from 'lodash';
import qs from 'qs';
import apiService from './index';

const BLOGGERS_URL = '/instagram/bloggers/';
const BLOGGERS_MODERATION_STATUS_URL = '/instagram/moderation/set_status/';
const BLOGGERS_MODERATION_URL = '/instagram/moderation/';
const CARD_ACTION_URL = '/instagram/bloggers/card_action/';
const SCREEN_UPDATE_URL = '/instagram/bloggers/screens/';
const SET_LOGIN_URL = '/instagram/bloggers/set_login/';
const GET_CODE_URL = '/instagram/bloggers/get_verification_code/';
const CHECK_CODE_URL = '/instagram/bloggers/check_verification_code/';
const MODERATION_STEPS_URL = '/instagram/moderation-steps/';
const MODERATION_REASONS_URL = '/instagram/moderation-reasons/';

export default {
  async checkActionPermissions(data, action) {
    const response = await apiService.post(
      `/instagram/bloggers/check_action_permissions/`,
      {
        data,
      },
      { params: { action } }
    );
    return response;
  },
  async delete(instaname) {
    const response = await apiService.delete(`/instagram/bloggers/${instaname}/`);
    return response;
  },
  async create(data) {
    const response = await apiService.post(`/instagram/bloggers/`, data);
    return response;
  },
  async connectBlogger(token) {
    const response = await apiService.post('/instagram/bloggers/connect_bot_user/', { token });
    return response;
  },
  async fnc(data, params) {
    const response = await apiService.post('/instagram/bloggers/fnc/', data, { params });
    return response;
  },
  async sendToModeration() {
    const response = await apiService.post('/instagram/bloggers/send_to_moderation/');
    return response;
  },
  async getBlogger(instaname, access) {
    const URL = `${BLOGGERS_URL}${instaname}/`;
    const response = await apiService.get(URL, { params: { access } });
    return response;
  },
  async getMaskedBlogger(instaname) {
    const URL = `${BLOGGERS_URL}${instaname}/retrieve_masked/`;
    const response = await apiService.get(URL);
    return response;
  },
  async revealCard(instaname) {
    const URL = `${BLOGGERS_URL}${instaname}/reveal_card/`;
    const response = await apiService.post(URL);
    return response;
  },
  /* eslint-disable camelcase */
  async setLogin(instaname, pr_account) {
    const response = await apiService.post(SET_LOGIN_URL, { instaname, pr_account });
    return response;
  },
  async getBloggerOptions(params) {
    const response = await apiService.get('/instagram/bloggers/api_schema/', {
      params,
      paramsSerializer: (ps) => {
        return qs.stringify(ps, { arrayFormat: 'repeat', skipNulls: true, plainObjects: true });
      },
    });
    return response;
  },
  async cardAction(action) {
    const response = await apiService.post(CARD_ACTION_URL, {
      action,
    });
    return response;
  },
  async getInstagramCode(instaname) {
    const response = await apiService.post(GET_CODE_URL, { instaname });
    return response;
  },
  /* eslint-disable camelcase */
  async checkInstagramCode(pr_account) {
    const response = await apiService.post(CHECK_CODE_URL, { pr_account });
    return response;
  },
  async uploadScreens(screendata) {
    const formData = new FormData();
    // screendata = lodash.pickBy(screendata, lodash.identity);
    Object.keys(screendata).forEach((key) => formData.append(key, screendata[key]));
    const response = await apiService.post(SCREEN_UPDATE_URL, formData);
    return response;
  },
  moderation: {
    async update(id, data) {
      const URL = `/instagram/moderation/${id}/`;
      const response = await apiService.patch(URL, data);
      return response;
    },
    async list(params) {
      const response = await apiService.get(BLOGGERS_MODERATION_URL, { params });
      return response;
    },
    async steps() {
      const response = await apiService.get(MODERATION_STEPS_URL);
      return response;
    },
    async reasons() {
      const response = await apiService.get(MODERATION_REASONS_URL);
      return response;
    },
    async fnc(data, params) {
      const response = await apiService.post('/instagram/moderation/fnc/', data, { params });
      return response;
    },
    moderation_history_items: {
      async create(data) {
        const response = await apiService.post(`/instagram/moderation-history-items/`, data);
        return response;
      },
      async me() {
        const response = await apiService.get(`/instagram/moderation-history-items/me/`);
        return response;
      },
    },
  },
  async toggleBotPlacement() {
    const response = await apiService.post('/instagram/bloggers/toggle_bot_placement/');
    return response;
  },
  async checkBloggerExists() {
    const response = await apiService.post('/instagram/check_blogger_exists/');
    return response;
  },
  async changeBloggerInstaname(data, params) {
    const response = await apiService.post('/instagram/change_blogger_instaname/', data, {
      params,
    });
    return response;
  },
  changeInstanameRequest: {
    async create(data) {
      const response = await apiService.post('/instagram/change-instaname-request/', data);
      return response;
    },
    moderation: {
      async list(params) {
        const response = await apiService.get('/instagram/change-instaname-request-moderation/', {
          params,
        });
        return response;
      },
      async update(data) {
        const response = await apiService.put(
          `/instagram/change-instaname-request-moderation/${data.id}/`,
          data
        );
        return response;
      },
    },
  },
};
