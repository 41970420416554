<template>
  <div v-show="showSignIn">
    <div class="popup__container">
      <div class="popup__inner">
        <div class="popups__overlay" @click.self="toggleSignIn(false)"></div>
        <component :is="showSignIn.warningComponent"></component>
        <div class="line"></div>
        <div class="popup">
          <a class="popup__close_reg" @click="toggleSignIn(false)">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M1 1L11 11M11 1L1 11"
                stroke="#6A7B83"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>

          <div class="popup_handles flex">
            <a
              @click="
                () => {
                  mode = 'log';
                  $v.$reset();
                  this.error = null;
                }
              "
              class="popup__handle openPopup"
              :class="{ active: mode === 'log' }"
              >Вход</a
            >
            <a
              @click="
                () => {
                  mode = 'reg';
                  $v.$reset();
                  this.error = null;
                }
              "
              class="popup__handle openPopup"
              :class="{ active: mode === 'reg' }"
              >Регистрация</a
            >
          </div>

          <form
            @keyup.enter.prevent="login"
            class="enter__container"
            method="post"
            v-if="mode === 'log'"
          >
            <div class="popup__content enter__content px-5">
              <div class="label__popup flex" v-if="error && error.response.status === 401">
                <div class="error__text error__text__big">Неверный номер или пароль</div>
              </div>
              <div class="label__popup flex">
                <div class="input__title text-center">E-mail или номер телефона</div>
                <b-form-input
                  :class="{ error: $v.loginString.$anyError }"
                  v-model="$v.loginString.$model"
                  class="popup__input"
                ></b-form-input>
                <div
                  class="error__text"
                  v-if="$v.loginString.$anyError && !$v.loginString.required"
                >
                  Введите номер телефона или e-mail
                </div>
              </div>
              <div class="label__popup flex">
                <div class="input__title">Пароль</div>
                <a @click="changePassword" class="forget__pass">Забыли пароль?</a>
                <input
                  :class="{ error: $v.password.$anyError }"
                  type="password"
                  class="popup__input"
                  v-model="$v.password.$model"
                />

                <template v-if="$v.password.$anyError">
                  <div v-if="!$v.password.required" class="error__text">Введите пароль!</div>
                  <div v-if="!$v.password.minLength" class="error__text">
                    Минимальная длина пароля {{ $v.password.$params.minLength.min }}
                  </div>
                </template>
              </div>
            </div>
            <div class="popup__bottom flex">
              <b-button
                variant="primary"
                class="popup__submit popup__submit-enter"
                @click="login"
                @keyup.enter="login"
              >
                Войти
              </b-button>
            </div>
          </form>

          <template v-if="mode === 'reg'">
            <div class="reg__container">
              <div class="popup__content reg__content">
                <div class="label__popup flex">
                  <div class="input__title">Введите e-mail</div>
                  <b-form-input
                    :class="{ error: $v.loginString.$anyError }"
                    v-model="$v.loginString.$model"
                    class="w-100 popup__input"
                  ></b-form-input>
                  <template v-if="$v.loginString.$anyError">
                    <div v-if="!$v.loginString.email" class="error__text">Некорректный e-mail</div>
                    <div v-if="!$v.loginString.required" class="error__text">Введите e-mail</div>
                  </template>
                </div>
                <div class="label__popup flex">
                  <div class="input__title">Пароль</div>
                  <input
                    :class="{ error: $v.password.$anyError }"
                    type="password"
                    class="popup__input"
                    v-model="$v.password.$model"
                  />

                  <template v-if="$v.password.$anyError">
                    <div v-if="!$v.password.required" class="error__text">Введите пароль!</div>
                    <div v-if="!$v.password.minLength" class="error__text">
                      Минимальная длина пароля {{ $v.password.$params.minLength.min }}
                    </div>
                  </template>
                </div>
                <div v-if="$v.password.$anyError" class="login__content-capt">
                  От 6 символов. Минимум по 1 букве верхнего и нижнего регистра и мимимум одна цифра
                </div>
                <div class="label__popup flex">
                  <div class="input__title">Подтвердите пароль</div>
                  <input
                    :class="{ error: $v.confirmPassword.$anyError }"
                    type="password"
                    v-model="$v.confirmPassword.$model"
                    class="popup__input"
                  />
                  <template v-if="$v.confirmPassword.$anyError">
                    <div v-if="!$v.confirmPassword.sameAsPassword" class="error__text">
                      Пароли не совпадают
                    </div>
                    <div v-if="!$v.confirmPassword.required" class="error__text">
                      Повторите пароль
                    </div>
                  </template>
                </div>
                <div class="label__popup agreeLabel flex">
                  <b-form-checkbox v-model="$v.acceptPolicy.$model">
                    <div class="check-text">
                      Я даю
                      <a
                        style="display: contents; white-space: break-spaces"
                        class="link"
                        href="/policy_agreement/"
                        target="_blank"
                        >согласие</a
                      >
                      на обработку моих персональных данных в соответствии с
                      <a
                        style="display: contents; white-space: break-spaces"
                        class="link"
                        href="/privacy/"
                        target="_blank"
                        >Политикой обработки персональных данных</a
                      >
                    </div>
                    <b-form-invalid-feedback :state="!$v.acceptPolicy.$anyError"
                      >Выберите чекбокс чтобы продолжить</b-form-invalid-feedback
                    >
                  </b-form-checkbox>
                  <b-form-checkbox v-model="$v.acceptOffero.$model" class="mt-3">
                    <div class="check-text">
                      Я соглашаюсь с
                      <a
                        style="display: contents; white-space: break-spaces"
                        class="link"
                        href="/offero/"
                        >Офертой</a
                      >
                      и
                      <a
                        style="display: contents; white-space: break-spaces"
                        class="link"
                        href="/policy/"
                        target="_blank"
                        >Пользовательским соглашением</a
                      >
                    </div>
                    <b-form-invalid-feedback :state="!$v.acceptOffero.$anyError"
                      >Выберите чекбокс чтобы продолжить</b-form-invalid-feedback
                    >
                  </b-form-checkbox>
                  <b-form-checkbox v-model="adAgree" class="mt-3">
                    <div class="check-text">
                      Я согласен (-а) на получение информационных и рекламных материалов
                    </div>
                  </b-form-checkbox>
                  <div v-if="error && error.response.data.detail" class="error__text">
                    {{ error.response.data.detail }}
                  </div>
                  <div class="error__text"></div>
                </div>
              </div>
              <div class="popup__bottom flex">
                <b-button
                  class="popup__submit reg__submit sendCod"
                  variant="primary"
                  @click="register"
                  @keydown.enter="register"
                >
                  Далее</b-button
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { required, minLength, sameAs, requiredIf, email } from 'vuelidate/lib/validators';
import auth from '@main/api/users';
import referal from '@main/api/referal';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';

export default {
  components: {},
  computed: {
    ...mapState(['showSignIn', 'currentUser']),
  },
  inject: ['loginInit'],
  data() {
    return {
      mode: 'log',
      adAgree: false,
      error: null,
      loginString: '',
      password: '',
      confirmPassword: '',
      acceptPolicy: false,
      acceptOffero: false,
    };
  },
  validations: {
    loginString: {
      required,
      email(val) {
        if (this.mode === 'reg') {
          return email(val);
        }
        return true;
      },
    },
    password: { required, minLength: minLength(6) },
    confirmPassword: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        return this.mode === 'reg';
      }),
      // eslint-disable-next-line func-names
      sameAsPassword(val) {
        if (this.mode === 'reg') return sameAs('password');
        return true;
      },
    },
    acceptPolicy: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        return this.mode === 'reg';
      }),
      mustNotBeFalseIfReg(val) {
        return this.mode === 'reg' ? val : true;
      },
    },
    acceptOffero: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        return this.mode === 'reg';
      }),
      mustNotBeFalseIfReg(val) {
        return this.mode === 'reg' ? val : true;
      },
    },
  },
  methods: {
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('notifications', ['showMessage', 'setWindow']),
    ...mapActions(['loadUser']),
    ...mapActions({
      loginStore: 'login',
    }),
    ...mapMutations('popups', ['toggleChangePasswordModal', 'toggleEmailActivationModal']),
    login(cb) {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      auth
        .login(this.loginString, this.password)
        .then((r) => {
          console.log(r);
          this.loginStore(r.access).then(() => {
            this.loginInit().then(() => {
              this.loadUser().then(() => {
                if (typeof this.showSignIn === 'object' && this.showSignIn.callback) {
                  try {
                    const callbackResult = this.showSignIn.callback({ query: this.$route.query });
                  } catch (e) {
                    console.log(e);
                  }
                }
                if (cb && typeof cb === 'function') {
                  try {
                    cb().catch((e) => {
                      console.log(e);
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }
                this.toggleSignIn(false);
                this.$router.$getNextPage(this.$router, this.$route).then(() => {
                  console.log('success');
                });
              });
            });
          });
          if (this.$route.query.referer_code) {
            referal.code.setRefererCode(this.$route.query.referer_code).catch((e) => {
              console.log(e);
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.error = e;
          if (e.response && e.response.status === 500) {
            this.setWindow({
              text: 'Произошла ошибка при входе в ваш аккаунт. Проверьте интернет-соединение, попробуйте перезагрузить страницу. При длительном присутствии ошибки обратитесь в техническую поддержку - help@easyprbot',
              iconPath: alertGreyIcon,
            });
          }
        });
    },
    register() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      auth
        .register(this.loginString, this.password)
        .then((user) => {
          this.loginString = user.username;
          this.mode = 'log';
          this.login(() => {
            auth.updateMe({
              id: 36260,
              profile: {
                ...this.lodash.cloneDeep(this.currentUser.profile),
                accept_email: this.adAgree,
              },
            });
          });
          this.toggleEmailActivationModal({
            open: true,
            context: { register: true, noEmailText: 'Изменить e-mail', disclose: true },
          });
        })
        .catch((e) => {
          this.error = e;
        });
    },
    changePassword() {
      this.toggleSignIn(false);
      this.toggleChangePasswordModal({
        open: true,
        context: {
          callback: () => {
            this.toggleSignIn(true);
          },
        },
      });
    },
    ...mapMutations(['toggleSignIn']),
  },
  watch: {
    showSignIn(val) {
      this.$bus.$emit('modal-open', val);
    },
  },
  destroyed() {
    this.$bus.$emit('modal-open', false);
  },
  mounted() {
    if (this.showSignIn.data) {
      Object.keys(this.showSignIn.data).forEach((key) => {
        this.$set(this, key, this.showSignIn.data[key]);
      });
    }
    if (this.loginString && this.password) {
      this.login();
    }
  },
};
</script>

<style scoped src="@main/styles/sign_in.css"></style>

<style lang="scss" scoped>
.popup {
  display: block !important;
}
</style>
