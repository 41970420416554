<template>
  <div class="mt-md-3">
    <div class="adapform">
      <b-form class="fs-16">
        <b-form-group>
          <label for="input-1">Аккаунт, который будет указан в рекламе у блогеров</label>
          <b-form-input
            :class="{ error: $v.order.instaname.$anyError }"
            v-model="order.instaname"
            id="input-1"
            @keypress="
              (event) => {
                englishLettersWarning = false;
                var ew = event.which;
                if ([32, 95, 44, 46].includes(ew)) return true;
                if (48 <= ew && ew <= 57) return true;
                if (65 <= ew && ew <= 90) return true;
                if (97 <= ew && ew <= 122) return true;
                event.preventDefault();
                englishLettersWarning = true;
              }
            "
            type="text"
            required
          ></b-form-input>
          <p v-if="englishLettersWarning" class="fs-12 mt-2" style="color: red">
            Только английские буквы!
          </p>
        </b-form-group>
        <b-form-group
          :symbolLimit="500"
          :symbolCount="order.description ? order.description.length : 0"
          class="input__symbol--count position-relative"
        >
          <label for="input-2">Тезисы</label>
          <b-textarea
            :class="{ error: $v.order.description.$anyError }"
            v-model="order.description"
            style="min-height: 310px"
            placeholder="Например: Мой блог про:
  Медитации и силу подсознания
  Психологическую сторону успеха и денег
  Обзоры и анти-обзоры на книги
  
  Последние темы про:
  - Медитации для новичков
  - Как медитации влияют на мозг
  - Как подтолкнуть партнера к работе над собой
  - Как заставить кризисы работать на пользу
  - Как развиваться без книг и тренингов"
            id="input-2"
            type="text"
            maxlength="500"
            required
          ></b-textarea>
        </b-form-group>
        <!--b-form-group>
          <label for="input-3">Ссылка на полное ТЗ</label>
          <b-form-input
            v-model="order.tk_link"
            placeholder="Необязательно"
            id="input-3"
            type="text"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <label for="input-4">Ссылка на макеты</label>
          <b-form-input
            v-model="order.layouts_link"
            placeholder="Необязательно"
            id="input-4"
            type="text"
            required
          ></b-form-input>
        </b-form-group-->
        <b-form-checkbox v-model="policyAgree" size="lg" id="checkbox-1">
          Я соглашаюсь с
          <b-button
            href="https://easyprbot.com/privacy/"
            target="_blank"
            variant="link"
            style="display: contents !important"
          >
            политикой конфиденциальности</b-button
          >
        </b-form-checkbox>
      </b-form>
    </div>
    <b-button @click="handleRequestSend" class="w-100 py-3 my-2" variant="yellow"
      >Отправить</b-button
    >
    <p class="gray-text fs-12">
      Вы отправляете запрос на рекламу блогерам, которые ещё не зарегистрировали свою карточку на
      сайте easyprbot.com они увидят после регистрации
    </p>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import bot from '@main/api/bot';
import promobot from '@main/api/promobot';
import successIcon from '@main/assets/img/svg/done_icon.svg';
import literal from '@main/func/literal';
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  data: () => ({
    order: {
      tk_link: '',
      layouts_link: '',
      instaname: '',
      description: '',
    },
    policyAgree: null,
    englishLettersWarning: false,
  }),
  methods: {
    ...mapActions(['loadUserCart']),
    ...mapActions('notifications', ['setWindow']),
    ...mapMutations('notifications', ['clearWindows']),
    async setSuccessWindow() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.order.description = literal.removeLinks(this.order.description);
      this.order.instaname = literal.parseInstaname(this.order.instaname);
      await bot.userPreorder.order(this.order);
      await this.loadUserCart();
      this.setWindow({
        backMode: true,
        contentClass: 'p-2',
        html: `<h1 class='my-3 text-center'>Ваши заявки отправлены</h1><p class='text-center'>Ответы придут на страницу <a href="/promobot/orders/answers/">"Ответы по рекламе"</a>. Обычно рассмотрение заявок занимает от 3-х дней.</p>`,
        iconPath: successIcon,
        footer: {
          attrs: {
            class: 'justify-content-center',
          },
        },
        buttons: [
          {
            text: 'К отзывам',
            attrs: { variant: 'yellow' },
            handler: () => {
              this.clearWindows();
              this.$router.push({ name: 'reviews' });
            },
          },
          {
            text: 'Ответы по рекламе',
            attrs: {
              variant: 'outline-default',
            },
            handler: () => {
              this.clearWindows();
              this.$router.push('/promobot/orders/answers/');
            },
          },
        ],
      });
    },
    handleRequestSend() {
      this.setSuccessWindow();
    },
  },
  validations: {
    order: { instaname: { required }, description: { required, maxLength: maxLength(500) } },
    policyAgree: { required },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .adapform {
    padding-right: 15%;
  }
}
</style>
