<template>
  <span>{{ dots }}</span>
</template>

<script>
export default {
  data: () => ({
    dots: '.',
  }),
  props: {
    interval: {
      type: Number,
      default: 100,
    },
  },
  mounted() {
    setInterval(() => {
      if (this.dots.length >= 3) this.dots = '';
      else this.dots += '.';
    }, this.interval);
  },
};
</script>
