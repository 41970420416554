import Vue from 'vue';
import MobileDetect from 'mobile-detect';

const envr = new MobileDetect(window.navigator.userAgent);

export default {
  namespaced: true,
  state: {
    showAuthModal: false,

    authenticated: true,

    // Показать окно с тарифами
    showPriceModal: false,

    // Мобильное приложение
    isMobile: envr.mobile() !== null,

    // Планшет
    isTablet: envr.tablet() !== null,

    currentContext: '',

    // Отобразить окно жалобы
    showAbuseModal: false,

    // Id пользователя для жалобы
    abusedProfileID: 0,

    // Цены на подписку
    premiumPrice: [],

    // Персональные предложения по подписке
    premiumOffers: [],

    // Цены на обновления
    updatePrice: [],

    // Состояние апи
    apiState: null,
  },
  mutations: {
    showingPriceModal(state) {
      state.showPriceModal = true;
    },
    hiddenPriceModal(state) {
      state.showPriceModal = false;
    },
    setCurrentContext(state, context) {
      console.log(context);
      state.currentContext = context;
    },
    makeAbuse(state, profileID) {
      Vue.set(state, 'abusedProfileID', profileID);
      Vue.set(state, 'showAbuseModal', true);
    },
    hideAbuseModal(state) {
      Vue.set(state, 'abusedProfileID', 0);
      Vue.set(state, 'showAbuseModal', false);
    },
    setPremiumPrice(state, data) {
      state.premiumPrice = data;
    },
    setPremiumOffers(state, data) {
      Vue.set(state, 'premiumOffers', data);
    },
    setUdatePrice(state, data) {
      state.udatePrice = data;
    },
    showingAuthModal(state, data) {
      Vue.set(state, 'showAuthModal', data);
    },
    setAuthentication(state, data) {
      Vue.set(state, 'authentication', data);
    },
    setApiState(state, data) {
      Vue.set(state, 'apiState', data);
    },
  },
  getters: {
    regComplete: (state, getters, rootState) => {
      return (
        rootState.currentUser &&
        rootState.currentUser.blogger_profile &&
        rootState.currentUser.blogger_profile.instaname &&
        rootState.currentUser.blogger_profile.instagram_verified &&
        rootState.currentUser.blogger_profile.coverage_min > 100 &&
        rootState.currentUser.blogger_profile.coverage_max > 100 &&
        rootState.currentUser.mutualpr_profile
      );
    },
    minPremiumPrice(state) {
      return Math.min(...state.premiumPrice.map((p) => Math.round(p.amount / p.days)));
    },
    minUpdatePrice(state) {
      return Math.min(...state.updatePrice.map((p) => Math.round(p.amount / p.count)));
    },
  },
};
