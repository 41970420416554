import bloggers from '@main/api/bloggers';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import router from '@main/router';
import enums from '@main/enums';

export default {
  namespaced: true,
  state: {},
  actions: {
    async getBlogger(context, payload) {
      try {
        const res = await bloggers.getBlogger(payload.instaname.toLowerCase(), payload.access);
        return res;
      } catch (e) {
        if (e.response.status === 403 || e.response.status === 401) {
          if (e.response.data.errcode === 3 || e.response.status === 401) {
            const maskedRes = await bloggers.getMaskedBlogger(payload.instaname.toLowerCase());
            return maskedRes;
          }
          context.dispatch(
            'notifications/setWindow',
            {
              text: e.response.data.detail,
              iconPath: alertGreyIcon,
            },
            { root: true }
          );
          payload.errBack();
        } else if (e.response.status === 404) {
          context.dispatch(
            'notifications/setWindow',
            {
              text: 'Блогер не найден или карточка была удалена!',
              iconPath: alertGreyIcon,
            },
            { root: true }
          );
          payload.errBack();
        }
      }
    },
  },
  mutations: {},
};
