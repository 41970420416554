<template>
  <div class="popup-result-test">
    <div class="container">
      <svg style="min-width: 25px !important" :class="iconClass" v-if="type !== ''">
        <use v-bind:xlink:href="icon"></use>
      </svg>
      <div class="popup-result__content" v-if="data.message || data.defaultMessage">
        <p
          v-html="data.title"
          style="line-height: 19px"
          class="fs-16 popup-result__link--small"
        ></p>
        <p
          v-html="data.message || defaultMessages[data.defaultMessage]"
          class="popup-result__text--grey"
        ></p>
      </div>
      <p
        v-html="data.title"
        style="line-break: normal !important; overflow: hidden !important"
        class="popup-result__text size-medium"
        v-else
      ></p>
      <button class="popup-result__btn-close" @click="$emit('close', data.id)">
        <svg class="popup-result__icon-close">
          <use xlink:href="../../assets/sprite.svg#close-btn"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import sprite from '@main/assets/sprite.svg';

const defaultMessages = {
  error: `Попробуйте позднее или заполните <a href='https://tracker.easyprbot.com/bug-form' target='_blank'>форму</a> и мы постараемся разобраться с вашей проблемой как можно скорее!`,
};

export default {
  props: {
    data: {
      type: Object,
      required: true,
      defaultMessages,
    },
  },
  computed: {
    type() {
      switch (this.data.icon) {
        case 1:
          return 'done';
        case 2:
          return 'alert-yellow';
        case 3:
          return 'alert-grey';
        default:
          return '';
      }
    },
    iconClass() {
      switch (this.data.icon) {
        case 1:
          return 'popup-result__icon';
        case 2:
          return 'popup-result__icon-alert';
        case 3:
          return 'popup-result__icon-alert';
        default:
          return 'popup-result__icon';
      }
    },
    icon() {
      return `${sprite}#${this.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1352px;
  max-width: 100%;
}
</style>
